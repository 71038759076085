import React, { Component } from "react"

import SEO from '../components/layout/SEO'
import Layout from '../components/layout/layout'
import SectionFull from "../components/layout/sectionFull"
import ContactComponent from '../components/modules/contact'

import { $ } from "../helpers/dom";
import ScrollPageComponent from '../components/modules/scrollpage';
import { scrollToElement } from '../helpers/utils'

import styles from "./contact.module.scss"

export default class Contact extends Component {
  scrollHandle = (callbackScroll) => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement($(document, `.${styles.sec2}`).offsetLeft)

  render() {
    return (
      <>
        <SEO
          title="Liên hệ | Beautique - Brand and Creative Consultancy that helps businesses to tranform and succeed"
          url="https://beautique.vn/contact"
          description="Beautique | Brand and Creative Consultancy that helps businesses to tranform and succeed"
          imgThumb="https://beautique.vn/img/home/og-img.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={styles.sec1}>
              <div className={styles.inner}>
                <div className={styles.sec1_info}>
                  <span className={styles.sec1_infoSub}>thông tin liên hệ</span>
                  <div className={styles.sec1_infoP}>
                    <p>33 Giang Văn Minh,<br/>Ba Đình, Hà Nội</p>
                    <p>info@beau.vn</p>
                    <p>097 531 9889 — o86 929 1771</p>
                  </div>
                  <div className={styles.sec1_infoS}>
                    <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'>
                      <svg width={36} height={36} viewBox="0 0 36 36">
                        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M20.988 8.95l-1.886-.003c-2.119 0-3.488 1.344-3.488 3.425v1.578h-1.896a.29.29 0 00-.297.284v2.288a.29.29 0 00.297.283h1.896v7.442a.29.29 0 00.296.284h2.474a.29.29 0 00.297-.284v-7.442h2.217a.29.29 0 00.297-.283v-2.288a.278.278 0 00-.086-.2.304.304 0 00-.21-.084h-2.218v-1.338c0-.643.16-.97 1.036-.97h1.27a.29.29 0 00.297-.284V9.234a.29.29 0 00-.296-.284z"
                            fill="#C8C8C8"
                          />
                        </g>
                      </svg>
                    </a>
                    <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='/#'>
                    <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                          stroke="#C8C8C8"
                          strokeWidth={1.068}
                          cx={17}
                          cy={16.846}
                          rx={17}
                          ry={16.846}
                        />
                        <g fill="#C8C8C8">
                          <path
                            d="M21.535 10.737H12.59a1.85 1.85 0 00-1.853 1.843v8.896a1.85 1.85 0 001.853 1.843h8.945a1.85 1.85 0 001.854-1.843V12.58a1.85 1.85 0 00-1.854-1.843zm-4.447 9.584c-1.84 0-3.336-1.488-3.336-3.318 0-1.829 1.496-3.317 3.336-3.317 1.839 0 3.335 1.488 3.335 3.317 0 1.83-1.496 3.318-3.335 3.318zm3.707-6.124a.947.947 0 01-.949-.944c0-.52.426-.943.95-.943.522 0 .948.423.948.943s-.426.944-.949.944z"
                            fillRule="nonzero"
                          />
                          <path d="M20.795 12.813a.442.442 0 00-.443.44.442.442 0 00.886 0 .442.442 0 00-.443-.44zM17.063 14.512a2.527 2.527 0 00-2.53 2.516 2.527 2.527 0 002.53 2.516 2.527 2.527 0 002.53-2.516 2.527 2.527 0 00-2.53-2.516z" />
                        </g>
                      </g>
                    </svg>
                    </a>
                    <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://dribbble.com/beauagency'>
                    <svg width={36} height={36} viewBox="0 0 36 36">
                    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                        stroke="#C8C8C8"
                        strokeWidth={1.068}
                        cx={17}
                        cy={16.846}
                        rx={17}
                        ry={16.846}
                      />
                      <g fill="#C8C8C8">
                        <path d="M19.106 17.633a28.263 28.263 0 011.528 5.353 7.056 7.056 0 001.316-1.036 6.948 6.948 0 001.953-3.784 8.672 8.672 0 00-4.797-.533zM21.956 12.056l-.006-.006A6.954 6.954 0 0017 10c-.731 0-1.445.112-2.123.327a28.363 28.363 0 012.86 4.347 16.247 16.247 0 004.219-2.618zM22.506 12.676a16.945 16.945 0 01-4.39 2.732c.236.476.46.958.67 1.448a9.59 9.59 0 015.207.46 6.947 6.947 0 00-1.487-4.64zM12.691 22.518A6.947 6.947 0 0017 24c1.005 0 1.978-.21 2.868-.611a27.513 27.513 0 00-1.58-5.575 8.758 8.758 0 00-5.597 4.704z" />
                        <path d="M16.868 17.437c.36-.152.727-.281 1.1-.387-.197-.45-.406-.895-.626-1.333a16.94 16.94 0 01-5.922 1.058c-.474 0-.946-.02-1.414-.058a6.954 6.954 0 002.044 5.232l.002.002a9.515 9.515 0 011.784-2.47 9.509 9.509 0 013.032-2.044zM16.959 14.98a27.862 27.862 0 00-2.898-4.337 7.004 7.004 0 00-2.01 1.407 6.947 6.947 0 00-1.965 3.85 16.14 16.14 0 006.873-.92z" />
                      </g>
                    </g>
                  </svg>
                    </a>
                    <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'>
                    <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                        <g fill="#C8C8C8">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M15.804 16.502c.345-.178.607-.378.786-.593.319-.387.476-.9.476-1.538 0-.62-.157-1.15-.472-1.594-.525-.723-1.413-1.093-2.67-1.113H8.947v10.44h4.641c.523 0 1.007-.047 1.455-.14.448-.096.836-.27 1.164-.525.291-.222.535-.497.729-.821.306-.49.46-1.046.46-1.665 0-.6-.135-1.11-.402-1.53-.27-.419-.665-.726-1.19-.92zM11 13.477h2.241c.493 0 .9.055 1.218.163.369.158.553.478.553.966 0 .437-.14.744-.419.916-.28.172-.643.259-1.09.259h-2.503v-2.304zm3.544 6.631c-.248.123-.597.184-1.043.184H11v-2.784h2.535c.442.004.785.064 1.031.176.438.203.655.576.655 1.12 0 .643-.225 1.075-.677 1.304z"
                            fillRule="nonzero"
                          />
                          <path d="M19.597 12.152h4.414v1.299h-4.414z" />
                          <path
                            d="M25.544 17.28a3.784 3.784 0 00-.608-1.597 3.09 3.09 0 00-1.315-1.142c-.53-.241-1.127-.362-1.791-.361-1.116 0-2.022.358-2.723 1.07-.7.714-1.05 1.74-1.05 3.078 0 1.427.387 2.458 1.164 3.09a4.11 4.11 0 002.683.95c1.228 0 2.183-.376 2.865-1.125.437-.473.684-.939.738-1.396h-2.033a1.678 1.678 0 01-.41.531c-.284.235-.653.353-1.105.353-.43 0-.795-.097-1.1-.291-.501-.31-.764-.854-.797-1.628h5.549c.008-.667-.012-1.18-.067-1.533zm-5.432.2c.073-.503.25-.9.531-1.195.282-.293.68-.441 1.189-.442.47 0 .862.138 1.182.415.316.28.494.685.53 1.221h-3.432z"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </svg>
                    </a>
                  </div>
                </div>
                <div className={styles.sec1_right}>
                  <img src="/img/person/team-img.jpg" alt=""/>
                  <button onClick={this.pageTranslateX} className={`${styles.sec1_btn} mouse-hover`}><img src="/img/icon/icon-arrow-next.svg" alt=""/></button>
                </div>
              </div>
            </SectionFull>
            <SectionFull className={styles.sec2}>
                <div className={styles.inner}>
                  <div className={styles.sec2_form}>
                    <ContactComponent />
                  </div>
                  <div className={styles.sec2_img}>
                    <img src="/img/person/team-img.jpg" alt=""/>
                  </div>
                  <div className={styles.sec2_list}>
                    <ul>
                      <li>
                        <span>điện thoại</span>
                        <p>(+84) 97 531 9889</p>
                        <p>(+84) 86 929 1771</p>
                      </li>
                      <li>
                        <span>email</span>
                        <p>info@beau.vn</p>
                      </li>
                      <li>
                        <span>website</span>
                        <p><a href="https://beau.vn">beau.vn</a></p>
                      </li>
                      <li>
                        <span>địa chỉ</span>
                        <p>33 Giang Văn Minh, Ba Đình, Hà Nội</p>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.sec2_social}>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'><img src="/img/contact/fb.svg" alt=""/></a>
                    <a target="_blank" rel="noopener noreferrer" href='/#'><img src="/img/contact/tt.svg" alt=""/></a>
                    <a target="_blank" rel="noopener noreferrer" href='/#'><img src="/img/contact/itg.svg" alt=""/></a>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'><img src="/img/contact/bh.svg" alt=""/></a>
                  </div>
                </div>
              </SectionFull>
          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
