import React, { Component } from "react"
import { Link } from "gatsby"

import SEO from "../../components/layout/SEO"
import Layout from "../../components/layout/layout"
import SectionFull from "../../components/layout/sectionFull"

import ScrollPageComponent from "../../components/modules/scrollpage"
import { scrollToElement } from "../../helpers/utils"
import ContactWork from "../../components/modules/contactWork"

import styles from "./mic.module.scss"

export default class Insight extends Component {
  scrollHandle = callbackScroll => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {
    return (
      <>
        <SEO
          title="Một thập kỷ xây dựng niềm tin với MIC"
          url="https://beautique.vn/work-detail/mic"
          description="Tròn một thập kỷ xây dựng và phát triển, từ một công ty bảo hiểm chỉ cung cấp các sản phẩm bảo hiểm cho đội ngũ quân nhân, tập đoàn bảo hiểm quân đội MIC hiện nay đã phủ sóng hầu như tất cả các lĩnh vực bảo hiểm. 'Bảo hiểm quân đội - một thập kỷ xây dựng niềm tin' không chỉ là một dấu mốc quan trọng đánh dấu một chặng đường tạo dựng niềm tin của khách hàng."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-mic.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={styles.sec1}>
              <div className={styles.inner}>
                <div>
                  <h2 className={styles.sec1_title}>
                    Một thập kỷ xây dựng niềm tin với MIC
                  </h2>
                </div>
                <div className={styles.sec1_content}>
                  <div className={styles.sec1_left}>
                    <ul className={styles.sec1_list}>
                      <li>
                        <span>Client</span>BẢO HIỂM QUÂN ĐỘI MIC
                      </li>
                      <li>
                        <span>Duration</span>05/2017 - 06/2017
                      </li>
                      <li>
                        <span>Scope</span>Campaign Identity
                      </li>
                    </ul>
                  </div>
                  <div className={styles.sec1_right}>
                    <p>
                      Tròn một thập kỷ xây dựng và phát triển, từ một công ty
                      bảo hiểm chỉ cung cấp các sản phẩm bảo hiểm cho đội ngũ
                      quân nhân, tập đoàn bảo hiểm quân đội MIC hiện nay đã phủ
                      sóng hầu như tất cả các lĩnh vực bảo hiểm. "Bảo hiểm quân
                      đội - một thập kỷ xây dựng niềm tin" không chỉ là một dấu
                      mốc quan trọng đánh dấu một chặng đường tạo dựng niềm tin
                      của khách hàng.
                    </p>
                  </div>
                  <button
                    onClick={this.pageTranslateX}
                    className={styles.sec1_btn}
                  >
                    <img src="/img/icon/icon-arrow-next.svg" alt="" />
                  </button>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec2}>
              <div className={styles.inner}>
                <img src="/img/work/MIC/mic1.png" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec3}>
              <div className={styles.inner}>
                <div className={styles.sec3_left}>
                  <h3>
                    Lấy cảm hứng từ chính điểm nhấn quan trọng nhất của Logo là
                    hình bình hành màu đỏ đang bay vút lên, qua đó sắp xếp tạo
                    thành một hệ lưới pattern như một vòng tròn toả ra và đang
                    chuyển động. Mang đến ý nghĩa của sự phát triển, lan toả và
                    sự đổi mới không ngừng.
                  </h3>
                </div>
                <div className={styles.sec3_right}>
                  <img src="/img/work/MIC/LOGO.gif" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec4}>
              <div className={styles.inner}>
                <img src="/img/work/MIC/mic3a.png" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec5}>
              <div className={styles.inner}>
                <div className={styles.sec5_left}>
                  <h3>Ứng dụng</h3>
                  <p>
                    Ngoài thiết kế nhận diện cho sự kiện kỷ niệm của MIC, đội
                    ngũ Beautique còn tham gia thiết kế trải nghiệm trên những
                    điểm chạm khác của thương hiệu như bộ văn phòng tới những
                    điểm chạm digital.
                  </p>
                  <div>
                    <img src="/img/work/MIC/shape1.png" alt="" />
                  </div>
                </div>
                <div className={styles.sec5_right}>
                  <img src="/img/work/MIC/mic4.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec6}>
              <div className={styles.inner}>
                <div className={styles.img}>
                  <img src="/img/work/MIC/mic5.png" alt="" />
                </div>
                <div className={styles.confetti}>
                  <img src="/img/work/MIC/confetti1.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec7}>
              <div className={styles.inner}>
                <div className={styles.img}>
                  <img src="/img/work/MIC/mic6.png" alt="" />
                </div>
                <div className={styles.confetti_green}>
                  <img src="/img/work/MIC/confetti2.png" alt="" />
                </div>
                <div className={styles.confetti_red}>
                  <img src="/img/work/MIC/confetti3.png" alt="" />
                </div>
                <div className={styles.shape}>
                  <img src="/img/work/MIC/shape2.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec8}>
              <div className={styles.inner}>
                <div className={styles.sec8_left}>
                  <h3>Điểm chạm ngoài trời</h3>
                  <p>
                    Không chỉ dừng lại tại việc ứng dụng bộ văn phòng cho sự
                    kiện, chúng tôi còn mở rộng hệ trải nghiệm của toàn bộ sự
                    kiện lên cả những điểm chạm ngoài trời như billboard và
                    poster.
                  </p>
                </div>
                <div className={styles.sec8_right}>
                  <div>
                    <img src="/img/work/MIC/mic7.png" alt="" />
                  </div>
                  <div className={styles.confetti1}>
                    <img src="/img/work/MIC/confetti2.png" alt="" />
                  </div>
                  <div className={styles.confetti2}>
                    <img src="/img/work/MIC/confetti2.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec9}>
              <div className={styles.inner}>
                <div className={styles.img}>
                  <img src="/img/work/MIC/mic8.png" alt="" />
                </div>
                <div className={styles.confetti_green}>
                  <img src="/img/work/MIC/confetti1.png" alt="" />
                </div>
                <div className={styles.shape}>
                  <img src="/img/work/MIC/shape_3.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec10}>
              <div className={styles.inner}>
                <div className={styles.sec10_left}>
                  <h3>Thank you!</h3>
                </div>
                <div className={styles.sec10_right}>
                  <div>
                    <img src="/img/work/MIC/shape4.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec11}>
              <div className={styles.inner}>
              <h3 className={styles.sec11Title}>Dự án điển hình.</h3>
                  <div className={styles.listWork}>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/olympia-school">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-olympia.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">The Olympia School - Trường học của tương lai</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/joa">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-joa.png`} alt=""/>
                        </div>
                        <h3 className="f-title">Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/doan">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-doan.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">DOAN - thời trang đại diện cho khái niệm Effortless beauty</h3>
                      </Link>
                    </div>
                  </div>
              </div>
            </SectionFull>

            <ContactWork />
          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
