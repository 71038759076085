import React, { Component } from "react"
import TransitionLink from 'gatsby-plugin-transition-link'

import SEO from '../components/layout/SEO'
import Layout from '../components/layout/layout'
import SectionFull from "../components/layout/sectionFull"

import { isElementVisible } from '../helpers/utils'
import ScrollPageComponent from '../components/modules/scrollpage';
import { keyInsightExit, keyInsightEntry } from '../helpers/page-transition'
import ContactWork from '../components/modules/contactWork';

import styles from "./work.module.scss"

export default class Work extends Component {
  constructor(props) {
    super(props);
    this.req = null;
  }

  componentDidMount() {
    // links
    const links = document.querySelectorAll(`.${styles.links}`);
    let activeLinks = () => {
      Array.prototype.forEach.call(links, (element) => {
        if (isElementVisible(element)) {
          element.classList.add(styles.active);
        } else {
          element.classList.remove(styles.active);
        }
      });
      this.req = window.requestAnimationFrame(activeLinks);
    }
    activeLinks();
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1025 && this.req) {
        window.cancelAnimationFrame(this.req);
      } else {
        activeLinks();
      }
    });
  }

  scrollHandle = (callbackScroll) => {
    callbackScroll(window.scrollY)
  }

  render() {
    return (
      <>
        <SEO
          title="Dự án | Beautique - Brand and Creative Consultancy that helps businesses to tranform and succeed"
          url="https://beautique.vn/work"
          description="Beautique | Brand and Creative Consultancy that helps businesses to tranform and succeed"
          imgThumb="https://beautique.vn/img/home/og-img.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
              <SectionFull className={styles.sec1}>
                <div className={styles.inner}>
                  <h3 className={styles.sec1_title}>Dự án</h3>
                </div>
              </SectionFull>
              <SectionFull className={styles.sec2}>
                <div className={styles.inner}>
                  <div className={styles.sec2_projectWrap}>
                        <div className={`${styles.sec2_item} ${styles.alignItemsCenter}`}>
                          <TransitionLink
                            to={`/work-detail/olympia-school`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-olympia.jpg`} alt=""/>
                            <h3 className="f-title">The Olympia School - Trường học của tương lai</h3>
                          </TransitionLink>
                        </div>

                        <div className={`${styles.sec2_item} ${styles.alignItemsStart}`}>
                          <TransitionLink
                            to={`/work-detail/joa`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-joa.png`} alt=""/>
                            <h3 className="f-title">Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air</h3>
                          </TransitionLink>
                        </div>

                        <div className={`${styles.sec2_item} ${styles.alignItemsEnd} ${styles.imgCover}`}>
                          <TransitionLink
                            to={`/work-detail/doan`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-doan.jpg`} alt=""/>
                            <h3 className="f-title">DOAN - thời trang đại diện cho khái niệm Effortless beauty</h3>
                          </TransitionLink>
                        </div>

                        <div className={`${styles.sec2_item} ${styles.alignItemsEnd}`}>
                          <TransitionLink
                            to={`/work-detail/wonder`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-wd.jpg`} alt=""/>
                            <h3 className="f-title">Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé</h3>
                          </TransitionLink>
                        </div>

                        <div className={`${styles.sec2_item} ${styles.alignItemsCenter} ${styles.imgCover}`}>
                          <TransitionLink
                            to={`/work-detail/mic`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-mic.jpg`} alt=""/>
                            <h3 className="f-title">Một thập kỷ xây dựng niềm tin với MIC</h3>
                          </TransitionLink>
                        </div>

                        <div className={`${styles.sec2_item} ${styles.alignItemsStart}`}>
                          <TransitionLink
                            to={`/work-detail/han`}
                            exit={{
                              trigger: ({ exit, node }) => keyInsightExit(node),
                              zIndex: 1,
                              length: 0.5,
                            }}
                            entry={{
                              trigger: ({ exit, node }) => keyInsightEntry(node),
                              zIndex: 1,
                              length: 1,
                              delay: 0.3
                            }}
                            className={`${styles.sec2_project} ${styles.style1} ${styles.links}`}
                          >
                            <span className="f-subtitle">Design/Identity</span>
                            <img className="f-img" src={`/img/work/thumb/thumb-han.jpg`} alt=""/>
                            <h3 className="f-title">Lưu giữ nét Hà Nội trong không gian The HAN Hotel</h3>
                          </TransitionLink>
                        </div>

                  </div>
                </div>
              </SectionFull>
              <ContactWork />
          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
