import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/04",
  "guid": "d28bf6e6-8ec0-4aab-8735-138f60f5a17b",
  "title": "Thương hiệu quá khác biệt có tạo ra vấn đề?",
  "subtitle": "The fourth episode.",
  "publicationDate": "2020-11-26T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Sự khác biệt luôn đi kèm 2 yếu tố cơ hội và thách thức. Đôi lúc chính sự khác biệt hình thành nên nỗi sợ đi ngược lại với đám đông, sợ mình làm sai...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_04.mp3",
  "size": 29810898,
  "duration": 1492,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sự khác biệt luôn đi kèm 2 yếu tố cơ hội và thách thức. Đôi lúc chính sự khác biệt hình thành nên nỗi sợ đi ngược lại với đám đông, sợ mình làm sai. Vậy một thương hiệu quá khác biệt so với đám đông có gây ảnh hưởng tới thương hiệu đó hay không? Hãy cùng chúng tôi trả lời câu hỏi này tại kỳ podcast lần này!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      