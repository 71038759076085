import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/06",
  "guid": "0cdd9fcd-cb22-4eb2-974c-dfb7ed26dc90",
  "title": "Trằn trọc về trường học design",
  "subtitle": "The sixth episode.",
  "publicationDate": "2020-12-09T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Với những câu hỏi dạng: Mình đang học cái gì vậy nhỉ? Cái này ứng dụng ra sao vào thực tế? Làm thế nào để hiểu hơn về nó bây giờ?...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_06.mp3",
  "size": 48925494,
  "duration": 2448,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Với những câu hỏi dạng: Mình đang học cái gì vậy nhỉ? Cái này ứng dụng ra sao vào thực tế? Làm thế nào để hiểu hơn về nó bây giờ?... Hẳn chúng ta đã từng băn khoăn về những câu hỏi như vậy trong những tháng ngày còn ngồi trên ghế nhà trường. Trong kỳ podcast lần này, khách mời của Beautique là những bạn trẻ đang theo đuổi ngành thiết kế đồ họa với rất nhiều trăn trở trên hành trình của mình. Mời bạn lắng nghe cùng chúng tôi!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      