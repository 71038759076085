import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/08",
  "guid": "13c1c5a2-e63d-4b1c-9778-d0f6621012c7",
  "title": "Xấu đẹp ra sao? Feedback thế nào?",
  "subtitle": "The eighth episode.",
  "publicationDate": "2020-12-23T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Câu chuyện thấu hiểu giữa Client và Agency dường như là một đường hầm chưa được khai thông. Client chưa hiểu những gì Agency muốn nói và Agency đôi khi...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_08.mp3",
  "size": 42289308,
  "duration": 2114,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Câu chuyện thấu hiểu giữa Client và Agency dường như là một đường hầm chưa được khai thông. Client chưa hiểu những gì Agency muốn nói và Agency đôi khi cũng chưa nắm rõ tâm tư nguyện vọng của Client.
Những hiểu lầm trong quá trình làm việc, đặc biệt tại khâu feedback giữa đôi bên gây cản trở không nhỏ cho toàn bộ dự án.
Vậy làm thế nào để giải quyết vấn đề thiếu thấu hiểu trong giao tiếp, nguyên nhân dẫn đến những bế tắc giữa đôi bên là gì? Mời các bạn cùng chúng tôi tìm lời giải đáp ngay tại podcast dưới đây.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      