import React, { Component } from "react"
import { Link } from "gatsby"

import SEO from '../../components/layout/SEO'
import Layout from '../../components/layout/layout'
import SectionFull from "../../components/layout/sectionFull"

import ScrollPageComponent from '../../components/modules/scrollpage';
import { scrollToElement } from '../../helpers/utils'
import ContactWork from '../../components/modules/contactWork';

import styles from "./han.module.scss"

export default class Insight extends Component {

  scrollHandle = (callbackScroll) => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {

    return (
      <>
        <SEO
          title="Lưu giữ nét Hà Nội trong không gian The HAN Hotel"
          url="https://beautique.vn/work-detail/han"
          description="Đâu đó trong không gian khách sạn, bạn sẽ bắt gặp một ban công nhỏ điểm chút hoa giấy tím, những bức ảnh về phố xá Hà Nội xưa cũ, hay đơn giản, chỉ là một vài viên gạch nhỏ mang dấu ấn của các ngôi nhà cũ thời bao cấp những năm 80. Đó là đôi lời tựa giới thiệu về thương hiệu The HAN Hotel."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-han.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
              <SectionFull className={styles.sec1}>
                  <div className={styles.inner}>
                      <div>
                          <h2 className={styles.sec1_title}>Lưu giữ nét Hà Nội trong không gian The HAN Hotel</h2>
                      </div>
                      <div className={styles.sec1_content}>
                      <div className={styles.sec1_left}>
                          <ul className={styles.sec1_list}>
                          <li><span>Client</span>The HAN Hotel</li>
                          <li><span>Duration</span>03/2017 - 04/2017</li>
                          <li><span>Scope</span>Brand Identity</li>
                          </ul>
                      </div>
                      <div className={styles.sec1_right}>
                          <p>Đâu đó trong không gian khách sạn, bạn sẽ bắt gặp một ban công nhỏ điểm chút hoa giấy tím, những bức ảnh về phố xá Hà Nội xưa cũ, hay đơn giản, chỉ là một vài viên gạch nhỏ mang dấu ấn của các ngôi nhà cũ thời bao cấp những năm 80. Đó là đôi lời tựa giới thiệu về thương hiệu The HAN Hotel.</p>
                      </div>
                      <button onClick={this.pageTranslateX} className={styles.sec1_btn}><img src="/img/icon/icon-arrow-next.svg" alt=""/></button>
                      </div>
                  </div>
              </SectionFull>


              <SectionFull className={styles.sec2}>
                <div className={styles.inner}>
                  <div>
                      <img src="/img/work/HAN/han1.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec3}>
                  <div className={styles.inner}>
                      <div className={styles.sec3_left}>
                          <div className={styles.blockquote}>
                              <h3>
                                  <div>
                                      <img src="/img/work/HAN/quote1.png" alt=""/>
                                  </div>
                                      The Han Hotel ra đời, như một góc nhỏ lưu giữ những hoài niệm của chúng tôi về Hà Nội.
                                  <span>
                                      <img src="/img/work/HAN/quote2.png" alt=""/>
                                  </span>
                              </h3>
                          </div>
                      </div>
                      <div className={styles.sec3_right}>
                          <img src="/img/work/HAN/han2.png" alt="" />
                      </div>
                  </div>
              </SectionFull>

              <SectionFull className={styles.sec4}>
                <div className={styles.inner}>
                  <div className={styles.sec4_left}>
                      <h3>Logo construction và vùng an toàn</h3>
                      <p>Màu sắc được lựa chọn cho Journey On Air có mối liên kết chặt chẽ với chiến lược về nội dung tin tức của thương hiệu. Có 3 bộ màu chính tương ứng với 3 chủ đề bài viết bao gồm về Thiên Nhiên Việt Nam, Chủ đề về Văn Hoá & Nghệ Thuật và Chủ đề Lịch Sử & Xã Hội.</p>
                  </div>
                  <div className={styles.sec4_right}>
                      <div className={styles.sec4_img1}>
                          <img src="/img/work/HAN/han3.png" alt="" />
                      </div>
                      <div className={styles.sec4_img2}>
                          <img src="/img/work/HAN/han4.png" alt="" />
                      </div>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec5}>
                <div className={styles.inner}>
                  <div className={styles.sec5_left}>
                      <div className={styles.sec5_left_scroll}>
                          <div className={styles.sec5_img1}>
                              <div>
                                  <img src="/img/work/HAN/han5.png" alt="" />
                              </div>
                              <p>Nostagia Teal<br />516573</p>
                          </div>
                          <div className={styles.sec5_img2}>
                              <div>
                                  <img src="/img/work/HAN/han6.png" alt="" />
                              </div>
                              <p>Bygone Brown<br />CD845C</p>
                          </div>
                      </div>
                      </div>
                  <div className={styles.sec5_right}>
                      <h3>Màu sắc thương hiệu</h3>
                      <p>Màu sắc được lựa chọn cho The HAN Hotel đem lại một chút hoài cổ và sự truyền thống cho thương hiệu, như gửi gắm một phần ký ức của Hà Nội những năm 80, vừa đem lại một vẻ sang trọng, đương đại cho không gian thương hiệu.</p>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec6}>
                <div className={styles.inner}>
                  <img src="/img/work/HAN/han19.png" alt="" />
                </div>
              </SectionFull>

              <SectionFull className={styles.sec7}>
                <div className={styles.inner}>
                  <div className={styles.sec7_left}>
                  <div className={styles.sec7_img1}>
                      <img src="/img/work/HAN/shape1.png" alt="" />
                  </div>
                  <div className={styles.sec7_img2}>
                      <img src="/img/work/HAN/shape1.png" alt="" />
                  </div>
                  <div className={styles.sec7_img3}>
                      <img src="/img/work/HAN/shape1.png" alt="" />
                  </div>
                  </div>
                  <div className={styles.sec7_right}>
                  <h3>Hoạ tiết thương hiệu</h3>
                  <p>Lấy cảm hứng từ hoa văn Á Đông pha thêm một chút của sự đương đại, tinh tế. Hoa văn chúng tôi lựa chọn cho thương hiệu The HAN Hotel được tạo nên từ chính logomark của bộ nhận diện với các sắc độ và kích thước khác nhau, giúp mang lại một trải nghiệm thị giác đặc biệt cho thương hiệu.</p>
                  <div className={styles.sec7_img4}>
                      <img src="/img/work/HAN/shape1.png" alt="" />
                  </div>
                  <div className={styles.sec7_img5}>
                      <img src="/img/work/HAN/shape1.png" alt="" />
                  </div>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec8}>
                <div className={styles.inner}>
                  <div className={styles.sec8_left}>
                  <img src="/img/work/HAN/han8.png" alt="" />
                  </div>
                  <div className={styles.sec8_right}>
                  <img src="/img/work/HAN/han9.png" alt="" />
                  </div>
                </div>
              </SectionFull>


              <SectionFull className={styles.sec9}>
                <div className={styles.inner}>
                  <img src="/img/work/HAN/han10.png" alt="" />
                </div>
              </SectionFull>

              <SectionFull className={styles.sec10}>
                <div className={styles.inner}>
                <div className={styles.sec10_left}>
                  <h3>Điểm chạm thương hiệu</h3>
                  <p>Để mang lại một trải nghiệm toàn diện cho thương hiệu, đội ngũ Beautique đã phát triển toàn bộ bộ nhận diện trải dài tại các điểm chạm offline, từ những chiếc pin cài áo cho đội ngũ nhân viên, bộ ứng dụng văn phòng, cho tới hệ thống chỉ đường được thiết kế riêng cho The HAN Hotel.</p>
                  </div>
                  <div className={styles.sec10_right}>
                  <div className={styles.sec10_img1}>
                      <img src="/img/work/HAN/han11.png" alt="" />
                  </div>
                  <div className={styles.sec10_img2}>
                      <img src="/img/work/HAN/han12.png" alt="" />
                  </div>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec11}>
                <div className={styles.inner}>
                  <div className={`${styles.sec11_box} ${styles.box1}`}>
                  <div className={styles.box_left}>
                      <h3>o1/</h3>
                      <p>Business Card</p>
                  </div>
                  <div className={styles.box_right}>
                      <img src="/img/work/HAN/han13.png" alt="" />
                  </div>
                  <div className={styles.shape1}>
                      <img src="/img/work/HAN/shape2.png" alt="" />
                  </div>
                  <div className={styles.shape2}>
                      <img src="/img/work/HAN/shape2.png" alt="" />
                  </div>
                  </div>
                  <div className={`${styles.sec11_box} ${styles.box2}`}>
                  <div className={styles.box_left}>
                      <h3>o2/</h3>
                      <p>Envelope</p>
                  </div>
                  <div className={styles.box_right}>
                      <img src="/img/work/HAN/han14.png" alt="" />
                  </div>
                  <div className={styles.shape3}>
                      <img src="/img/work/HAN/shape2.png" alt="" />
                  </div>
                  </div>
                  <div className={`${styles.sec11_box} ${styles.box3}`}>
                  <div className={styles.box_left}>
                      <h3>o3/</h3>
                      <p>Door Hanger</p>
                  </div>
                  <div className={styles.box_right}>
                      <img src="/img/work/HAN/han15.png" alt="" />
                  </div>
                  </div>
                  <div className={`${styles.sec11_box} ${styles.box4}`}>
                  <img src="/img/work/HAN/han16.png" alt="" />
                  </div>
                  <div className={`${styles.sec11_box} ${styles.box5}`}>
                  <div className={styles.box_left}>
                      <h3>o4/</h3>
                      <p>Pictogram System</p>
                  </div>
                  <div className={styles.box_right}>
                      <img src="/img/work/HAN/han17.png" alt="" />
                  </div>
                  </div>
                  <div className={`${styles.sec11_box} ${styles.box6}`}>
                  <img src="/img/work/HAN/han18.png" alt="" />
                  </div>

                </div>
              </SectionFull>

          <SectionFull className={styles.sec12}>
              <div className={styles.inner}>
                  <h3>Thank you!</h3>
                  <div className={styles.shape1}>
                      <img src="/img/work/HAN/shape2.png" alt="" />
                  </div>
                  <div className={styles.shape2}>
                      <img src="/img/work/HAN/shape2.png" alt="" />
                  </div>
              </div>
          </SectionFull>

          <SectionFull className={styles.sec13}>
                <div className={styles.inner}>
                <h3 className={styles.sec13Title}>Dự án điển hình.</h3>
                  <div className={styles.listWork}>
                  <div className={styles.listItem}>
                      <Link to="/work-detail/olympia-school">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-olympia.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">The Olympia School - Trường học của tương lai</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/joa">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-joa.png`} alt=""/>
                        </div>
                        <h3 className="f-title">Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/wonder">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-wd.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </SectionFull>

              <ContactWork />


          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
