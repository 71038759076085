// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b

const calcWinsize = () => {
  return { width: window.innerWidth, height: window.innerHeight }
}

// Gets the mouse position
const getMousePos = e => {
  return { x: e.clientX, y: e.clientY }
}

const distance = (x1, y1, x2, y2) => {
  var a = x1 - x2
  var b = y1 - y2

  return Math.hypot(a, b)
}

class ElementHover {
  constructor(el) {
    // DOM elements
    // el: main element
    this.DOM = { el: el }
    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: 0.1 },
      ty: { previous: 0, current: 0, amt: 0.1 },
      scale: { previous: 1, current: 1, amt: 0.2 },
    }
    this.mousepos = { x: 0, y: 0 }
    this.winsize = calcWinsize()

    // state (hover)
    this.state = {
      hover: false,
    }
    // calculate size/position
    this.calculateSizePosition()
    // init events
    this.initEvents()
    // loop fn
    requestAnimationFrame(() => this.render());

  }

  calculateSizePosition() {
    // size/position
    this.rect = this.DOM.el.getBoundingClientRect()
    // the movement will take place when the distance from the mouse to the center of the button is lower than this value
    this.distanceToTrigger = this.rect.width * 0.8
  }

  initEvents() {
    window.addEventListener("resize", () => (this.winsize = calcWinsize()))

    // Track the mouse position

    window.addEventListener("mousemove", ev => {
      this.mousepos = getMousePos(ev);
    })
    this.onResize = () => this.calculateSizePosition()
    window.addEventListener("resize", this.onResize)
  }

  render() {
    this.calculateSizePosition();
    // calculate the distance from the mouse to the center of the button
    const distanceMouseButton = distance(
      this.mousepos.x,
      this.mousepos.y,
      this.rect.x + this.rect.width / 2,
      this.rect.y + this.rect.height / 2
    )
    let x = 0
    let y = 0

    if (distanceMouseButton < this.distanceToTrigger) {
      x = (this.mousepos.x - (this.rect.x + this.rect.width / 2)) * 0.3
      y = (this.mousepos.y - (this.rect.y + this.rect.height / 2)) * 0.3
    }

    this.renderedStyles["tx"].current = x
    this.renderedStyles["ty"].current = y

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt
      )
    }
    this.DOM.el.style.transform = `translate3d(${this.renderedStyles["tx"].previous}px, ${this.renderedStyles["ty"].previous}px, 0)`

    requestAnimationFrame(() => this.render())
  }

  enter() {
    this.state.hover = true
  }

  leave() {
    this.state.hover = false
  }
}

export { ElementHover };
