import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/16",
  "guid": "75f380a3-27b1-4955-af56-62fe3210a462",
  "title": "Kinh doanh phong trào - khi doanh nghiệp thiếu sáng tạo",
  "subtitle": "Kinh doanh phong trào - khi doanh nghiệp thiếu sáng tạo",
  "publicationDate": "2021-03-25T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Thiếu sự khác biệt trong mắt khách hàng, lượng khách hàng trung thành thấp, nhân sự không gắn bó với công ty, … Những điều này có đẩy công ty bạn tới bờ vực phá sản không? Chưa chắc!",
  "url": "https://beautique.vn/mp3/Beauthik_talk_16.mp3",
  "size": 57858820,
  "duration": 1808,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Thiếu sự khác biệt trong mắt khách hàng, lượng khách hàng trung thành thấp, nhân sự không gắn bó với công ty, … Những điều này có đẩy công ty bạn tới bờ vực phá sản không? Chưa chắc.
Vậy nhưng, liệu bạn sẽ có thể tiếp tục bước đi mạnh mẽ chứ? Chắc chắn là không.
Thiếu đi những điều kể trên, thương hiệu sẽ chỉ như một con thuyền lạc bến, trôi tự do vô phương hướng trên đại dương rộng lớn. Cùng chúng tôi tìm hiểu về những rủi ro của doanh nghiệp khi thiếu đi sự sáng tạo trong quá trình xây dựng thương hiệu tại podcast dưới đây!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      