/**
 *  TranslateX Page to one point when scroll
 * @param {Number} pointScroll
 * @param {Element} fixcontainer
 * @param {Object} objScroll
 * @param  {Array} sectionElements
 */
const scrollToPoint = (pointScroll, objScroll, ...sectionElements) => {
  window.scrollTo({
    top: pointScroll,
    left: 0,
    behavior: "smooth",
  })
  if (!sectionElements.length) return
  if (objScroll.type === "negative") {
    for (const e of sectionElements) {
      e.style.transform = `translateX(-${objScroll.scrollTop * 0.01}px)`
      setTimeout(() => {
        e.style.transform = `translateX(0)`
      }, 350)
    }
  } else {
    for (const e of sectionElements) {
      e.style.transform = `translateX(${objScroll.scrollTop * 0.01}px)`
      setTimeout(() => {
        e.style.transform = `translateX(0)`
      }, 350)
    }
  }
}

const triggerResize = () => {
  if (document.createEvent) {
    let resizeEvent = window.document.createEvent("UIEvents")
    resizeEvent.initUIEvent("resize", true, false, window, 0)
    window.dispatchEvent(resizeEvent)
  } else {
    window.dispatchEvent(new Event("resize"))
  }
}

const animateFrame = () => {
  setTimeout(() => {
    triggerResize()
  }, 1000)

  let lastTime = 0,
    id = null
  let vendors = ["ms", "moz", "webkit", "o"]
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + "RequestAnimationFrame"]
    window.cancelAnimationFrame =
      window[vendors[x] + "CancelAnimationFrame"] ||
      window[vendors[x] + "CancelRequestAnimationFrame"]
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function(callback) {
      let currTime = new Date().getTime()
      let timeToCall = Math.max(0, 16 - (currTime - lastTime))
      id = window.setTimeout(function() {
        callback(currTime + timeToCall)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function(id) {
      clearTimeout(id)
    }
  }
}

export { scrollToPoint, animateFrame }
