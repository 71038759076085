import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/13",
  "guid": "320a12cb-84f7-42c2-8f2d-c280f44d5325",
  "title": "Freelancer - Độc lập, tự do, liệu có hạnh phúc?",
  "subtitle": "Freelancer",
  "publicationDate": "2021-02-19T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Trải qua gần 1 năm dài với những diễn biến dịch bệnh khó lường, công việc Freelancer ngày càng khẳng định tính linh hoạt so với những vị trí cố định khác.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_13.mp3",
  "size": 47760390,
  "duration": 2388,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Trải qua gần 1 năm dài với những diễn biến dịch bệnh khó lường, công việc Freelancer ngày càng khẳng định tính linh hoạt so với những vị trí cố định khác.
Tuy vậy, Freelance đồng thời cũng là một công việc đòi hỏi tính chủ động rất cao. Liệu có phải ai cũng có thể trở thành một Freelancer? Trong podcast lần này, mời bạn cùng Hương Giang và Beautique trò chuyện về chủ đề nêu trên!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      