import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/22",
  "guid": "2e5c8218-8197-4309-ae6f-d05f3086b7fe",
  "title": "Vai trò của Design: Làm đẹp có mục đích",
  "subtitle": "Vai trò của Design: Làm đẹp có mục đích",
  "publicationDate": "2021-05-05T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Design là công cụ giúp make up thương hiệu đẹp đẽ hơn trong mắt khách hàng mục tiêu. Nhưng hãy đẹp có chủ đích, đẹp có dẫn đắt. Có như vậy, thương hiệu mới thật sự lôi cuốn được những tệp khách hàng thuộc về thương hiệu.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_22.mp3",
  "size": 68466660,
  "duration": 2140,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Design là công cụ giúp make up thương hiệu đẹp đẽ hơn trong mắt khách hàng mục tiêu. Nhưng hãy đẹp có chủ đích, đẹp có dẫn đắt. Có như vậy, thương hiệu mới thật sự lôi cuốn được những tệp khách hàng thuộc về thương hiệu. Hãy cùng chúng tôi trò chuyện về vai trò của Design trong podcast tuần này.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      