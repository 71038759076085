// mouse drag
let isEnter = false,
  mouseX = null,
  scrollTop = 0

const mouseDown = (cursor, cursorCircle, isMouseDrag, evt) => {
  if (isMouseDrag) {
    scrollTop = window.scrollY
    mouseX = evt.clientX
    isEnter = true
  }

  if (cursor && cursorCircle) {
    cursor.style.transform = "scale(0.7)"
    cursorCircle.style.transform = "scale(2)"
  }
}

const mouseMove = (cursor, cursorCircle, evt) => {
  if (isEnter) {
    if (evt.clientX - mouseX < 0) {
      window.scrollTo({
        top: scrollTop + mouseX - evt.clientX,
      })
    } else {
      window.scrollTo({
        top: scrollTop - evt.clientX + mouseX,
      })
    }
  }
  // cursor custom
  if (cursor && cursorCircle) {
    cursor.style.opacity = "1"
    cursor.style.top = evt.clientY - 4 + "px"
    cursor.style.left = evt.clientX - 4 + "px"
    cursorCircle.style.opacity = "1"
    setTimeout(() => {
      cursorCircle.style.top = evt.clientY - 20 + "px"
      cursorCircle.style.left = evt.clientX - 20 + "px"
    }, 200)
  }
}

const mouseUp = (cursor, cursorCircle, isMouseDrag) => {
  if (isMouseDrag) isEnter = false
  cursor.style.transform = "scale(1.1)"
  cursorCircle.style.transform = "scale(1)"
}

export { mouseDown, mouseMove, mouseUp }
