import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/07",
  "guid": "ddd312ca-aebe-4703-8f0b-5c1450649f90",
  "title": "Bao nhiêu option cho đủ?",
  "subtitle": "The seventh episode.",
  "publicationDate": "2020-12-17T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Có nhiều option để có nhiều sự so sánh? Bao nhiêu cho đủ khi có thể \"không bao giờ là đủ?\"",
  "url": "https://beautique.vn/mp3/Beauthik_talk_07.mp3",
  "size": 58571010,
  "duration": 2929,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Có nhiều option để có nhiều sự so sánh?
Bao nhiêu cho đủ khi có thể "không bao giờ là đủ?"
Client có lý do của họ, Agency có quan điểm của mình.
Cùng chúng tôi trả lời câu hỏi “Bao nhiêu option là đủ?” ngay tại podcast dưới đây!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      