import React, { Component } from "react"

import SEO from '../components/layout/SEO'
import Layout from '../components/layout/layout'
import SectionFull from "../components/layout/sectionFull"
import KeyTeam from "../components/modules/keyTeam"
import ContactComponent from '../components/modules/contact'

import { addClassAnimate, handleNextSlide } from '../helpers/utils'
import { scrollToPoint, animateFrame } from '../helpers/page-scroll'
import { $, changeClassElement } from '../helpers/dom'
import ScrollPageComponent from '../components/modules/scrollpage';

import styles from "./about.module.scss"

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataKeyTeams: [
        {
          img: 'person/img1_anh_Ha.jpg',
          title: 'Phạm Hoàng Hà',
          position: 'Creative Director',
          active: true
        },
        {
          img: 'person/img5_a_Son.jpg',
          title: 'Đào Hải Sơn',
          position: 'Brand Statregist '
        },
        {
          img: 'person/img3_a_Bach.jpg',
          title: 'Nguyễn Cường Bách',
          position: 'Marketing Director'
        },
        {
          img: 'person/img4_c_Hoa.jpg',
          title: 'Bùi Thị Hoà',
          position: 'Account Executive'
        },
        {
          img: 'person/img2_a_Neo.jpg',
          title: 'Khuất Hoài Nam',
          position: 'Technology Advisor'
        },
        {
          img: 'person/img6_Huyen.jpg',
          title: 'Tạ Bích Huyền',
          position: 'Designer'
        },
        {
          img: 'person/img7_Trang.jpg',
          title: 'Nguyễn Thị Hà Trang',
          position: 'Designer'
        }
      ]
    }

    this.distanceScroll = 0;
    this.sec4axisX = {
      axisXpos: false,
      axisXNeg: false,
    };
    this.sec5axisX = {
      axisXpos: false,
      axisXNeg: false,
    };
    this.checkBrowser = false;
    this.pointScroll = 1;
    this.req = null;
  }

  componentDidMount() {
    animateFrame();
    // check browser
    if (window.navigator.userAgent.includes('Firefox') || window.navigator.userAgent.includes('Edg')) {
      this.checkBrowser = true;
      this.pointScroll = 2;
    }

    // add slide tab in section 5
    const menuTabEles = Array.from($(document, ".js-menu", 'NodeList'));
    const sec5Count = $(document, `.${styles.sec5_count}`);
    const nextSlide = $(document, `.${styles.nextSlide}`);
    let boxActive = null, sec5LeftActive = null;
    const box = $(document, `.${styles.sec5_tabPanel}`);

    box.addEventListener('touchstart', function(e) {
      this.dis = 0;
      this.x1 = e.touches[0].clientX;
    });

    box.addEventListener('touchmove', function(e) {
      this.x2 = e.touches[0].clientX;
      this.dis = this.x2 - this.x1;
    });

    box.addEventListener('touchend', function() {
      boxActive = $(box, '.box-active');
      sec5LeftActive = $(boxActive, '.sec5LeftActive');
      if (this.dis <= 0 && Math.abs(this.dis) > 20) {
        handleNextSlide(sec5Count, boxActive, sec5LeftActive);
      } else if (this.dis >= 0 && Math.abs(this.dis) > 20){
        return;
      }
    });

    menuTabEles.forEach((e, i) => {
      e.addEventListener('click', function() {
        if (e.className.includes('active')) return;
        for (const e of menuTabEles) { changeClassElement('REMOVE', 'active', e); }
        changeClassElement('ADD', 'active', this);

        let boxElement = $(document, `[data-index="${i}"]`);
        for(const el of boxElement.parentElement.childNodes) { changeClassElement('REMOVE', 'box-active', el);  };
        changeClassElement('ADD', 'box-active', boxElement);

        boxElement.parentElement.style.left = `-${boxElement.offsetLeft}px`;

        boxActive = $(document, `.${styles.sec5_box}.box-active`);
        sec5LeftActive = $(boxActive, '.sec5LeftActive');
        let index = [...boxActive.childNodes].indexOf(sec5LeftActive);

        sec5Count.firstChild.textContent = `o${index + 1}`;
        sec5Count.lastChild.textContent = `o${boxActive.childNodes.length}`;
      });
    });

    nextSlide.addEventListener('click', () => {
      boxActive = $(document, `.${styles.sec5_box}.box-active`);
      sec5LeftActive = $(boxActive, '.sec5LeftActive');
      handleNextSlide(sec5Count, boxActive, sec5LeftActive);
    });

    const personElements = $(document, `.person`, 'NodeList');
    const ww1 = window.innerWidth / 2 + 496;
    const ww2 = window.innerWidth / 2;
    const ww3 = window.innerWidth / 2 - 496;
    const ww4 = window.innerWidth / 2 - 1000;
    const elementActive = $(document, `.element-active`, 'NodeList');
    const sec2Circle1 = $(document, '.style1');
    const sec2Circle2 = $(document, '.style2');
    const star = $(document, `.${styles.star}`);

    setTimeout(() => {
      this.active = true;
      sec2Circle1.parentElement.classList.add(styles.circleActive);
    }, 1000);

    const activeLinks = () => {
      sec2Circle1.parentElement.style.opacity = "1";
      Array.prototype.forEach.call(personElements, (element, i) => {
        const xEl = element.getBoundingClientRect().x;
        const img = element.querySelector('img');
        if (xEl <= ww1 && xEl >= ww2) {
          const d = Math.floor(Math.abs(xEl - ww1));
          element.firstElementChild.style.width = `${290 + Math.floor(d*0.41)}px`;
          element.firstElementChild.style.height = `${386 + Math.floor(d*0.55)}px`;
          img.style.filter = `grayscale(${1 - d/495})`;

          element.style.top = `${30 - Math.floor(d/16.5)}px`;

          if (element.previousElementSibling) {
            element.previousElementSibling.dataset.tf = Math.floor(d*0.41);
            element.previousElementSibling.style.transform = `translateX(-${Math.floor(d*0.41)}px)`;
          }
          if (i >= 2) {
            try {
              element.previousElementSibling.previousElementSibling.style.transform = `translateX(-${+element.previousElementSibling.previousElementSibling.dataset.tf + Math.floor(d*0.41)}px)`;
            } catch (error) {}
          }
          element.firstElementChild.nextSibling.classList.remove('showInfo');
        } else if (xEl < ww2 && xEl > ww3) {
          element.firstElementChild.style.height = `495px`;
          element.firstElementChild.style.height = `660px`;
          img.style.filter = `grayscale(0)`;
          element.firstElementChild.nextElementSibling.classList.add('showInfo');
          element.style.top = `0px`;
        }
        else if (xEl <= ww3 && xEl >= ww4) {
          const d1 = Math.floor(Math.abs(xEl - ww3));

          element.firstElementChild.style.width = `${495 - (d1*0.41)}px`;
          element.firstElementChild.style.height = `${660 - (d1*0.55)}px`;
          img.style.filter = `grayscale(${1 - (1-d1/495)})`;

          element.style.top = `${Math.floor(d1/16.5)}px`;
          element.firstElementChild.nextSibling.classList.remove('showInfo');
        }
        else if (xEl >= window.innerWidth/2 || xEl < ww4){
          element.firstElementChild.style.width = `290px`;
          element.firstElementChild.style.height = `386px`;
          img.style.filter = `grayscale(1)`;
          element.style.top = '30px';
          element.firstElementChild.nextSibling.classList.remove('showInfo');

        }
      });

      addClassAnimate(elementActive, styles.textbox1Active);

      const x1 = sec2Circle1.getBoundingClientRect();
      const x2 = sec2Circle2.getBoundingClientRect();

      if (this.active) {
        sec2Circle1.style.bottom = `${window.scrollY * 0.25 + 45}px`;
        sec2Circle2.style.bottom = `${window.scrollY * 0.25 + 45}px`;
        star.style.bottom = `${window.scrollY * 0.25 + 167}px`;
        sec2Circle1.style.left = `${window.scrollY * 0.15 + 45}px`;
        sec2Circle2.style.right = `${window.scrollY * 0.15 + 45}px`;
      }

      if (window.scrollY * 0.002 >= 0.8 && window.scrollY * 0.002 <= 2.1) {
        star.firstChild.style.transform = `scaleX(${window.scrollY*0.002})`;
      }

      if (x1.right - x2.left > 140) {
        sec2Circle1.firstChild.style.transform = `scale(0)`;
        sec2Circle2.firstChild.style.transform = `scale(0)`;
        setTimeout(() => {
          star.style.transform = `scale(1)`;
          star.style.opcaity = `1`;
        }, 500);
      }
      if (x1.right - x2.left < 70) {
        setTimeout(() => {
          sec2Circle1.firstChild.style.transform = `scale(1)`;
          sec2Circle2.firstChild.style.transform = `scale(1)`;
        }, 500);
        star.style.transform = `scale(0)`;
        star.style.opcaity = `0`;
      }

      this.req = window.requestAnimationFrame(activeLinks);
    }

    let animateMb = () => {
      const x1 = sec2Circle1.getBoundingClientRect();
      const x2 = sec2Circle2.getBoundingClientRect();

      if (this.active) {
        sec2Circle1.style.bottom = `${window.scrollY * 0.25}px`;
        sec2Circle2.style.bottom = `${window.scrollY * 0.25}px`;
        star.style.bottom = `${window.scrollY * 0.25 + 46}px`;
        sec2Circle1.style.left = `${window.scrollY * 0.25}px`;
        sec2Circle2.style.right = `${window.scrollY * 0.25}px`;
      }

      if (window.scrollY * 0.004 >= 0.8 && window.scrollY * 0.004 <= 1.6) {
        star.firstChild.style.transform = `scaleX(${window.scrollY*0.004})`;
      }

      if (x1.right - x2.left > 67) {
        sec2Circle1.firstChild.style.transform = `scale(0)`;
        sec2Circle2.firstChild.style.transform = `scale(0)`;
        setTimeout(() => {
          star.style.transform = `scale(1)`;
          star.style.opcaity = `1`;
        }, 500);
      }
      if (x1.right - x2.left < 40) {
        setTimeout(() => {
          sec2Circle1.firstChild.style.transform = `scale(1)`;
          sec2Circle2.firstChild.style.transform = `scale(1)`;
        }, 500);
        star.style.transform = `scale(0)`;
        star.style.opcaity = `0`;
      }

      this.reqMb = window.requestAnimationFrame(animateMb);
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1025) {
        window.cancelAnimationFrame(this.req);
        this.req = null;
        if (!this.reqMb) animateMb();
      } else {
        window.cancelAnimationFrame(this.reqMb);
        this.reqMb = null;
        if (!this.req) activeLinks();
      }
    });
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.req);
  }

  scrollHandle = (callbackScroll) => {
    const scrollTop = window.scrollY;
    callbackScroll(scrollTop)
    // // section 4,5,6
    // const sectionElements = $(document, 'section', 'NodeList');
    // const sec4OffsetLeft = sectionElements[3].offsetLeft;
    // const sec5OffsetLeft = sectionElements[4].offsetLeft;
    // const sec6OffsetLeft = sectionElements[5].offsetLeft;

    // // ascesding scroll
    // if (scrollTop > this.distanceScroll) {
    //   if (scrollTop + window.innerWidth <= sec4OffsetLeft && !this.sec4axisX.axisXpos) {
    //     callbackScroll(scrollTop, 0.15);
    //   }
    //   // display section 4
    //   else if (!this.sec4axisX.axisXpos && scrollTop + window.innerWidth > sec4OffsetLeft) {
    //     if (scrollTop > sec4OffsetLeft - window.innerWidth) {
    //       if (scrollTop - this.distanceScroll < this.pointScroll && !this.sec4axisX.axisXpos) {
    //         callbackScroll(sec4OffsetLeft - window.innerWidth);
    //         scrollToPoint(
    //           sec4OffsetLeft - window.innerWidth,
    //           { scrollTop, type: 'negative' },
    //           sectionElements[1].firstElementChild,
    //         );
    //       } else {
    //         callbackScroll(sec4OffsetLeft, 0.05);
    //         if (this.checkBrowser) {
    //           setTimeout(() => {
    //             scrollToPoint(sec4OffsetLeft);
    //           }, 200);
    //         } else {
    //           scrollToPoint(sec4OffsetLeft);
    //         }
    //         this.sec4axisX.axisXpos = true;

    //         changeClassElement('REMOVE', styles.translateXPositiveAbout,
    //           sectionElements[3].firstElementChild.firstElementChild,
    //           sectionElements[3].lastChild.firstElementChild
    //         );
    //       }
    //     }
    //   }
    //   // pass section 4 and display section 5
    //   else if (scrollTop > sec4OffsetLeft && !this.sec4axisX.axisXNeg && this.sec4axisX.axisXpos && (sectionElements[3].getBoundingClientRect().x >= -20 && sectionElements[3].getBoundingClientRect().x <= 20)) {
    //     if (scrollTop - this.distanceScroll < this.pointScroll && !this.sec4axisX.axisXNeg && this.sec4axisX.axisXpos) {
    //       callbackScroll(sec4OffsetLeft);
    //       scrollToPoint(
    //         sec4OffsetLeft,
    //         { scrollTop, type: 'negative' },
    //         sectionElements[3].childNodes[0],
    //         sectionElements[3].childNodes[1]
    //       );
    //     }
    //     else {
    //       callbackScroll(sec5OffsetLeft, 0.05);
    //       if (this.checkBrowser) {
    //         setTimeout(() => {
    //           scrollToPoint(sec5OffsetLeft);
    //         }, 200);
    //       } else {
    //         scrollToPoint(sec5OffsetLeft);
    //       }
    //       this.sec4axisX.axisXNeg = true;
    //       this.sec5axisX.axisXpos = true;

    //       changeClassElement('ADD', styles.translateXNegativeAbout,
    //         sectionElements[3].firstElementChild.firstElementChild,
    //         sectionElements[3].lastChild.firstElementChild
    //       );
    //       changeClassElement('REMOVE', styles.translateXPositiveAbout,
    //         sectionElements[4].firstElementChild.firstElementChild,
    //         sectionElements[4].lastChild.firstElementChild
    //       );
    //     }
    //   }
    //   // pass section 5 and display section 6
    //   else if (scrollTop > sec5OffsetLeft && !this.sec5axisX.axisXNeg && this.sec5axisX.axisXpos && (sectionElements[4].getBoundingClientRect().x >= -20 && sectionElements[4].getBoundingClientRect().x <= 20)) {
    //     if (scrollTop - this.distanceScroll < this.pointScroll &&!this.sec5axisX.axisXNeg && this.sec5axisX.axisXpos) {
    //       callbackScroll(sec5OffsetLeft);
    //       scrollToPoint(
    //         sec5OffsetLeft,
    //         { scrollTop, type: 'negative'},
    //         sectionElements[4].childNodes[0],
    //         sectionElements[4].childNodes[1]
    //       );
    //     }
    //     else {
    //       callbackScroll(sec6OffsetLeft, 0.05);
    //       if (this.checkBrowser) {
    //         setTimeout(() => {
    //           scrollToPoint(sec6OffsetLeft);
    //         }, 200);
    //       } else {
    //         scrollToPoint(sec6OffsetLeft);
    //       }
    //       this.sec5axisX.axisXNeg = true;

    //       changeClassElement('ADD', styles.translateXNegativeAbout,
    //         sectionElements[4].firstElementChild.firstElementChild,
    //         sectionElements[4].lastChild.firstElementChild
    //       );
    //       changeClassElement('REMOVE', styles.translateXPositiveAbout,
    //         sectionElements[6].firstElementChild.firstElementChild
    //       );
    //     }
    //   }
    //   // next scroll
    //   else if (scrollTop > sec6OffsetLeft && this.sec5axisX.axisXNeg){
    //     callbackScroll(scrollTop, 0.15);
    //   }
    // }
    // // descesding scroll
    // else {
    //   if (this.sec5axisX.axisXNeg && scrollTop - window.innerWidth > sec5OffsetLeft) {
    //     callbackScroll(scrollTop, 0.15);
    //   }
    //   // display section 5 and pass section 6
    //   else if (this.sec5axisX.axisXNeg && scrollTop - window.innerWidth <= sec5OffsetLeft) {
    //     if (this.distanceScroll - scrollTop < this.pointScroll) {
    //       callbackScroll(sec6OffsetLeft);
    //       scrollToPoint(
    //         sec6OffsetLeft,
    //         { scrollTop, type: 'positive' },
    //         sectionElements[5].firstElementChild,
    //         sectionElements[5].nextSibling.firstElementChild.firstElementChild
    //       );
    //     } else {
    //       callbackScroll(sec5OffsetLeft, 0.05);
    //       if (this.checkBrowser) {
    //         setTimeout(() => {
    //           scrollToPoint(sec5OffsetLeft);
    //         }, 200);
    //       } else {
    //         scrollToPoint(sec5OffsetLeft);
    //       }
    //       this.sec5axisX.axisXNeg = false;

    //       changeClassElement('REMOVE', styles.changeBgWhite,
    //         sectionElements[4],
    //         sectionElements[6]
    //       );
    //       changeClassElement('REMOVE', styles.translateXNegativeAbout,
    //         sectionElements[4].firstElementChild.firstElementChild,
    //         sectionElements[4].lastChild.firstElementChild
    //       );
    //       changeClassElement('ADD', styles.translateXPositiveAbout,
    //         sectionElements[6].firstElementChild.firstElementChild
    //       );
    //     }
    //   }
    //   // display section 4 and pass section 5
    //   else if (scrollTop < sec5OffsetLeft && this.sec5axisX.axisXpos && !this.sec5axisX.axisXNeg && (sectionElements[4].getBoundingClientRect().x >= -20 && sectionElements[4].getBoundingClientRect().x <= 20)) {
    //     if (this.distanceScroll - scrollTop < this.pointScroll && this.sec5axisX.axisXpos && !this.sec5axisX.axisXNeg) {
    //       callbackScroll(sec5OffsetLeft);
    //       scrollToPoint(
    //         sec5OffsetLeft,
    //         { scrollTop, type: 'positive' },
    //         sectionElements[4].childNodes[0],
    //         sectionElements[4].childNodes[1]
    //       );
    //     }
    //     else {
    //       callbackScroll(sec4OffsetLeft, 0.05);
    //       if (this.checkBrowser) {
    //         setTimeout(() => {
    //           scrollToPoint(sec4OffsetLeft);
    //         }, 200);
    //       } else {
    //         scrollToPoint(sec4OffsetLeft);
    //       }
    //       this.sec4axisX.axisXNeg = false;
    //       this.sec5axisX.axisXpos = false;

    //       changeClassElement('REMOVE', styles.translateXNegativeAbout,
    //         sectionElements[3].firstElementChild.firstElementChild,
    //         sectionElements[3].lastChild.firstElementChild
    //       );
    //       changeClassElement('ADD', styles.translateXPositiveAbout,
    //         sectionElements[4].firstElementChild.firstElementChild,
    //         sectionElements[4].lastChild.firstElementChild
    //       );
    //     }
    //   }
    //   // display sction 3 and pass section 4
    //   else if (scrollTop < sec4OffsetLeft && !this.sec4axisX.axisXNeg && this.sec4axisX.axisXpos && (sectionElements[3].getBoundingClientRect().x >= -20 && sectionElements[3].getBoundingClientRect().x <= 20)) {
    //     if (this.distanceScroll - scrollTop < this.pointScroll && !this.sec4axisX.axisXNeg && this.sec4axisX.axisXpos) {
    //       callbackScroll(sec4OffsetLeft);
    //       scrollToPoint(
    //         sec4OffsetLeft,
    //         { scrollTop, type: 'positive' },
    //         sectionElements[3].childNodes[0],
    //         sectionElements[3].childNodes[1]
    //       );
    //     }
    //     else {
    //       callbackScroll(sec4OffsetLeft - window.innerWidth, 0.05);
    //       if (this.checkBrowser) {
    //         setTimeout(() => {
    //           scrollToPoint(sec4OffsetLeft - window.innerWidth);
    //         }, 200);
    //       } else {
    //         scrollToPoint(sec4OffsetLeft - window.innerWidth);
    //       }
    //       this.sec4axisX.axisXpos = false;

    //       changeClassElement('ADD', styles.translateXPositiveAbout,
    //         sectionElements[3].firstElementChild.firstElementChild,
    //         sectionElements[3].lastChild.firstElementChild
    //       );
    //     }
    //   }
    //   else if (!this.sec4axisX.axisXpos && scrollTop + window.innerWidth <= sec4OffsetLeft) {
    //     callbackScroll(scrollTop, 0.15);
    //   }
    // }
    // this.distanceScroll = scrollTop;
  }

  pageTranslateX = () => {
    window.scrollTo({
      top: $(document, `.${styles.sec6}`).offsetLeft + 700,
      left: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const { dataKeyTeams } = this.state;

    return (
      <>
        <SEO
          title="Giới thiệu | Beautique - Brand and Creative Consultancy that helps businesses to tranform and succeed"
          url="https://beautique.vn/about"
          description="Beautique | Brand and Creative Consultancy that helps businesses to tranform and succeed"
          imgThumb="https://beautique.vn/img/home/og-img.jpg"
        />
        <Layout>
            <ScrollPageComponent scrollHandle={this.scrollHandle}>
              <SectionFull className={styles.sec1}>
                <div className={styles.inner}>
                  <div className={`${styles.textbox1} element-active`}>
                    <span>triết lí của chúng tôi</span>
                    <h3>Chúng tôi tin rằng sự phát triển và thành công sinh ra tại điểm giao thoa giữa tư duy nhạy bén trong kinh doanh và sự tiên phong trong sáng tạo.</h3>
                  </div>
                </div>
              </SectionFull>
              <SectionFull className={` ${styles.sec2} ${styles.alignItemsCenter} `}>
                <div className={`${styles.inner} `}>
                  <div className={styles.sec2_circle+' style1'}><span>B</span></div>
                  <div className={styles.sec2_circle+' style2'}><span>C</span></div>
                  <div className={styles.star}>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </SectionFull>
              <SectionFull className={`${styles.sec3} ${styles.alignItemsEnd}`}>
                <div className={`${styles.inner}`}>
                  <div className={`${styles.textbox1} element-active`}>
                    <img src="/img/logo-white.svg" alt="" />
                    <h3>Bản sắc thương hiệu chính là điểm giao thoa đó, để tạo ra mối quan hệ thân thiết giữa doanh nghiệp và khách hàng.</h3>
                  </div>
                </div>
              </SectionFull>
              <SectionFull className={styles.sec4}>
                <div className={styles.left}>
                  <div className={`${styles.inner}`}>
                    <div className={`${styles.textbox1}`}>
                      <h3>Với triết lý này, Beautique cung cấp hai dịch vụ là xây dựng bản sắc thương hiệu và đưa ra giải pháp sáng tạo truyền tải tới khách hàng.</h3>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={`${styles.inner}`}>
                    <div className={styles.sec4_box}>
                      <span className={styles.sec4_number}>o1 <span>/</span></span>
                      <h3 className={styles.sec4_title}>Strategy</h3>
                      <ul className={styles.sec4_list}>
                        <li>Brand</li>
                        <li>Communication</li>
                      </ul>
                    </div>
                    <div className={styles.sec4_box}>
                      <span className={styles.sec4_number}>o2<span>/</span></span>
                      <h3 className={styles.sec4_title}>Creative Solutions</h3>
                      <ul className={styles.sec4_list}>
                        <li>Identity</li>
                        <li>Website design</li>
                        <li>Editorial</li>
                        <li>Communication</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </SectionFull>
              <SectionFull className={styles.sec5}>
                <div className={styles.left}>
                  <div className={`${styles.inner}`}>
                    <h2 className={styles.sec5_title}>Quy trình<br />tiếp cận</h2>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={`${styles.inner}`}>
                    <div className={styles.sec5_tab}>
                      <ul className={styles.sec5_tabNav}>
                        <li className="active js-menu"><span>Brand Strategy</span></li>
                      </ul>
                      <div className={styles.sec5_tabContent}>
                        <div className={styles.sec5_tabPanel}>
                          <div className={`${styles.sec5_box} box-active`} data-index="0">
                            <div className={`${styles.sec5_left} sec5LeftActive`}>
                              <h3 className={styles.sec5_boxTitle}>Khám phá & Xác định phạm vi dự án.</h3>
                              <p className={styles.sec5_boxText}>Đây là thời điểm bắt đầu dự án giữa Doanh nghiệp và Beautique. Chúng tôi luôn đồng hành với doanh nghiệp để đi vào khám phá và xác định đề bài. Giai đoạn khám phá này tập trung vào đối tượng chính là doanh nghiệp. Ở bước này, điều cần thiết là hai bên cùng chia sẻ thẳng thắn về tầm nhìn của dự án, lý do doanh nghiệp quyết định thực hiện dự án cũng như mục tiêu mà doanh nghiệp mong muốn đạt được khi dự án khép lại. Đây chính là cơ sở cho vòng nghiên cứu dự án tiếp theo.</p>
                            </div>
                            <div className={styles.sec5_left}>
                              <h3 className={styles.sec5_boxTitle}>Nghiên cứu dự án.</h3>
                              <p className={styles.sec5_boxText}>
                                Bước nghiên cứu dự án là bước thu thập dữ liệu ban đầu và cũng là tiền đề quan trọng nhất để xây dựng bản sắc và phát triển bản sắc thương hiệu. Tại bước này có 3 đối tượng chính mà chúng tôi cần tìm hiểu chi tiết đó là bản thân doanh nghiệp, về khác hàng tiềm năng và cuối cùng là các đối thủ trên thị trường.
                                <br />
                                Tại bước này Beautique sẽ cùng với doanh nghiệp phân tích và tìm hiểu nhu cầu của khách hàng qua những công cụ như: Personas, Surveys, Interviews, Customer jouney mapping, Empathy map...
                              </p>
                            </div>
                            <div className={styles.sec5_left}>
                              <h3 className={styles.sec5_boxTitle}>Workshop xác định bản sắc thương hiệu.</h3>
                              <p className={styles.sec5_boxText}>Dựa vào những hiểu biết về tâm lý khách hàng tại bước nghiên cứu dự án, buổi workshop này được thiết kế để cả 2 phía sẽ cùng xác định lại những yếu tố nào là phù hợp để phát triển vào việc xây dựng bản sắc thương hiệu, những yếu tố nào không phù hợp với sự phát triển của thương hiệu trong tương lai.</p>
                            </div>
                            <div className={styles.sec5_left}>
                              <h3 className={styles.sec5_boxTitle}>Đề xuất chiến lược phát triển bản sắc thương hiệu.</h3>
                              <p className={styles.sec5_boxText}>Từ bản sắc thương hiệu (bao gồm tính cách, hình mẫu và yếu tố độc nhất) đã được xác định tại bước trước, đội ngũ Beautique sẽ đề xuất chiến lược phát triển bản sắc bao gồm định hướng thể hiện bản sắc thương hiệu trên các điểm chạm bằng hình ảnh, thiết kế, hay thông điệp truyền thông.</p>
                            </div>
                            <div className={styles.sec5_left}>
                              <h3 className={styles.sec5_boxTitle}>Phát triển bản sắc thương hiệu.</h3>
                              <p className={styles.sec5_boxText}>Sau khi định hướng phát triển bản sắc và phong cách thể hiện được ấn định, chúng sẽ trở thành kim chỉ nam quyết định thiết kế và dáng vẻ của toàn bộ điểm chạm của thương hiệu. Bắt đầu từ bước này đội ngũ của Beautique sẽ chính thức bắt tay vào việc thiết kế và xậy dựng các điểm chạm thương hiệu (logo, bộ nhận diện, website, truyền thông thương hiệu,...) tuỳ thuộc vào phạm vi của từng dự án. Mọi điểm chạm này không chỉ mang theo bản sắc của thương hiệu mà cả khi người dùng tương tác và trải nghiệm với điểm chạm thì chúng còn phải thể hiện tối đa bản sắc thương hiệu.</p>
                            </div>
                            <div className={styles.sec5_left}>
                              <h3 className={styles.sec5_boxTitle}>Quản trị thương hiệu.</h3>
                              <p className={styles.sec5_boxText}>Trong quá trình vận hành, phát triển và mở rộng phạm vi khing doanh của doanh nghiệp sẽ phát sinh nhiều yếu tố có thể ảnh hưởng đến hình ảnh của thương hiệu trong tiềm thức khách hàng. Cần liên tục lắng nghe, thu thập suy nghĩ và cảm xúc của khách hàng trong quá trình vận hành thương hiệu. Dữ liệu từ quá trình vận hành thương hiệu sẽ được dùng để tiếp tục nghiên cứu và từ đó phát triển hướng đi mới cho thương hiệu trong tương lai.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.nextSlide} mouse-hover`}>
                        <img src="/img/icon/icon-arrow-next.svg" alt="" />
                      </div>

                      <div className={styles.sec5_count}>
                        <span>o1</span>/<span>o6</span>
                      </div>
                    </div>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec6}>
                <div className={styles.inner}>
                  <div className={`${styles.info}`}>
                    <h3 className={styles.sec6_title}>Đội ngũ.</h3>
                    <p>Với khát vọng kiến tạo giá trị và tối ưu hóa sức mạnh tiềm ẩn trong mỗi sản phẩm, chúng tôi xây dựng cho mình tư duy thiết kế, triết lý sáng tạo và gửi gắm giá trị cho mỗi thương hiệu.</p>
                    <button onClick={this.pageTranslateX} className={`mouse-hover ${styles.btnNext}`}><img src="/img/icon/arrow-black.svg" alt="" /></button>
                  </div>
                  <div className={`${styles.sec6_content}`}>
                    {dataKeyTeams.map((key, index) => (
                      <KeyTeam key={index} img={key.img} title={key.title} position={key.position} active={key.active}/>
                      ))}
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec8}>
                  <div className={styles.sec8_left}>
                      <ContactComponent />
                  </div>
                  <div className={styles.sec8_right}>
                    <span className={styles.sec8_titleSocial}>theo dõi</span>
                    <div className={styles.sec8_social}>
                      <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'>
                      <svg width={36} height={36} viewBox="0 0 36 36">
                        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M20.988 8.95l-1.886-.003c-2.119 0-3.488 1.344-3.488 3.425v1.578h-1.896a.29.29 0 00-.297.284v2.288a.29.29 0 00.297.283h1.896v7.442a.29.29 0 00.296.284h2.474a.29.29 0 00.297-.284v-7.442h2.217a.29.29 0 00.297-.283v-2.288a.278.278 0 00-.086-.2.304.304 0 00-.21-.084h-2.218v-1.338c0-.643.16-.97 1.036-.97h1.27a.29.29 0 00.297-.284V9.234a.29.29 0 00-.296-.284z"
                            fill="#C8C8C8"
                          />
                        </g>
                      </svg>
                      </a>
                      <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='/#'>
                      <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                          stroke="#C8C8C8"
                          strokeWidth={1.068}
                          cx={17}
                          cy={16.846}
                          rx={17}
                          ry={16.846}
                        />
                        <g fill="#C8C8C8">
                          <path
                            d="M21.535 10.417H12.59a1.85 1.85 0 00-1.853 1.843v8.896a1.85 1.85 0 001.853 1.843h8.945a1.85 1.85 0 001.854-1.843V12.58a1.85 1.85 0 00-1.854-1.843zm-4.447 9.584c-1.84 0-3.336-1.488-3.336-3.318 0-1.829 1.496-3.317 3.336-3.317 1.839 0 3.335 1.488 3.335 3.317 0 1.83-1.496 3.318-3.335 3.318zm3.707-6.124a.947.947 0 01-.949-.944c0-.52.426-.943.95-.943.522 0 .948.423.948.943s-.426.944-.949.944z"
                            fillRule="nonzero"
                          />
                          <path d="M20.795 12.813a.442.442 0 00-.443.44.442.442 0 00.886 0 .442.442 0 00-.443-.44zM17.063 14.512a2.527 2.527 0 00-2.53 2.516 2.527 2.527 0 002.53 2.516 2.527 2.527 0 002.53-2.516 2.527 2.527 0 00-2.53-2.516z" />
                        </g>
                      </g>
                    </svg>
                      </a>
                      <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://dribbble.com/beauagency'>
                      <svg width={36} height={36} viewBox="0 0 36 36">
                    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                        stroke="#C8C8C8"
                        strokeWidth={1.068}
                        cx={17}
                        cy={16.846}
                        rx={17}
                        ry={16.846}
                      />
                      <g fill="#C8C8C8">
                        <path d="M19.106 17.633a28.263 28.263 0 011.528 5.353 7.056 7.056 0 001.316-1.036 6.948 6.948 0 001.953-3.784 8.672 8.672 0 00-4.797-.533zM21.956 12.056l-.006-.006A6.954 6.954 0 0017 10c-.731 0-1.445.112-2.123.327a28.363 28.363 0 012.86 4.347 16.247 16.247 0 004.219-2.618zM22.506 12.676a16.945 16.945 0 01-4.39 2.732c.236.476.46.958.67 1.448a9.59 9.59 0 015.207.46 6.947 6.947 0 00-1.487-4.64zM12.691 22.518A6.947 6.947 0 0017 24c1.005 0 1.978-.21 2.868-.611a27.513 27.513 0 00-1.58-5.575 8.758 8.758 0 00-5.597 4.704z" />
                        <path d="M16.868 17.437c.36-.152.727-.281 1.1-.387-.197-.45-.406-.895-.626-1.333a16.94 16.94 0 01-5.922 1.058c-.474 0-.946-.02-1.414-.058a6.954 6.954 0 002.044 5.232l.002.002a9.515 9.515 0 011.784-2.47 9.509 9.509 0 013.032-2.044zM16.959 14.98a27.862 27.862 0 00-2.898-4.337 7.004 7.004 0 00-2.01 1.407 6.947 6.947 0 00-1.965 3.85 16.14 16.14 0 006.873-.92z" />
                      </g>
                    </g>
                  </svg>
                      </a>
                      <a className="mouse-hover" target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'>
                      <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                        <g fill="#C8C8C8">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M15.804 16.502c.345-.178.607-.378.786-.593.319-.387.476-.9.476-1.538 0-.62-.157-1.15-.472-1.594-.525-.723-1.413-1.093-2.67-1.113H8.947v10.44h4.641c.523 0 1.007-.047 1.455-.14.448-.096.836-.27 1.164-.525.291-.222.535-.497.729-.821.306-.49.46-1.046.46-1.665 0-.6-.135-1.11-.402-1.53-.27-.419-.665-.726-1.19-.92zM11 13.477h2.241c.493 0 .9.055 1.218.163.369.158.553.478.553.966 0 .437-.14.744-.419.916-.28.172-.643.259-1.09.259h-2.503v-2.304zm3.544 6.631c-.248.123-.597.184-1.043.184H11v-2.784h2.535c.442.004.785.064 1.031.176.438.203.655.576.655 1.12 0 .643-.225 1.075-.677 1.304z"
                            fillRule="nonzero"
                          />
                          <path d="M19.597 12.152h4.414v1.299h-4.414z" />
                          <path
                            d="M25.544 17.28a3.784 3.784 0 00-.608-1.597 3.09 3.09 0 00-1.315-1.142c-.53-.241-1.127-.362-1.791-.361-1.116 0-2.022.358-2.723 1.07-.7.714-1.05 1.74-1.05 3.078 0 1.427.387 2.458 1.164 3.09a4.11 4.11 0 002.683.95c1.228 0 2.183-.376 2.865-1.125.437-.473.684-.939.738-1.396h-2.033a1.678 1.678 0 01-.41.531c-.284.235-.653.353-1.105.353-.43 0-.795-.097-1.1-.291-.501-.31-.764-.854-.797-1.628h5.549c.008-.667-.012-1.18-.067-1.533zm-5.432.2c.073-.503.25-.9.531-1.195.282-.293.68-.441 1.189-.442.47 0 .862.138 1.182.415.316.28.494.685.53 1.221h-3.432z"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </svg>
                      </a>
                    </div>
                  </div>
                </SectionFull>
              <SectionFull className={styles.fixSec8Right}>
                <div className={`${styles.sec8_right}`}>
                    <div className={styles.sec8_info}>
                      <p>33 Giang Văn Minh,<br/>Ba Đình, Hà Nội</p>
                      <p>info@beau.vn</p>
                      <p>097 531 9889 — o86 929 1771</p>
                    </div>
                    <div className={styles.sec8_social}>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='/#'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://dribbble.com/beauagency'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/></svg>
                    </a>

                    </div>
                    <div className={styles.sec8_ft}>
                       <p>Member of <img src="/img/contact/Mobile_Beau_Logo.png" alt="" /></p>
                    </div>
                  </div>
              </SectionFull>
            </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
