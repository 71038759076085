import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/21",
  "guid": "f43f55fd-f9fd-4e90-8b1e-1fe69e24c3f2",
  "title": "Chiến lược thương hiệu bao gồm những gì?",
  "subtitle": "Chiến lược thương hiệu bao gồm những gì?",
  "publicationDate": "2021-04-28T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Chiến lược có vai trò như một tấm bản đồ giúp bạn định rõ đường đi nước bước của thương hiệu. Vậy nhưng Chiến lược thật sự là gì?",
  "url": "https://beautique.vn/mp3/Beauthik_talk_21.mp3",
  "size": 58608650,
  "duration": 1832,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Chiến lược có vai trò như một tấm bản đồ giúp bạn định rõ đường đi nước bước của thương hiệu. Vậy nhưng Chiến lược thật sự là gì? Chiến lược thương hiệu gồm những yếu tố nào cấu thành? Mời bạn tìm câu trả lời tại số podcast này của Beautique!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      