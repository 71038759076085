import React from 'react'

import Layout from '../components/layout/layout'

export default function Page404() {
  return (
    <Layout>
      <h1 style={{marginTop: "100px"}}>Page not found</h1>
    </Layout>
  )
}
