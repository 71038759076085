// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-data-postcast-week-02-mdx": () => import("../src/data/postcast/week-02.mdx" /* webpackChunkName: "component---src-data-postcast-week-02-mdx" */),
  "component---src-data-postcast-week-03-mdx": () => import("../src/data/postcast/week-03.mdx" /* webpackChunkName: "component---src-data-postcast-week-03-mdx" */),
  "component---src-data-postcast-week-01-mdx": () => import("../src/data/postcast/week-01.mdx" /* webpackChunkName: "component---src-data-postcast-week-01-mdx" */),
  "component---src-data-postcast-week-04-mdx": () => import("../src/data/postcast/week-04.mdx" /* webpackChunkName: "component---src-data-postcast-week-04-mdx" */),
  "component---src-data-postcast-week-05-mdx": () => import("../src/data/postcast/week-05.mdx" /* webpackChunkName: "component---src-data-postcast-week-05-mdx" */),
  "component---src-data-postcast-week-06-mdx": () => import("../src/data/postcast/week-06.mdx" /* webpackChunkName: "component---src-data-postcast-week-06-mdx" */),
  "component---src-data-postcast-week-07-mdx": () => import("../src/data/postcast/week-07.mdx" /* webpackChunkName: "component---src-data-postcast-week-07-mdx" */),
  "component---src-data-postcast-week-08-mdx": () => import("../src/data/postcast/week-08.mdx" /* webpackChunkName: "component---src-data-postcast-week-08-mdx" */),
  "component---src-data-postcast-week-09-mdx": () => import("../src/data/postcast/week-09.mdx" /* webpackChunkName: "component---src-data-postcast-week-09-mdx" */),
  "component---src-data-postcast-week-11-mdx": () => import("../src/data/postcast/week-11.mdx" /* webpackChunkName: "component---src-data-postcast-week-11-mdx" */),
  "component---src-data-postcast-week-10-mdx": () => import("../src/data/postcast/week-10.mdx" /* webpackChunkName: "component---src-data-postcast-week-10-mdx" */),
  "component---src-data-postcast-week-14-mdx": () => import("../src/data/postcast/week-14.mdx" /* webpackChunkName: "component---src-data-postcast-week-14-mdx" */),
  "component---src-data-postcast-week-12-mdx": () => import("../src/data/postcast/week-12.mdx" /* webpackChunkName: "component---src-data-postcast-week-12-mdx" */),
  "component---src-data-postcast-week-13-mdx": () => import("../src/data/postcast/week-13.mdx" /* webpackChunkName: "component---src-data-postcast-week-13-mdx" */),
  "component---src-data-postcast-week-15-mdx": () => import("../src/data/postcast/week-15.mdx" /* webpackChunkName: "component---src-data-postcast-week-15-mdx" */),
  "component---src-data-postcast-week-16-mdx": () => import("../src/data/postcast/week-16.mdx" /* webpackChunkName: "component---src-data-postcast-week-16-mdx" */),
  "component---src-data-postcast-week-17-mdx": () => import("../src/data/postcast/week-17.mdx" /* webpackChunkName: "component---src-data-postcast-week-17-mdx" */),
  "component---src-data-postcast-week-18-mdx": () => import("../src/data/postcast/week-18.mdx" /* webpackChunkName: "component---src-data-postcast-week-18-mdx" */),
  "component---src-data-postcast-week-19-mdx": () => import("../src/data/postcast/week-19.mdx" /* webpackChunkName: "component---src-data-postcast-week-19-mdx" */),
  "component---src-data-postcast-week-20-mdx": () => import("../src/data/postcast/week-20.mdx" /* webpackChunkName: "component---src-data-postcast-week-20-mdx" */),
  "component---src-data-postcast-week-21-mdx": () => import("../src/data/postcast/week-21.mdx" /* webpackChunkName: "component---src-data-postcast-week-21-mdx" */),
  "component---src-data-postcast-week-22-mdx": () => import("../src/data/postcast/week-22.mdx" /* webpackChunkName: "component---src-data-postcast-week-22-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-detail-doan-js": () => import("../src/pages/work-detail/doan.js" /* webpackChunkName: "component---src-pages-work-detail-doan-js" */),
  "component---src-pages-work-detail-han-js": () => import("../src/pages/work-detail/han.js" /* webpackChunkName: "component---src-pages-work-detail-han-js" */),
  "component---src-pages-work-detail-joa-js": () => import("../src/pages/work-detail/joa.js" /* webpackChunkName: "component---src-pages-work-detail-joa-js" */),
  "component---src-pages-work-detail-mic-js": () => import("../src/pages/work-detail/mic.js" /* webpackChunkName: "component---src-pages-work-detail-mic-js" */),
  "component---src-pages-work-detail-olympia-school-js": () => import("../src/pages/work-detail/olympia-school.js" /* webpackChunkName: "component---src-pages-work-detail-olympia-school-js" */),
  "component---src-pages-work-detail-wonder-js": () => import("../src/pages/work-detail/wonder.js" /* webpackChunkName: "component---src-pages-work-detail-wonder-js" */)
}

