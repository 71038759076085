import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/18",
  "guid": "d78afdc5-9293-48b5-bed4-73c4f15aae3b",
  "title": "Logo Xiaomi - Làm gì mà hết 7 tỉ?!??",
  "subtitle": "Logo Xiaomi - Làm gì mà hết 7 tỉ?!??",
  "publicationDate": "2021-04-02T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Đằng sau 1 chiếc logo là những gì mà đáng giá 9 con số 0?",
  "url": "https://beautique.vn/mp3/Beauthik_talk_18.mp3",
  "size": 61497750,
  "duration": 1922,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Đằng sau 1 chiếc logo là những gì mà đáng giá 9 con số 0? Liệu có đáng không? Cùng chúng tôi đi tìm câu trả lời tại podcast kì này!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      