import React from "react"
import { Link } from "gatsby"

import styles from "./sideDrawer.module.scss"

export default function sideDrawer(props) {
  return (
    <div
      className={`${
        props.showDraw
          ? `${styles.sideDraw} ${styles.sideDrawShow}`
          : styles.sideDraw
      }`}
    >
      <nav>
        <ul>
          <li onClick={props.clickCloseDraw} role="presentation">
            <Link to="/" activeStyle={{ color: "#000" }}>
              Home
            </Link>
          </li>
          <li onClick={props.clickCloseDraw} role="presentation">
            <Link to="/about" activeStyle={{ color: "#000" }}>
              About
            </Link>
          </li>
          <li onClick={props.clickCloseDraw} role="presentation">
            <Link to="/work" activeStyle={{ color: "#000" }}>
              Work
            </Link>
          </li>
          <li>
            <a href="https://beautique.vn/insight/">
              Insight
            </a>
          </li>
          <li onClick={props.clickCloseDraw} role="presentation">
            <Link to="/contact" activeStyle={{ color: "#000" }}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div
        className={`${styles.btnMenu}`}
        onClick={props.clickCloseDraw}
        role="presentation"
      >
        <div className={styles.btnMenu_line}></div>
        <div className={styles.btnMenu_line}></div>
        <div className={styles.btnMenu_line}></div>
      </div>
    </div>
  )
}
