import React, { Component } from "react"
import { Link } from "gatsby"

import SEO from "../../components/layout/SEO"
import Layout from "../../components/layout/layout"
import SectionFull from "../../components/layout/sectionFull"
import ContactWork from "../../components/modules/contactWork"

import ScrollPageComponent from "../../components/modules/scrollpage"
import { scrollToElement } from "../../helpers/utils"

import styles from "./wonder.module.scss"

export default class Insight extends Component {
  scrollHandle = callbackScroll => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {
    return (
      <>
        <SEO
          title="Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé"
          url="https://beautique.vn/work-detail/wonder"
          description="Beautique được giao nhiệm vụ xây dựng chiến lược về hình ảnh và nhận diện hợp lý để thể hiện được tính cách thương hiệu Wonder một cách rõ nét nhất. Vì Wonder là một mô hình tương đối mới tại Việt Nam, đây là một đề bài tương đối thử thách cho đội ngũ Beautique. Giải pháp chúng tôi lựa chọn là sự pha trộn hài hoà của sự tinh tế và ngây thơ đưược truyền tải qua màu sắc cũng như hệ thống hoa văn thương hiệu."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-wd.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={styles.sec1}>
              <div className={styles.inner}>
                <div>
                  <h2 className={styles.sec1_title}>
                    Wonder không gian nghệ thuật sáng tạo dành cho gia đình và
                    các bé
                  </h2>
                </div>
                <div className={styles.sec1_content}>
                  <div className={styles.sec1_left}>
                    <ul className={styles.sec1_list}>
                      <li>
                        <span>Client</span>Wonder
                      </li>
                      <li>
                        <span>Duration</span>04/2018 - 05/2018
                      </li>
                      <li>
                        <span>Scope</span>Brand Strategy
                        <br />
                        Campaign Identity
                      </li>
                    </ul>
                  </div>
                  <div className={styles.sec2Mb}>
                    <img src="/img/work/WONDER/wd1.png" alt="" />
                  </div>
                  <div className={styles.sec1_right}>
                    <p>
                      Beautique được giao nhiệm vụ xây dựng chiến lược về hình
                      ảnh và nhận diện hợp lý để thể hiện được tính cách thương
                      hiệu Wonder một cách rõ nét nhất. Vì Wonder là một mô hình
                      tương đối mới tại Việt Nam, đây là một đề bài tương đối
                      thử thách cho đội ngũ Beautique. Giải pháp chúng tôi lựa
                      chọn là sự pha trộn hài hoà của sự tinh tế và ngây thơ
                      được truyền tải qua màu sắc cũng như hệ thống hoa văn
                      thương hiệu.
                    </p>
                  </div>
                  <button
                    onClick={this.pageTranslateX}
                    className={styles.sec1_btn}
                  >
                    <img src="/img/icon/arrow-black.svg" alt="" />
                  </button>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec2}>
              <div className={styles.inner}>
                <div>
                  <img src="/img/work/WONDER/wd1.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec3}>
              <div className={styles.inner}>
                <div className={styles.sec3_left}>
                  <img src="/img/work/WONDER/wd2.png" alt="" />
                </div>
                <div className={styles.sec3_right}>
                  <p>
                    Wonder là sự kỳ thú và cảm giác kỳ diệu đến từ những khoảnh
                    khắc đáng trân quý tong cuộc sống khi ta có thể nhìn thấy
                    hoặc cảm nhận thấy cái đẹp và lòng tốt trong từng cái chạm
                    nhẹ hay mỗi nốt nhạc.
                  </p>
                  <h3>
                    Cảm giác ấy gần giống như một khoảnh khắc "wow" nhưng sâu
                    lắng, ý nghĩa và mag tính cá nhân hơn.
                  </h3>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec4}>
              <div className={styles.inner}>
                <div>
                  <img src="/img/work/WONDER/wd3.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec5}>
              <div className={styles.inner}>
                <div className={styles.sec5_left}>
                  <img src="/img/work/WONDER/wd4.png" alt="" />
                </div>
                <div className={styles.sec5_right}>
                  <h3>Color Pallete</h3>
                  <div>
                    <img src="/img/work/WONDER/wd5.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec6}>
              <div className={styles.inner}>
                <div className={styles.sec6_left}>
                  <img src="/img/work/WONDER/wd6.png" alt="" />
                </div>
                <div className={styles.sec6_right}>
                  <img src="/img/work/WONDER/wd7.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec7}>
              <div className={styles.inner}>
                <div className={`${styles.sec7_box} ${styles.box1}`}>
                  <h3>Key visual</h3>
                  <p>
                    Sự kết hợp giữa những đường thẳng, cong và chấm tròn tạo nên
                    bộ icon mang lại cho Wonder ý nghĩa hoàn thiện
                  </p>
                </div>
                <div className={`${styles.sec7_box} ${styles.box2}`}>
                  <div className={styles.box_left}>
                    <img src="/img/work/WONDER/wd8.png" alt="" />
                  </div>
                  <div className={styles.box_right}>
                    <h3>o1.</h3>
                    <p>Quyển sách biểu tượng cho kiến thức</p>
                  </div>
                </div>
                <div className={`${styles.sec7_box} ${styles.box3}`}>
                  <div className={styles.box_left}>
                    <img src="/img/work/WONDER/wd9.png" alt="" />
                  </div>
                  <div className={styles.box_right}>
                    <h3>o2.</h3>
                    <p>Kính lúp biểu tượng cho sự khám phá, tìm tòi</p>
                  </div>
                </div>
                <div className={`${styles.sec7_box} ${styles.box4}`}>
                  <div className={styles.box_left}>
                    <img src="/img/work/WONDER/wd10.png" alt="" />
                  </div>
                  <div className={styles.box_right}>
                    <h3>o3.</h3>
                    <p>
                      Khuông nhạc biểu tượng cho niềm đam mê, cảm thụ nghệ thuật
                    </p>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec8}>
              <div className={styles.inner}>
                <div>
                  <img src="/img/work/WONDER/wd12.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec9}>
              <div className={styles.inner}>
                <div className={`${styles.sec9_box} ${styles.box1}`}>
                  <h3>Poster và concept hình ảnh</h3>
                  <p>
                    Các yếu tố đồ hoạ được sử dụng tập trung vào sự tương tác
                    giữa trẻ em và thế giới xung quanh, ví dụ với các hoạt động
                    như chơi nhạc, vẽ tranh, đọc sách... Các hoạt động này mang
                    tinh thần kết nối giữa học và chơi của Wonder
                  </p>
                </div>
                <div className={`${styles.sec9_box} ${styles.box2}`}>
                  <div>
                    <img src="/img/work/WONDER/wd13.png" alt="" />
                  </div>
                </div>
                <div className={`${styles.sec9_box} ${styles.box3}`}>
                  <div>
                    <img src="/img/work/WONDER/wd14.png" alt="" />
                  </div>
                </div>
                <div className={`${styles.sec9_box} ${styles.box4}`}>
                  <div>
                    <img src="/img/work/WONDER/wd15.png" alt="" />
                  </div>
                </div>
                <div className={`${styles.sec9_box} ${styles.box5}`}>
                  <div>
                    <img src="/img/work/WONDER/wd11.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec10}>
              <div className={styles.inner}>
                <h3 className={styles.sec10Title}>Dự án điển hình.</h3>
                <div className={styles.listWork}>
                  <div className={styles.listItem}>
                    <Link to="/work-detail/olympia-school">
                      <span className="f-subtitle">Design/Identity</span>
                      <div>
                        <img
                          className="f-img"
                          src={`/img/work/thumb/thumb-olympia.jpg`}
                          alt=""
                        />
                      </div>
                      <h3 className="f-title">
                        The Olympia School - Trường học của tương lai
                      </h3>
                    </Link>
                  </div>
                  <div className={styles.listItem}>
                    <Link to="/work-detail/joa">
                      <span className="f-subtitle">Design/Identity</span>
                      <div>
                        <img
                          className="f-img"
                          src={`/img/work/thumb/thumb-joa.png`}
                          alt=""
                        />
                      </div>
                      <h3 className="f-title">
                        Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện
                        Journey on Air
                      </h3>
                    </Link>
                  </div>
                  <div className={styles.listItem}>
                    <Link to="/work-detail/doan">
                      <span className="f-subtitle">Design/Identity</span>
                      <div>
                        <img
                          className="f-img"
                          src={`/img/work/thumb/thumb-doan.jpg`}
                          alt=""
                        />
                      </div>
                      <h3 className="f-title">
                        DOAN - thời trang đại diện cho khái niệm Effortless
                        beauty
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            </SectionFull>
            <ContactWork />
          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
