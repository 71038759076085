import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/09",
  "guid": "6215926f-f212-4501-869f-8cb3ed465171",
  "title": "Thiết kế để làm gì? Liệu bạn đã hiểu rõ?",
  "subtitle": "The ninth episode.",
  "publicationDate": "2020-12-30T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Hiểu thế nào cho trọn vẹn và bao quát nhất để Design không chỉ dừng lại như công cụ giúp tô điểm cho vẻ bề ngoài?",
  "url": "https://beautique.vn/mp3/Beauthik_talk_09.mp3",
  "size": 53236692,
  "duration": 2662,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hiểu thế nào cho trọn vẹn và bao quát nhất để "Design" không chỉ dừng lại như công cụ giúp tô điểm cho vẻ bề ngoài?
Cơ hội được trò chuyện cùng với 2 vị khách mời đến từ @Hai&Ikigai đã giúp cho chúng tôi có được cho mình một góc nhìn tổng quan hơn về vai trò của Design trong hoạt động kinh doanh cũng như thực tế đời sống, xã hội.
Mời bạn cùng lắng nghe Podcast #9!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      