import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/20",
  "guid": "d07460bb-cb96-4da6-9f52-d9d094e0f1a5",
  "title": "Hiểu sao cho thấu chân dung khách hàng?",
  "subtitle": "Hiểu sao cho thấu chân dung khách hàng?",
  "publicationDate": "2021-04-20T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Khi không biết bắt đầu từ đâu, hãy bắt đầu từ Research. Bởi lẽ, khi đứng trước ngã ba đường, với sự ngập ngừng không định hướng, hãy mạnh dạn tìm kiếm sự giúp đỡ từ những người qua đường.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_20.mp3",
  "size": 88318785,
  "duration": 2760,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Khi không biết bắt đầu từ đâu, hãy bắt đầu từ Research. Bởi lẽ, khi đứng trước ngã ba đường, với sự ngập ngừng không định hướng, hãy mạnh dạn tìm kiếm sự giúp đỡ từ những người qua đường. Research cũng giống như việc tìm kiếm sự chỉ dẫn khi không biết phải đi đâu, làm gì. Cùng lắng nghe podcast của chúng tôi để biết biết phải làm gì khi thương hiệu đang đứng trước ngã ba đường!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      