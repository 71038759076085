import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/12",
  "guid": "1c3f1cd9-cc38-4a52-901a-c16ab3d2421c",
  "title": "Social media - Để khi nói sẽ có người nghe",
  "subtitle": "The twelfth episode.",
  "publicationDate": "2021-01-29T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Trong số podcast tuần này, mời bạn cùng Cổ Động và Beautique trò chuyện về miền đất Social Media đầy nhiễu loạn.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_12.mp3",
  "size": 41899896,
  "duration": 2095,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`"The information we consume matters just as much as the food we put in our body. It affects our thinking, our behavior, how we understand our place in the world. And how we understand others." — Evan Williams, Co-Founder of Twitter and Medium
Trong số podcast tuần này, mời bạn cùng Cổ Động và Beautique trò chuyện về miền đất Social Media đầy nhiễu loạn. Khi tất cả đều ồn ào, cần làm sao để mọi người dừng lại và lắng nghe bạn? Mời bạn cùng chúng tôi lắng nghe!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      