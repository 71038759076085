import React from "react"
import { Link } from 'gatsby'
import styles from "./keyProject.module.scss"


const KeyProject = props => {
  // const active = props.active === 'true' ? ' active' : '';

  return (
    <div className={`project ${styles.project} ${props.pjActive ? 'pj-active': ''}`}>
      <div className={styles.subtitle}><span>{props.subtitle}</span></div>
      <div className={styles.img}>
        <img src={'/img/'+props.img} alt="" />
      </div>
      <div className={styles.title}>
        <h3><Link to={props.link}>{props.title}</Link></h3>
      </div>
    </div>
  )
}

export default KeyProject;
