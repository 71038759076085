import React from "react"

import styles from "./drawerButton.module.scss"

export default function DrawerButton(props) {
  return (
    <div
      className={`${styles.btnMenu} ${
        props.customColor ? styles.white : styles.black
      }`}
      onClick={props.clickOpenDraw}
      role="presentation"
    >
      <div className={styles.btnMenu_line}></div>
      <div className={styles.btnMenu_line}></div>
      <div className={styles.btnMenu_line}></div>
    </div>
  )
}
