import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/14",
  "guid": "d7c072bc-c812-4045-884a-7b4d95b44af4",
  "title": "Chiến lược - Kim chỉ nam của thiết kế",
  "subtitle": "Chiến lược - Kim chỉ nam của thiết kế",
  "publicationDate": "2021-02-26T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Trong số podcast #14, mời bạn lắng nghe những chia sẻ của Hưng Nguyễn - Managing Director & Linh Vũ - Head of Strategy về tầm quan trọng của Chiến lược thương hiệu thông qua dự án Inochi đầy tâm huyết của B&A Team.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_14.mp3",
  "size": 128817120,
  "duration": 4026,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Trong số podcast #14, mời bạn lắng nghe những chia sẻ của Hưng Nguyễn - Managing Director & Linh Vũ - Head of Strategy về tầm quan trọng của Chiến lược thương hiệu thông qua dự án Inochi đầy tâm huyết của B&A Team.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      