import React, { Component } from "react"
import { Link } from 'gatsby';

import SEO from '../../components/layout/SEO'
import Layout from '../../components/layout/layout'
import SectionFull from "../../components/layout/sectionFull"

import ScrollPageComponent from '../../components/modules/scrollpage';
import { scrollToElement } from '../../helpers/utils'
import ContactWork from '../../components/modules/contactWork';

import styles from "./joa.module.scss"

export default class Insight extends Component {

  scrollHandle = (callbackScroll) => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {

    return (
      <>
        <SEO
          title="Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air"
          url="https://beautique.vn/work-detail/joa"
          description="Journey On Air là một trang thông tin điện tử được hình thành với một mục tiêu giới thiệu và quảng bá du lịch Việt Nam đến với du khách và bạn bè Quốc tế. Đội ngũ sáng lập Journey On Air tiếp cận Beautique với một mong muốn có thể xây dựng một chiến lược định vị và bộ nhận diện vừa giúp truyền tải được bản sắc văn hoá Việt Nam mà cũng tạo được sự khác biệt với các kênh thông tin về du lịch khác trên thị trường."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-joa.png"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={styles.sec1}>
              <div className={styles.inner}>
                <div>
                  <h2 className={styles.sec1_title}>
                    Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey
                    on Air
                  </h2>
                </div>
                <div className={styles.sec1_content}>
                  <div className={styles.sec1_left}>
                    <ul className={styles.sec1_list}>
                      <li>
                        <span>Client</span>Journey on Air
                      </li>
                      <li>
                        <span>Duration</span>10/2019 - 12/2019
                      </li>
                      <li>
                        <span>Scope</span>Brand Strategy <br />
                        Campaign Identity
                      </li>
                    </ul>
                  </div>
                  <div className={styles.sec1_right}>
                    <p>
                      Journey On Air là một trang thông tin điện tử được hình
                      thành với một mục tiêu giới thiệu và quảng bá du lịch Việt
                      Nam đến với du khách và bạn bè Quốc tế. Đội ngũ sáng lập
                      Journey On Air tiếp cận Beautique với một mong muốn có thể
                      xây dựng một chiến lược định vị và bộ nhận diện vừa giúp
                      truyền tải được bản sắc văn hoá Việt Nam mà cũng tạo được
                      sự khác biệt với các kênh thông tin về du lịch khác trên
                      thị trường.
                    </p>
                  </div>
                  <button
                    onClick={this.pageTranslateX}
                    className={styles.sec1_btn}
                  >
                    <img src="/img/icon/icon-arrow-next.svg" alt="" />
                  </button>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec2}>
              <div className={styles.inner}>
                <img src="/img/work/JOA/MAIN.gif" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec3}>
              <div className={styles.inner}>
                <div className={styles.sec3_left}>
                  <h3>
                    Khách hàng tiềm năng của Journey On Air bao gồm hai nhóm
                    chính (giới trẻ từ 18-35 và người trung niên đã ngoài 55
                    tuổi). Tuy có rất nhiều điểm khác biệt nhưng 2 nhóm khách
                    hàng này cùng chia sẻ chung mối quan tâm về văn hoá và lịch
                    sử của Quốc gia bản địa. Những rào cản họ gặp phải khi tiếp
                    cận đến những luồng thông tin là sự sơ sài, thiếu chuyên
                    nghiệp của những trang tin được mở ra nhằm mục đính thương
                    mại tour du lịch. Qua quá trình nghiên cứu, chúng tôi tìm ra
                    được nhu cầu tiềm ẩn của khách hàng cho một trang tin tức về
                    du lịch đó là:
                  </h3>
                </div>
                <div className={styles.sec3_right}>
                  <div className={styles.sec3_circle}>Giàu Văn Hoá</div>
                  <div className={styles.sec3_circle}>Nét Á Đông</div>
                  <div className={styles.sec3_circle}>Đáng Tin Cậy</div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec4}>
              <div className={styles.inner}>
                <div className={styles.sec4_left}>
                  <h4>
                    Sau quá trình làm việc với đội ngũ Journey On Air, chúng tôi
                    đã cùng xây dựng được chiến lược định vị thương hiệu cho
                    Journey On Air như sau:
                  </h4>
                  <div className={styles.sec4_blockquote}>
                    <h3>
                      <div>
                        <img src="/img/work/JOA/quote1.png" alt="" />
                      </div>
                      Cổng thông tin số 1 về du lịch tại Việt Nam với lượng
                      thông tin hữu ích, trải nghiệm du lịch tuyệt vời và mang
                      đậm chất Việt Nam
                      <span>
                        <img src="/img/work/JOA/quote2.png" alt="" />
                      </span>
                    </h3>
                  </div>
                </div>
                <div className={styles.sec4_right}>
                  <img src="/img/work/JOA/joa1.jpg" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec5}>
              <div className={styles.inner}>
                <div className={styles.sec5_left}>
                  <h3>
                    Lấy cảm hứng từ những hoa văn, hoạ tiết trong lịch sử dân
                    tộc, hoà trộn với những ảnh hưởng về văn hoá phương Tây từ
                    cuối thế kỉ 19. Bộ nhận diện được xây dựng cho Journey On
                    Air là sự cân bằng của những yếu tố truyền thống lẫn hiện
                    đại. Thông điệp Beautique muốn truyền tải qua bộ nhận diện
                    này đó là tính văn hoá của thương hiệu nhưng vẫn cần giữ
                    được tính khách quan của kênh thông tin nhằm tạo sự tin
                    tưởng với độc giả.{" "}
                  </h3>
                </div>
                <div className={styles.sec5_right}>
                  <div>
                    <img src="/img/work/JOA/joa2.png" alt="" />
                  </div>
                  <p>Logo chính cùng với các phiên bản cho logo rút gọn</p>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec6}>
              <div className={styles.inner}>
                <div className={styles.sec6_left}>
                  <h3>
                    Logotype được thiết kế trên nền của bộ font Minion Variable
                    Concept, đây là một bộ font có chân với kiểu dáng cổ điển và
                    chuyên nghiệp. Để tạo được cảm giác Á Đông, chúng tôi đã sử
                    dụng những nét cắt tại các góc 30, 45 và 60 độ lấy cảm hứng
                    từ độ nghiêng, nét thanh đậm của ngòi bút lông trong nghệ
                    thuật thư pháp truyền thống Việt Nam.
                  </h3>
                </div>
                <div className={styles.sec6_right}>
                  <div
                    className={`${styles.sec6_box} ${styles.sec6_box_width1}`}
                  >
                    <img src="/img/work/JOA/j-on-air.gif" alt="" />
                  </div>
                  <div
                    className={`${styles.sec6_box} ${styles.sec6_box_width2}`}
                  >
                    <div
                      className={`${styles.sec6_box_item} ${styles.sec6_box_width3}`}
                    >
                      <img src="/img/work/JOA/a.gif" alt="" />
                    </div>
                    <div
                      className={`${styles.sec6_box_item} ${styles.sec6_box_width4}`}
                    >
                      <div>
                        <img src="/img/work/JOA/i.gif" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/JOA/r.gif" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sec6_right_img}>
                    <img src="/img/work/JOA/joa6Mb.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec7}>
              <div className={styles.inner}>
                <div className={styles.sec7_left}>
                  <h3>
                    Hoa văn được thiết kế cho bộ nhận diện của Journey On Air
                    lấy cảm hứng từ chính tên thương hiệu. Đây là sự tiếp nối
                    của hoạ tiết "đám mây" trong logo và cũng là một hình ảnh
                    rất quen thuộc thường thấy trong nghệ thuật tranh thờ cúng
                    "Hàng Trống" và "Đông Hồ".
                  </h3>
                </div>
                <div className={styles.sec7_right}>
                  <img src="/img/work/JOA/joa3.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec8}>
              <div className={styles.inner}>
                <div className={styles.sec8_left}>
                  <h3>
                    Màu sắc được lựa chon cho Journey On Air có mối liên kết
                    chặt chẽ với chiến lược về nội dung tin tức của thương hiệu.
                    Có 3 bộ màu chính tương ứng với 3 chủ đề bài viết bao gồm về
                    Thiên Nhiên Việt Nam, Chủ đề về Văn Hoá & Nghệ Thuật và Chủ
                    đề Lịch Sử & Xã Hội.
                  </h3>
                </div>
                <div className={styles.sec8_right}>
                  <img
                    className={styles.sec8_right_img1}
                    src="/img/work/JOA/joa4.png"
                    alt=""
                  />
                  <img
                    className={styles.sec8_right_img2}
                    src="/img/work/JOA/joa8Mb.png"
                    alt=""
                  />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec9}>
              <div className={styles.inner}>
                <div className={styles.sec9_left}>
                  <h3>
                    Hệ thống lưới và vùng an toàn tiêu chuẩn của Logo để đảm bảo
                    tính nhất quán cho hình ảnh thương hiệu.
                  </h3>
                </div>
                <div className={styles.sec9_right}>
                  <div className={styles.sec9_right_img1}>
                    <img src="/img/work/JOA/safespace.gif" alt="" />
                  </div>
                  <img
                    className={styles.sec9_right_img2}
                    src="/img/work/JOA/joa9Mb.png"
                    alt=""
                  />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec10}>
              <div className={styles.inner}>
                <img src="/img/work/JOA/joa5.png" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec11}>
              <div className={styles.inner}>
                <div className={styles.sec11_left}>
                  <h3>
                    Là một trang thông tin điện tử, bộ nhận diện Journey On Air
                    được xây dựng tập trung cho những điểm chạm digital bao gồm
                    Website, Web Banner và các kênh social thay vì những điểm
                    chạm vật lý truyền thống.
                  </h3>
                </div>
                <div className={styles.sec11_right}>
                  <div className={styles.sec11_right_img1}>
                    <img src="/img/work/JOA/layout2.gif" alt="" />
                  </div>
                  <img
                    className={styles.sec11_right_img2}
                    src="/img/work/JOA/joa11Mb.png"
                    alt=""
                  />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec12}>
              <div className={styles.inner}>
                <div className={styles.sec12_left}>
                  <h3>
                    Website Journey On Air được thiết kế với mục tiêu làm nổi
                    bật được tính cách thương hiệu và cũng phần nào thể hiện
                    được những đường nét và hoa văn truyền thống. Cân bằng lại
                    những yếu tố văn hoá có tính thể hiện cao đó là hệ thống
                    thông tin và cách trình bày rõ ràng, và tao nhã nhằm thể
                    hiện tính chuyên nghiệp của một trang tin tức khách quan.
                  </h3>
                </div>
                <div className={styles.sec12_right}>
                  <div className={styles.sec12_right_img1}>
                    <img src="/img/work/JOA/joa-web.gif" alt="" />
                  </div>
                  <img
                    className={styles.sec12_right_img2}
                    src="/img/work/JOA/joa12Mb.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.fl1}>
                <img src="/img/work/JOA/joa9.png" alt="" />
              </div>
              <div className={styles.fl2}>
                <img src="/img/work/JOA/joa10.png" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec13}>
              <div className={styles.inner}>
                <div className={styles.mb1}>
                  <img src="/img/work/JOA/joa6.png" alt="" />
                </div>
                <div className={styles.mb2}>
                  <img src="/img/work/JOA/joa7.png" alt="" />
                </div>
                <div className={styles.mb3}>
                  <img src="/img/work/JOA/joa8.png" alt="" />
                </div>
              </div>
              <div className={styles.fl3}>
                <img src="/img/work/JOA/joa11.png" alt="" />
              </div>
              <div className={styles.fl4}>
                <img src="/img/work/JOA/joa12.png" alt="" />
              </div>
              <div className={styles.fl5}>
                <img src="/img/work/JOA/joa13.png" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec14}>
              <div className={styles.inner}>
              <h3 className={styles.sec14Title}>Dự án điển hình.</h3>
                  <div className={styles.listWork}>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/olympia-school">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-olympia.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">The Olympia School - Trường học của tương lai</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/doan">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-doan.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">DOAN - thời trang đại diện cho khái niệm Effortless beauty</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/wonder">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-wd.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé</h3>
                      </Link>
                    </div>
                  </div>
              </div>
            </SectionFull>
            <ContactWork />

          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
