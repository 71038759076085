import React from "react"
import styles from "./keyTeam.module.scss"
const KeyTeam = props => {
  //const active = props.active === true ? ' active' : '';
  return (
    <div className={`person ${styles.keyteamWrap}`}>
      <div className={styles.img}>
        <img src={'/img/'+props.img} alt="" />
      </div>
      <div className={styles.disc}>
        <h3 className={styles.title}><a href={props.link}>{props.title}</a></h3>
        <p className={styles.position}>{props.position}</p>
      </div>
    </div>
  )
}
export default KeyTeam;
