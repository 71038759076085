import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/10",
  "guid": "43a4f354-5236-11eb-ae93-0242ac130002",
  "title": "Độc Bước Trên Hành Trình Design? Bạn Không Cô Đơn!",
  "subtitle": "The tenth episode.",
  "publicationDate": "2021-01-09T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Có lẽ không phải mọi thiết kế đều là đứa con tinh thần của người làm Design.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_10.mp3",
  "size": 50234148,
  "duration": 2513,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Có lẽ không phải mọi thiết kế đều là "đứa con tinh thần" của người làm Design. `}</p>
    <p>{`Giữa điều cần làm và điều muốn làm luôn tồn tại một khoảng cách lớn. Tại đó, đôi khi chúng ta hào hứng hơn với những điều mà bản thân yêu thích. `}</p>
    <p>{`Điều gì khiến bạn cảm thấy được trọn vẹn nhất? Sau những nỗ lực hoàn thành một dự án "cần làm" hay những lúc hào hứng thực hiện những điều mình thích? 
Mời bạn cùng lắng nghe cuộc trò chuyện giữa Beautique & Nhat Anh Nguyen!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      