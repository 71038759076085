import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/01",
  "guid": "a338d7eb-b9dd-4883-a4fc-0493f80eb1b8",
  "title": "Câu chuyện có trước hay logo có trước?",
  "subtitle": "The first episode.",
  "publicationDate": "2020-10-29T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Câu chuyện có trước hay logo có trước? Khách hàng mong muốn agency - trong quá trình thiết kế nhận diện thương hiệu - đề xuất cho họ một logo truyền tải toàn bộ ý nghĩa và câu chuyện của thương hiệu, điều này là đúng hay sai?",
  "url": "https://beautique.vn/mp3/Beauthik_talk_01.mp3",
  "size": 69921230,
  "duration": 2187,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Câu chuyện có trước hay logo có trước? Khách hàng mong muốn agency - trong quá trình thiết kế nhận diện thương hiệu - đề xuất cho họ một logo truyền tải toàn bộ ý nghĩa và câu chuyện của thương hiệu, điều này là đúng hay sai?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      