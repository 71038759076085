import React from 'react'

import styles from './star.module.scss'

export default function Star() {
  return (
    <div className={styles.stars}>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>

        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>
        <div className={styles.container}>
          <div className={styles.star} />
        </div>


      </div>
  )
}
