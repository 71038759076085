import React from "react"
import styles from "./section.module.scss"

const Section = props => (
  <section
    ref={props.forwardRef ? props.forwardRef : null}
    className={`${styles.section} ${props.className}`}
    style={{ backgroundColor: props.bg && props.bg }}
  >
    {props.children}
  </section>
)

export default Section
