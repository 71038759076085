import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import anime from "animejs"

import DrawerButton from "./drawerButton"
import SideDrawer from "./sideDrawer"

import {
  animatePageExitHandler,
  animatePageEntryHandler,
} from "../../helpers/page-transition"

import styles from "./header.module.scss"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sideDrawOpen: false,
      dataHeader: {
        logo: {
          link: "/",
          logoDark: "logo-dark.svg",
          logoLight: "logo-white.svg",
        },
        menu: [
          { text: "About", link: "/about" },
          { text: "Work", link: "/work" },
          { text: "Insight", link: "https://beautique.vn/insight/" },
          { text: "Contact", link: "/contact" },
        ],
      },
      customColor: null,
    }

    this.RefbgLogo = React.createRef()
  }

  componentDidMount() {
    const links = document.querySelectorAll(".activeLink")
    links.forEach(link => {
      if (window.location.href.includes(link.innerText.toLowerCase())) {
        link.classList.add(styles.active_link)
      }
      link.addEventListener("click", function() {
        for (const e of links) e.classList.remove(styles.active_link)
        link.classList.add(styles.active_link)
      })
    })

    const animate = () => {
      if (window.innerWidth >= 1025) {
        this.setState({ customColor: null })
        window.cancelAnimationFrame(this.req)
        return
      }
      let path = this.props.path
      // let reg = /insight\/(([0-9])|([0-9a-z]\w+))-(([0-9a-z]\w+))/g

      try {
        if (path.includes("wonder")) {
          if (!this.state.customColor) {
            this.setState({ customColor: "bgWonder" })
          }
        } else if (path.includes("doan")) {
          if (!this.state.customColor) {
            this.setState({ customColor: "bgDoan" })
          }
        } else if (path.includes("olympia-school")) {
          if (!this.state.customColor) {
            this.setState({ customColor: "bgDoan" })
          }
        } else {
          this.setState({ customColor: null })
        }
        // if (reg.test(path)) {
        //   if (!this.state.customColor) {
        //     this.setState({ customColor: "bgDoan" })
        //   }
        // }
      } catch (error) {
        this.setState({ customColor: null })
      }
      this.req = window.requestAnimationFrame(animate)
    }
    if (!this.run && !this.req) {
      animate()
      this.run = true
    }
  }

  handleSideDrawOpen = () => {
    this.setState({ sideDrawOpen: true })
  }

  handleSideDrawClose = () => {
    this.setState({ sideDrawOpen: false })
  }

  logoTextHover = () => {
    anime({
      targets: this.RefbgLogo.current,
      backgroundPositionY: [0, -415],
      easing: "steps(13)",
      duration: 350,
      loop: false,
      delay: 200,
    })
  }

  logoTextLeave = () => {
    anime({
      targets: this.RefbgLogo.current,
      backgroundPositionY: [-415, 0],
      easing: "steps(13)",
      duration: 200,
      loop: false,
    })
  }

  render() {
    const { sideDrawOpen, dataHeader, customColor } = this.state

    return (
      <>
        <header className={`${styles.header} ${customColor || ""}`}>
          <div className={`${styles.header__logo} mouse-hover activeLink`}>
            <TransitionLink
              to="/"
              exit={{
                trigger: ({ exit, node }) => animatePageExitHandler(node),
                zIndex: 1,
                length: 1,
              }}
              entry={{
                trigger: ({ exit, node }) => animatePageEntryHandler(node),
                zIndex: 1,
                length: 1,
                delay: 0.3,
              }}
              className={styles.logoImg}
            >
              {customColor ? (
                <img src={"/img/" + dataHeader.logo.logoDark} alt="" />
              ) : (
                <img src={"/img/" + dataHeader.logo.logoLight} alt="" />
              )}
            </TransitionLink>
            <h3 className={styles.textLogo}>
              <a
                href="/"
                onMouseEnter={() => this.logoTextHover()}
                onMouseLeave={() => this.logoTextLeave()}
                onFocus={() => void 0}
              >
                <span ref={this.RefbgLogo} className={styles.bgLogo}></span>
              </a>
            </h3>
          </div>
          <div className={styles.header__nav}>
            <nav>
              <ul>
                <li className="mouse-hover activeLink">
                  <TransitionLink
                    to="/about"
                    exit={{
                      trigger: ({ exit, node }) => animatePageExitHandler(node),
                      zIndex: 1,
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ exit, node }) =>
                        animatePageEntryHandler(node),
                      zIndex: 1,
                      length: 1,
                      delay: 0.3,
                    }}
                  >
                    About
                  </TransitionLink>
                </li>
                <li className="mouse-hover activeLink">
                  <TransitionLink
                    to="/work"
                    exit={{
                      trigger: ({ exit, node }) => animatePageExitHandler(node),
                      zIndex: 1,
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ exit, node }) =>
                        animatePageEntryHandler(node),
                      zIndex: 1,
                      length: 1,
                      delay: 0.3,
                    }}
                  >
                    Work
                  </TransitionLink>
                </li>
                <li className="mouse-hover activeLink">
                  <a href="/insight/">Insight</a>
                </li>
                <li className="mouse-hover activeLink">
                  <TransitionLink
                    to="/contact"
                    exit={{
                      trigger: ({ exit, node }) => animatePageExitHandler(node),
                      zIndex: 1,
                      length: 1,
                    }}
                    entry={{
                      trigger: ({ exit, node }) =>
                        animatePageEntryHandler(node),
                      zIndex: 1,
                      length: 1,
                      delay: 0.3,
                    }}
                  >
                    Contact
                  </TransitionLink>
                </li>
              </ul>
            </nav>
          </div>
          <DrawerButton
            customColor={customColor}
            clickOpenDraw={this.handleSideDrawOpen}
            showBtnClose={sideDrawOpen}
          />
          <SideDrawer
            clickCloseDraw={this.handleSideDrawClose}
            showDraw={sideDrawOpen}
          />
        </header>
        <main>{this.props.children}</main>
      </>
    )
  }
}

export default Header
