import React, { Component, createRef } from "react"

import { animateFrame } from "../../helpers/page-scroll"
import {
  setHeightScrollHorizontal,
  setHeightScrollVertical,
  mouseHoverEffect,
  progressMouseScroll,
} from "../../helpers/utils"
import { mouseMove, mouseDown, mouseUp } from "../../helpers/mouse-drag"
import { $ } from "../../helpers/dom"

import styles from "./scrollpage.module.scss"

export default class Scrollpage extends Component {
  constructor(props) {
    super(props)

    this.piContainer = createRef()
    this.fixcontainer = createRef()
    this.cursor = createRef()
    this.cursorCircle = createRef()
    this.progressCircle = createRef()

    this.funcResize = null
    this.funcScroll = null
    this.funcMouseMove = null
    this.funcMouseDown = null
    this.funcMouseUp = null

    this.scrollTop = 0
    this.tweened = 0
    this.req = null
    this.speed = 0.15

    this.btnElements = null
    this.linkElementsArrowFollow = null
    this.isHorizontalScroll = this.props.isHorizontalScroll ?? true
  }

  componentDidMount() {
    animateFrame()
    const circumferenceProgress =
      this.progressCircle.current.r.baseVal.value * 2 * Math.PI

    //set the styles
    this.fixcontainer.current.style.position = "fixed"
    this.fixcontainer.current.style.backgroundColor = "#000"
    this.fixcontainer.current.style.top = "0"
    this.fixcontainer.current.style.left = "0"
    this.fixcontainer.current.style.transform =
      "matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)"

    // init progress bar mouse
    // https://codepen.io/jeremenichelli/pen/vegymB
    this.progressCircle.current.style.strokeDasharray = `${circumferenceProgress} ${circumferenceProgress}`
    this.progressCircle.current.style.strokeDashoffset = circumferenceProgress

    const scrollDefault = () => {
      this.speed = 1
      this.scrollTop = window.scrollY
    }

    const update = () => {
      this.req = window.requestAnimationFrame(update)
      if (Math.abs(this.scrollTop - this.tweened) > 0) {
        const left = Math.floor(
          (this.tweened += this.speed * (this.scrollTop - this.tweened))
        )
        if (window.innerWidth >= 1025 && this.isHorizontalScroll) {
          this.fixcontainer.current.style.transform = `matrix3d(1,0,0,0, 0,1,0,0, 0,0,1,0, ${left *
            -1},0,0,1)`
          setHeightScrollHorizontal(this.piContainer.current)
        } else {
          this.fixcontainer.current.style.transform = `matrix3d(1,0,0,0, 0,1,0,0, 0,0,1,0, 0,${left *
            -1},0,1)`
          setHeightScrollVertical(this.piContainer.current)
        }
      }
      progressMouseScroll(
        this.progressCircle.current,
        circumferenceProgress,
        this.cursor.current,
        this.cursorCircle.current,
        this.scrollTop
      )
    }
    update()

    this.funcMouseDown = mouseDown.bind(
      this,
      this.cursor.current,
      this.cursorCircle.current,
      this.isMouseDrag
    )
    this.funcMouseUp = mouseUp.bind(
      this,
      this.cursor.current,
      this.cursorCircle.current,
      this.isMouseDrag
    )

    const callbackScroll = (scroll, speed) => {
      this.scrollTop = scroll
      if (speed) {
        this.speed = speed
      }
    }
    this.funcResize = () => {
      this.req = null
      if (window.innerWidth >= 1025 && this.isHorizontalScroll) {
        // this.speed = 0.15;
        this.fixcontainer.current.style.display = "flex"
        this.fixcontainer.current.style.height = "100vh"
        setHeightScrollHorizontal(this.piContainer.current)
        if (this.funcScroll) {
          window.removeEventListener("scroll", this.funcScroll)
          this.funcScroll = null
        }
        this.funcScroll = this.props.scrollHandle.bind(this, callbackScroll)
        // mouse hover into link

        window.addEventListener("mousedown", this.funcMouseDown)
        window.addEventListener("mouseup", this.funcMouseUp)
        this.cursor.current.style.opacity = "1"
        this.cursorCircle.current.style.opacity = "1"
        this.btnElements = $(document, ".mouse-hover", "NodeList")
        mouseHoverEffect(
          this.btnElements,
          this.cursor.current,
          this.cursorCircle.current
        )
        this.funcMouseMove = mouseMove.bind(
          this,
          this.cursor.current,
          this.cursorCircle.current
        )
        window.addEventListener("mousemove", this.funcMouseMove)
      } else {
        this.fixcontainer.current.style.display = "block"
        this.fixcontainer.current.style.height = "initial"
        setHeightScrollVertical(this.piContainer.current)
        if (this.funcScroll) {
          window.removeEventListener("scroll", this.funcScroll)
          this.funcScroll = null
        }
        this.funcScroll = scrollDefault
        window.removeEventListener("mousemove", this.funcMouseMove)
        window.removeEventListener("mousedown", this.funcMouseDown)
        window.removeEventListener("mouseup", this.funcMouseUp)
        this.cursor.current.style.opacity = "0"
        this.cursorCircle.current.style.opacity = "0"
      }
      window.addEventListener("scroll", this.funcScroll, false)
    }
    window.addEventListener("resize", this.funcResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.funcScroll)
    window.removeEventListener("resize", this.funcResize)
    window.removeEventListener("mousemove", this.funcMouseMove)
    window.removeEventListener("mousedown", this.funcMouseDown)
    window.removeEventListener("mouseup", this.funcMouseUp)
    window.cancelAnimationFrame(this.req)
  }

  render() {
    return (
      <div ref={this.piContainer}>
        <div ref={this.fixcontainer}>{this.props.children}</div>

        <div className={styles.cursor} ref={this.cursor}></div>
        <div className={styles.cursorCircle} ref={this.cursorCircle}>
          <svg>
            <circle
              ref={this.progressCircle}
              stroke="#ffdec4"
              strokeWidth="2"
              fill="transparent"
              r="19"
              cx="20"
              cy="20"
            />
          </svg>
        </div>
      </div>
    )
  }
}
