import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/05",
  "guid": "2f2925b8-287e-4731-b53b-4f3559eb7910",
  "title": "Pitch hay không Pitch",
  "subtitle": "The fifth episode.",
  "publicationDate": "2020-12-02T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Đôi khi đi pitch cũng giống như đánh một canh bạc, thắng thua có chắc do hay dở? Khi bước vào một bàn pitch mà bạn không tường mặt trọng tài và cả những người tham gia...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_05.mp3",
  "size": 44640918,
  "duration": 2233,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Đôi khi đi pitch cũng giống như đánh một canh bạc, thắng thua có chắc do hay dở? Khi bước vào một bàn pitch mà bạn không tường mặt trọng tài và cả những người tham gia, thật khó để lường trước đường đi nước bước của đối thủ. Nhưng chẳng lẽ cứ phải tỏ đường thì mới dám đi? Gặp “big fish” thì sao? Không lẽ nhắm quay lưng? Vậy pitching hay không pitching?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      