/**
 *
 * @param {Element} container Node Element
 * @param {Element} sec NodeList Element
 * @param {Number}  time time
 */
const setHeightScrollHorizontal = container => {
  const containerWidth = container.children[0].offsetWidth
  const h = containerWidth - window.innerWidth + window.innerHeight
  container.style.height = `${h}px`
}

const setHeightScrollVertical = container => {
  const h = container.firstElementChild.clientHeight
  container.style.height = `${h}px`
}

/**
 *
 * @param {Element} btnElements NodeList Element
 * @param {Element} cursor Node Element cursor
 * @param {Element} cursorCircle
 */
const mouseHoverEffect = (btnElements, cursor, cursorCircle) => {
  if (btnElements.length) {
    for (const e of btnElements) {
      e.style.cursor = "none"
      e.addEventListener("mouseenter", function() {
        cursor.style.transform = "scale(0.7)"
        cursorCircle.style.transform = "scale(2)"
        cursorCircle.firstChild.style.opacity = "0"
        cursorCircle.lastElementChild.style.display = "none"
      })
      e.addEventListener("mouseleave", function() {
        cursor.style.transform = "scale(1.1)"
        cursorCircle.style.transform = "scale(1)"
        cursorCircle.firstChild.style.opacity = "1"
        cursorCircle.lastElementChild.style.display = "block"
      })
    }
  }
}

/**
 *
 * @param {Element} progressCircle Node Element
 * @param {Number} circumference circumference progress mouse
 * @param {Element} cursor Node Element
 * @param {Element} cursorCircle Node Element
 * @param {Number} scrollTop distance scrollY
 */
const progressMouseScroll = (
  progressCircle,
  circumference,
  cursor,
  cursorCircle,
  scrollTop
) => {
  if (progressCircle && cursor && cursorCircle) {
    const maxScroll = document.body.offsetHeight - window.innerHeight,
      offset = circumference - (scrollTop / maxScroll) * circumference
    progressCircle.style.strokeDasharray = `${circumference} ${circumference}`
    progressCircle.style.strokeDashoffset = offset
    progressCircle.style.stroke = "#ffdec4"
    cursor.style.background = "#ffdec4"
    cursorCircle.style.background = "#a7a7a771"
  }
}

/**
 * Hidden section
 * @param {Element} sectionsELements  Node Element
 * @param {Number} index index element active
 * @param {String} classActive class name
 */
const activeSection = (sectionsELements, index, classActive) => {
  sectionsELements.forEach((elements, i) => {
    if (i + 1 === index) {
      elements.classList.add(classActive)
    } else {
      elements.classList.remove(classActive)
    }
  })
}

const isElementVisible = el => {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight
  return (
    (rect.x >= 0 && rect.x <= vWidth && rect.y >= 0 && rect.y <= vHeight) ||
    (rect.right >= 0 &&
      rect.right <= vWidth &&
      rect.bottom >= 0 &&
      rect.bottom <= vHeight)
  )
}

const addClassAnimate = (elements, className) => {
  if (!elements.length) elements = new Array(elements)
  Array.prototype.forEach.call(elements, element => {
    if (isElementVisible(element)) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  })
}

const changeWidthInput = e => {
  if (
    e.target.getAttribute("size") === "0" ||
    e.target.getAttribute("size") === "2"
  ) {
    e.target.style.width = e.target.dataset.w + "px"
  } else {
    e.target.style.width = "auto"
  }
  e.target.setAttribute(
    "size",
    e.target.value.length ? e.target.value.length + 1 : e.target.value.length
  )
}

const handleNextSlide = (sec5Count, boxActive, sec5LeftActive) => {
  let nextSlide = null
  if (!sec5LeftActive.nextSibling) {
    nextSlide = boxActive.firstElementChild
  } else {
    nextSlide = sec5LeftActive.nextSibling
  }
  const eleActiveClone = sec5LeftActive
  const h = nextSlide.offsetHeight
  boxActive.parentElement.style.cssText = `min-height: ${h}px !important;`

  sec5LeftActive.style.left = "-100%"
  sec5LeftActive.classList.remove("sec5LeftActive")
  nextSlide.style.left = "0"
  nextSlide.classList.add("sec5LeftActive")
  const index = [...boxActive.childNodes].indexOf(nextSlide)
  sec5Count.firstChild.textContent = `o${index + 1}`
  setTimeout(() => {
    eleActiveClone.style.cssText =
      "transition: none !important; left: 102%; opacity: 0;"
  }, 500)
  setTimeout(() => {
    eleActiveClone.style.cssText =
      "transition: all .35s ease-in-out !important; left: 102%;"
  }, 600)
}

const handlePrevSlide = (sec5Count, boxActive, sec5LeftActive) => {
  if (!sec5LeftActive.previousSibling) return
  sec5LeftActive.style.left = "100%"
  sec5LeftActive.classList.remove("sec5LeftActive")
  sec5LeftActive.previousSibling.style.left = "0"
  sec5LeftActive.previousSibling.classList.add("sec5LeftActive")
  const index = [...boxActive.childNodes].indexOf(
    sec5LeftActive.previousSibling
  )
  sec5Count.firstChild.textContent = `o${index + 1}`
}

const scrollToElement = position => {
  let elementPosition = position
  let startPosition = window.pageYOffset
  let startTime = null
  let duration = 500

  function animate(currentTime) {
    if (!startTime) startTime = currentTime
    let timeElapsed = currentTime - startTime

    let run = easeInOutSine(
      timeElapsed,
      startPosition,
      elementPosition,
      duration
    )
    window.scrollTo(0, run)
    if (timeElapsed < duration) requestAnimationFrame(animate)
  }

  function easeInOutSine(t, b, c, d) {
    return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b
  }

  requestAnimationFrame(animate)
}

const postData = async (url, data) => {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  })
  return response.json()
}

const getPosition = Element => Element.getBoundingClientRect()

export {
  setHeightScrollHorizontal,
  setHeightScrollVertical,
  mouseHoverEffect,
  progressMouseScroll,
  activeSection,
  isElementVisible,
  addClassAnimate,
  changeWidthInput,
  handleNextSlide,
  handlePrevSlide,
  scrollToElement,
  postData,
  getPosition,
}
