import anime from "animejs"
// header menu
const animatePageExitHandler = node => {
  let p = node.firstElementChild.firstElementChild
  let tweened = +p.style.transform.split(", ")[12]
  let Point = tweened + window.innerWidth

  anime
    .timeline({
      easing: "cubicBezier(.25, .97, .22, .99)",
    })
    .add({
      targets: p,
      duration: 300,
      opacity: [1, 0],
    })

  const update = () => {
    window.requestAnimationFrame(update)
    if (Math.abs(Point - tweened) > 0) {
      const left = Math.floor((tweened += 0.1 * (Point - tweened)))
      p.style.transform = `matrix3d(1,0,0,0, 0,1,0,0, 0,0,1,0, ${left},0,0,1)`
    }
  }
  update()
}

const animatePageEntryHandler = node => {
  let w = window.innerWidth
  const animation = anime
    .timeline({
      easing: "easeInOutSine",
    })
    .add({
      targets: node,
      duration: 400,
      delay: 1000,
      translateX: [`${w}px`, "0"],
      opacity: [0, 1],
    })
  animation.finished.then(function() {
    node.removeAttribute("style")
  })
}

// insight filter PC
const insightFilterExit = node => {
  anime
    .timeline({
      easing: "easeInOutSine",
    })
    .add({
      targets: node,
      duration: 350,
      opacity: [1, 0],
    })
}

const insightFilterEntry = node => {
  let w = window.innerWidth
  const animation = anime
    .timeline({
      easing: "easeInOutSine",
    })
    .add({
      targets: node.firstElementChild,
      duration: 400,
      delay: 700,
      translateX: [`${w}px`, "0"],
      opacity: [0, 1],
    })
  animation.finished.then(function() {
    node.firstElementChild.style.transform = "none"
  })
}

// keyinsight
const keyInsightExit = node => {
  anime
    .timeline({
      easing: "easeInOutSine",
    })
    .add({
      targets: node,
      duration: 350,
      opacity: [1, 0],
    })
}

const keyInsightEntry = node => {
  const animation = anime
    .timeline({
      easing: "easeInOutSine",
    })
    .add({
      targets: node,
      duration: 400,
      delay: 700,
      opacity: [0, 1],
    })
  animation.finished.then(function() {
    node.style.transform = "none"
  })
}

export {
  animatePageExitHandler,
  animatePageEntryHandler,
  insightFilterExit,
  insightFilterEntry,
  keyInsightExit,
  keyInsightEntry,
}
