import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/17",
  "guid": "60da2398-ff70-4878-b454-7288a462e146",
  "title": "Mô hình B&C: Công thức gắn kết khách hàng bền chặt",
  "subtitle": "Mô hình B&C: Công thức gắn kết khách hàng bền chặt",
  "publicationDate": "2021-03-31T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Doanh nghiệp vốn luôn tự hào khi sản phẩm của mình nhanh hơn hay sạch hơn. Tuy nhiên,  chẳng tốn mấy thời gian để các đối thủ trên thị trường học hỏi và tạo ra những sản phẩm chẳng thua kém gì của bạn...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_17.mp3",
  "size": 69684925,
  "duration": 2178,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doanh nghiệp vốn luôn tự hào khi sản phẩm của mình “nhanh hơn” hay “sạch hơn”. Tuy nhiên,  chẳng tốn mấy thời gian để các đối thủ trên thị trường “học hỏi” và tạo ra những sản phẩm chẳng thua kém gì của bạn. Nếu thị trường có những sản phẩm “nhanh hơn” hay “tốt hơn” thì lợi thế cạnh tranh của bạn là gì?
B&C Model là tư duy ứng dụng sáng tạo & thấu hiểu nhằm giải quyết bài toán kinh doanh của thương hiệu, từ đó gắn kết khoảng cách vô hình giữa thương hiệu và khách hàng mục tiêu. Cùng chúng tôi tìm hiểu thêm tại số podcast này!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      