import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/03",
  "guid": "df7957a1-ae50-4432-bfc0-60bd2663a526",
  "title": "Sức Mạnh Của Việc Đặt Câu Hỏi",
  "subtitle": "The third episode.",
  "publicationDate": "2020-11-19T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Toàn bộ thành quả đầu ra của dự án sẽ nhanh chóng đổ sông đổ bể nếu doanh nghiệp và agency không thể cùng nhau xác định chính xác Mục tiêu ngay từ giai đoạn tiền dự án....",
  "url": "https://beautique.vn/mp3/Beauthik_talk_03.mp3",
  "size": 40841280,
  "duration": 2044,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Toàn bộ thành quả đầu ra của dự án sẽ nhanh chóng đổ sông đổ bể nếu doanh nghiệp và agency không thể cùng nhau xác định chính xác Mục tiêu ngay từ giai đoạn tiền dự án.
Chẳng một ai muốn phí hoài thời gian và tiền bạc của mình vào những điều vô nghĩa. Bởi vậy, hãy ngồi lại và cùng nhau định hình rõ ràng đích đến mà đôi bên cùng mong muốn, trước khi bắt đầu guồng quay của công việc.
Trong kỳ podcast lần này, Beautique sẽ chia sẻ cách chúng tôi Đặt Câu Hỏi để tìm ra vấn đề cốt lõi, từ đó bắt tay giải quyết vấn đề cùng doanh nghiệp!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      