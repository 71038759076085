import React, { Component } from "react"
import { Link } from 'gatsby';

import SEO from '../components/layout/SEO'
import Layout from '../components/layout/layout'
import SectionFull from "../components/layout/sectionFull"
import KeyProject from "../components/modules/keyProject"
import ContactComponent from '../components/modules/contact'
import Star from '../components/modules/star'

import { addClassAnimate, scrollToElement } from '../helpers/utils'
import { $, changeClassElement } from '../helpers/dom'
import { scrollToPoint, animateFrame } from '../helpers/page-scroll'
import ScrollPageComponent from '../components/modules/scrollpage';

import styles from "./home.module.scss"

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataKeyProjects: [
        {
          img: 'work/thumb/thumb-olympia.jpg',
          title: 'The Olympia School - Trường học của tương lai',
          link: '/work-detail/olympia-school',
          subtitle: 'Strategy/Brand'
        },
        {
          img: 'work/thumb/thumb-joa.png',
          title: 'Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air',
          link: '/work-detail/joa',
          subtitle: 'Strategy/Brand'
        },
        {
          img: 'work/thumb/thumb-doan.jpg',
          title: 'DOAN - thời trang đại diện cho khái niệm Effortless beauty',
          link: '/work-detail/doan',
          subtitle: 'Strategy/Brand'
        },
        {
          img: 'work/thumb/thumb-wd.jpg',
          title: 'Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé',
          link: '/work-detail/wonder',
          subtitle: 'Strategy/Brand'
        },
        {
          img: 'work/thumb/thumb-mic.jpg',
          title: 'Một thập ký xây dựng niềm tin với MIC',
          link: '/work-detail/mic',
          subtitle: 'Strategy/Brand'
        },
        {
          img: 'work/thumb/thumb-han.jpg',
          title: 'Lưu giữ nét Hà Nội trong không gian The HAN Hotel',
          link: '/work-detail/han',
          subtitle: 'Strategy/Brand'
        },
      ],
    }

    this.distanceScroll = 0;
    this.sec4axisX = {
      axisXpos: false,
      axisXNeg: false,
    };
    this.checkBrowser = false;
    this.pointScroll = 1;
    this.req = null;
  }

  componentDidMount() {
    animateFrame();
    // check browser
    if (window.navigator.userAgent.includes('Firefox') || window.navigator.userAgent.includes('Edg')) {
      this.checkBrowser = true;
      this.pointScroll = 2;
    }

    // req
    const projectElements = $(document, '.project', 'NodeList');
    const elementsActive = $(document, '.element-active', 'NodeList');
    const section = $(document, 'section', 'NodeList');
    const sec6Btn = $(document, `.${styles.sec6_btn}`, 'NodeList');
    const sec4Box = $(document, `.${styles.sec4_box}`, 'NodeList');
    const ww = window.innerWidth / 2;
    const imgSection2 = $(document, `.${styles.sec2__image}`);

    const imageZoomIn = element => {
      element.firstElementChild.style.transform = 'scale(0.95) translateY(8px)';
      element.firstElementChild.nextElementSibling.style.transform = 'scale(0.95)';
      element.lastElementChild.style.transform = 'scale(0.95) translateY(-8px)';
    }

    const imageZoomOut = element => {
      element.firstElementChild.style.transform = 'scale(1) translateY(0)';
      element.firstElementChild.nextElementSibling.style.transform = 'scale(1)';
      element.lastElementChild.style.transform = 'scale(1) translateY(0)';
    }

    projectElements.forEach(element => {
      element.firstElementChild.nextElementSibling.addEventListener('mouseenter',() => imageZoomIn(element));
      element.firstElementChild.nextElementSibling.addEventListener('mouseleave',() => imageZoomOut(element));
      element.lastElementChild.addEventListener('mouseenter',() => imageZoomIn(element));
      element.lastElementChild.addEventListener('mouseleave',() => imageZoomOut(element));
    })

    const activeLinks = () => {
      Array.prototype.forEach.call(projectElements, (element, i) => {
        const xEl = element.getBoundingClientRect().x;
        if (xEl > ww && xEl <= ww + 340) {
          this.p = window.scrollY;
        }
        if (xEl <= ww) {
          const d = Math.floor(Math.abs(xEl - ww));
          if (d <= 300) {
              element.style.height = `${250 + Math.floor(d*0.5)}px`;
              element.firstElementChild.nextElementSibling.style.width = `${340 + Math.floor(d*1.3)}px`;
              element.firstElementChild.style.width = `${340 + Math.floor(d*1.3)}px`;
              element.lastElementChild.style.width = `${340 + Math.floor(d*1.3)}px`;
              element.firstElementChild.nextElementSibling.style.height = `${250 + Math.floor(d*0.5)}px`;
              element.lastElementChild.firstElementChild.style.fontSize = `${27 + Math.floor(d*0.08)}px`;

              if (element.previousElementSibling) {
                element.previousElementSibling.dataset.tf = Math.floor(d*1.3);
                element.previousElementSibling.style.transform = `translateX(-${Math.floor(d*1.3)}px)`;
              }
              if (i >= 2) {
                try {
                  element.previousElementSibling.previousElementSibling.style.transform = `translateX(-${+element.previousElementSibling.previousElementSibling.dataset.tf + Math.floor(d*1.3)}px)`;
                } catch (error) {}
              }
          }
          else {
            element.style.height = `400px`;
            element.firstElementChild.nextElementSibling.style.height = `400px`;
            element.lastElementChild.firstElementChild.style.fontSize = `50px`;
          }
        } else {
          element.style.height = `250px`;
          element.style.transform = `translateX(0px)`;
          element.firstElementChild.nextElementSibling.style.width = `340px`;
          element.firstElementChild.style.width = `340px`;
          element.lastElementChild.style.width = `340px`;
          element.firstElementChild.nextElementSibling.style.height = `250px`;
          element.lastElementChild.firstElementChild.style.fontSize = `27px`;
        }
      });

      setTimeout(() => {
        section[0].firstElementChild.lastElementChild.classList.add(styles.btnAnimate)
      }, 2500);

      addClassAnimate(elementsActive, styles.active);
      addClassAnimate(sec4Box, styles.sec4BoxActive);

      if (section[4].getBoundingClientRect().x <= 10 && section[6].getBoundingClientRect().x >= window.innerWidth) {
        sec6Btn[0].style.display = 'none';
        sec6Btn[1].style.display = 'block';
      } else {
        sec6Btn[0].style.display = 'block';
        sec6Btn[1].style.display = 'none';
      }

      // section 2
      imgSection2.style.backgroundPosition = `-${Math.floor(window.scrollY * .05)}px`;

      this.req = window.requestAnimationFrame(activeLinks);
    }

    // res
    const sec6Project = $(document, `.${styles.sec6_project}`);
    sec6Project.addEventListener('touchstart', function(e) {
      this.dis = 0;
      this.x1 = e.touches[0].clientX;
    });

    sec6Project.addEventListener('touchmove', function(e) {
      this.x2 = e.touches[0].clientX;
      this.dis = this.x2 - this.x1;
    });

    sec6Project.addEventListener('touchend', function() {
      const pjActiveElement = $(sec6Project, '.pj-active', 'NodeList');
      const pjActive = pjActiveElement[pjActiveElement.length - 1]
      const pjLeft = Math.abs(sec6Project.offsetLeft);
      const pjActiveWidth = pjActive.offsetWidth;
      if (this.dis < 0 && Math.abs(this.dis) > 10 && pjActive.nextSibling) {
        sec6Project.style.left = `-${pjLeft + pjActiveWidth + window.innerWidth*0.12}px`;
        pjActive.nextSibling.classList.add('pj-active');
      } else if (this.dis > 0 && Math.abs(this.dis) > 10 && pjActive.previousSibling){
        sec6Project.style.left = `-${pjLeft - (pjActiveWidth + window.innerWidth*0.12)}px`;
        pjActive.classList.remove('pj-active');
      }
    });


    // mb
    const animateMb = () => {
      sec4Box.forEach(e => {
        let rec1 = e.getBoundingClientRect();
        if (rec1.y <= window.innerHeight/2) {
          if (!e.previousSibling && !this.ac) {
            e.classList.add(styles.sec4MbActive);
            this.ac = true;
          } else if (e.previousSibling && this.ac) {
            e.previousSibling.classList.remove(styles.sec4MbActive);
            e.classList.add(styles.sec4MbActive);
          }
        } else {
          e.classList.remove(styles.sec4MbActive);
          this.ac = false;
        }
      })

      this.reqMb = window.requestAnimationFrame(animateMb);
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1025) {
        window.cancelAnimationFrame(this.req);
        this.req = null;
        if (!this.reqMb) animateMb();

        const pjActiveElement = $(sec6Project, '.pj-active', 'NodeList');
        sec6Project.style.left = "0";
        pjActiveElement.forEach((e, i) => {
          if (i === 0) return;
          e.classList.remove('pj-active');
        });
      } else {
        window.cancelAnimationFrame(this.reqMb);
        this.reqMb = null;
        if (!this.req) activeLinks();
      }
    });
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.req);
    window.cancelAnimationFrame(this.reqMb);
  }

  // callback scroll
  scrollHandle = (callbackScroll) => {
    const scrollTop = window.scrollY;

    const sectionElements = $(document, `section`, "NodeList");
    const sec4OffsetLeft = sectionElements[3].offsetLeft;
    const sec5OffsetLeft = sectionElements[4].offsetLeft;

    // ascesding scroll
    if (scrollTop > this.distanceScroll) {
      // section 4
      if (scrollTop + window.innerWidth <= sec4OffsetLeft && !this.sec4axisX.axisXpos) {
        callbackScroll(scrollTop, 0.15);
      }
      // display section 4
      else if (!this.sec4axisX.axisXpos && scrollTop + window.innerWidth > sec4OffsetLeft) {
        if (scrollTop - this.distanceScroll < this.pointScroll && !this.sec4axisX.axisXpos) {
          callbackScroll(sec4OffsetLeft - window.innerWidth);
          scrollToPoint(
            sec4OffsetLeft - window.innerWidth,
            { scrollTop, type: 'negative' },
            sectionElements[1], sectionElements[2]
          );
        } else {
          callbackScroll(sec4OffsetLeft, 0.05);
          if (this.checkBrowser) {
            setTimeout(() => {
              scrollToPoint(sec4OffsetLeft);
            }, 200);
          } else {
            scrollToPoint(sec4OffsetLeft);
          }
          this.sec4axisX.axisXpos = true;
        }
      }
      // pass section 4 and display section 5
      else if (scrollTop > sec4OffsetLeft && this.sec4axisX.axisXpos && !this.sec4axisX.axisXNeg && (sectionElements[3].getBoundingClientRect().x >= -20 && sectionElements[3].getBoundingClientRect().x <= 20)) {
        if (scrollTop - this.distanceScroll < this.pointScroll && this.sec4axisX.axisXpos && !this.sec4axisX.axisXNeg) {
          callbackScroll(sec4OffsetLeft);
          scrollToPoint(
            sec4OffsetLeft,
            { scrollTop, type: 'negative'},
            sectionElements[3].firstElementChild.firstElementChild
          );
        }
        else {
          callbackScroll(sec5OffsetLeft, 0.05);
          if (this.checkBrowser) {
            setTimeout(() => {
              scrollToPoint(sec5OffsetLeft);
            }, 200);
          } else {
            scrollToPoint(sec5OffsetLeft);
          }
          this.sec4axisX.axisXNeg = true;
          changeClassElement('REMOVE', styles.translateXPositiveHome, sectionElements[4].firstElementChild);
          setTimeout(() => {
            changeClassElement('ADD', styles.changeBgWhite, sectionElements[3],sectionElements[4], sectionElements[5]);
            changeClassElement('REMOVE', styles.translateXPositiveHome, sectionElements[5].firstElementChild.firstElementChild);
          }, 800);
        }
      }
      // next scroll
      else if (this.sec4axisX.axisXNeg && scrollTop > sec5OffsetLeft) {
        callbackScroll(scrollTop,0.15);
      }
    }
    // descesing scroll
    else {
      // scroll
      if (this.sec4axisX.axisXNeg && scrollTop - window.innerWidth > sec4OffsetLeft) {
        callbackScroll(scrollTop, 0.15);
      }
      // display section 4 and pass section 5
      else if (this.sec4axisX.axisXNeg && scrollTop - window.innerWidth <= sec4OffsetLeft) {
        if (this.distanceScroll - scrollTop < this.pointScroll) {
          callbackScroll(sec5OffsetLeft);
          scrollToPoint(
            sec5OffsetLeft,
            { scrollTop, type: 'positive'},
            sectionElements[4].firstElementChild,
            sectionElements[5].firstElementChild.firstElementChild
          );
        } else {
          callbackScroll(sec4OffsetLeft,0.05);
          if (this.checkBrowser) {
            setTimeout(() => {
              scrollToPoint(sec4OffsetLeft);
            }, 200);
          } else {
            scrollToPoint(sec4OffsetLeft);
          }
          this.sec4axisX.axisXNeg = false;

          changeClassElement('REMOVE', styles.changeBgWhite, sectionElements[3], sectionElements[4], sectionElements[5]);
          changeClassElement('ADD', styles.translateXPositiveHome, sectionElements[4].firstElementChild, sectionElements[5].firstElementChild.firstElementChild);
        }
      }
      // pass section 4 and display section 3
      else if (scrollTop < sec4OffsetLeft && this.sec4axisX.axisXpos && !this.sec4axisX.axisXNeg && (sectionElements[3].getBoundingClientRect().x >= -20 && sectionElements[3].getBoundingClientRect().x <= 20)) {
        if (this.distanceScroll - scrollTop < this.pointScroll && this.sec4axisX.axisXpos && !this.sec4axisX.axisXNeg) {
          callbackScroll(sec4OffsetLeft);
          scrollToPoint(
            sec4OffsetLeft,
            { scrollTop, type: 'positive'},
            sectionElements[3].firstElementChild.firstElementChild
          );
        }
        else {
          callbackScroll(sec4OffsetLeft - window.innerWidth, 0.05);
          if (this.checkBrowser) {
            setTimeout(() => {
              scrollToPoint(sec4OffsetLeft - window.innerWidth);
            }, 200);
          } else {
            scrollToPoint(sec4OffsetLeft - window.innerWidth);
          }
          this.sec4axisX.axisXpos = false;
        }
      }
      // next scroll
      else if (!this.sec4axisX.axisXpos && scrollTop + window.innerWidth <= sec4OffsetLeft) {
        callbackScroll(scrollTop, 0.15);
      }
    }

    this.distanceScroll = scrollTop;
  }

  pageTranslateX = () => scrollToElement($(document, `.${styles.sec3}`).offsetLeft);

  render() {
    const { dataKeyProjects } = this.state;

    return (
      <>
        <SEO
          title="Beautique | Brand and Creative Consultancy that helps businesses to tranform and succeed"
          url="https://beautique.vn/"
          description="Beautique | Brand and Creative Consultancy that helps businesses to tranform and succeed"
          imgThumb="https://beautique.vn/img/home/og-img.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={`${styles.sec1} ${styles.secBottom}`}>
              <Star />
              <div className={`${styles.sec1_inner} element-active`}>
                <h2>Kết nối giá trị thương hiệu đến khách hàng là sứ mệnh của Beautique.</h2>
                <button onClick={this.pageTranslateX} className={'mouse-hover '+ styles.sec1_Btn}><img src="/img/home/icon-next.svg" alt="" /></button>
              </div>
            </SectionFull>
            <SectionFull className={`${styles.sec2} element-active`}>
              <div className={styles.sec2__image} style={{backgroundImage: 'url(/img/home/image-1.jpg)'}}>

              </div>
            </SectionFull>
            <SectionFull className={`${styles.sec3} ${styles.secBottom}`}>
              <div className={`${styles.textbox1} element-active`}>
                <span>Chúng tôi làm gì?</span>
                <h3>Chúng tôi giúp doanh nghiệp gắn kết chặt chẽ với khách hàng qua việc thể hiện và truyền tải bản sắc trong thời đại nhiễu loạn.</h3>
              </div>
            </SectionFull>
            <SectionFull className={styles.sec4}>
              <div className={styles.sec4_inner}>
                <div className={styles.sec4_list}>
                  <div className={styles.sec4_box}>
                    <span className="b-number">o1 <span>/</span></span>
                    <h3 className="b-title">Strategy</h3>
                    <ul className="b-list">
                      <li>Brand</li>
                      <li>Communication</li>
                    </ul>
                    <Link to="/about" className={`${styles.sec4_btnAbout} mouse-hover`}>Về chúng tôi <img src="/img/home/icon-plus-white.svg" alt="" /></Link>
                  </div>
                  <div className={styles.sec4_box}>
                    <span className="b-number">o2 <span>/</span></span>
                    <h3 className="b-title">Design</h3>
                    <ul className="b-list">
                      <li>Identity</li>
                      <li>Website design</li>
                      <li>Editorial</li>
                      <li>Communication</li>
                    </ul>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={`${styles.sec5} ${styles.secBottom}`}>
              <div className={`${styles.textbox1} ${styles.translateXPositiveHome}`}>
                <span>Dự án đã thực hiện</span>
                <h3>Khám phá cách chúng tôi giúp thương hiệu giành lợi thế trên thị trường</h3>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec6}>
              <div className={styles.sec6__inner}>
                <div className={`${styles.sec6_project} ${styles.translateXPositiveHome}`}>
                  {dataKeyProjects.map((key, index) => (
                    <KeyProject pjActive={index===0? true : false} key={index} subtitle={key.subtitle} img={key.img} title={key.title} link={key.link} active={key.active ? 'true' : 'false'}/>
                  ))}
                </div>
                <div className={`mouse-hover ${styles.sec6_btn}`}>
                  <Link to="/work">Tất cả dự án <img src="/img/home/icon-plus-black.svg" alt=""/></Link>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec7}>
              <div className={styles.sec7_left}>
                <ContactComponent />
              </div>
              <div className={`${styles.sec7_right} element-active`}>
                <div className={styles.sec7_info}>
                  <p>33 Giang Văn Minh,<br/>Ba Đình, Hà Nội</p>
                  <p>info@beau.vn</p>
                  <p>097 531 9889 — o86 929 1771</p>
                </div>
                <div className={styles.sec7_social}>
                  <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'>
                  <svg width={36} height={36} viewBox="0 0 36 36">
                        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M20.988 8.95l-1.886-.003c-2.119 0-3.488 1.344-3.488 3.425v1.578h-1.896a.29.29 0 00-.297.284v2.288a.29.29 0 00.297.283h1.896v7.442a.29.29 0 00.296.284h2.474a.29.29 0 00.297-.284v-7.442h2.217a.29.29 0 00.297-.283v-2.288a.278.278 0 00-.086-.2.304.304 0 00-.21-.084h-2.218v-1.338c0-.643.16-.97 1.036-.97h1.27a.29.29 0 00.297-.284V9.234a.29.29 0 00-.296-.284z"
                            fill="#C8C8C8"
                          />
                        </g>
                      </svg>
                  </a>
                  <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='/#'>
                  <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                          stroke="#C8C8C8"
                          strokeWidth={1.068}
                          cx={17}
                          cy={16.846}
                          rx={17}
                          ry={16.846}
                        />
                        <g fill="#C8C8C8">
                          <path
                            d="M21.535 10.737H12.59a1.85 1.85 0 00-1.853 1.843v8.896a1.85 1.85 0 001.853 1.843h8.945a1.85 1.85 0 001.854-1.843V12.58a1.85 1.85 0 00-1.854-1.843zm-4.447 9.584c-1.84 0-3.336-1.488-3.336-3.318 0-1.829 1.496-3.317 3.336-3.317 1.839 0 3.335 1.488 3.335 3.317 0 1.83-1.496 3.318-3.335 3.318zm3.707-6.124a.947.947 0 01-.949-.944c0-.52.426-.943.95-.943.522 0 .948.423.948.943s-.426.944-.949.944z"
                            fillRule="nonzero"
                          />
                          <path d="M20.795 12.813a.442.442 0 00-.443.44.442.442 0 00.886 0 .442.442 0 00-.443-.44zM17.063 14.512a2.527 2.527 0 00-2.53 2.516 2.527 2.527 0 002.53 2.516 2.527 2.527 0 002.53-2.516 2.527 2.527 0 00-2.53-2.516z" />
                        </g>
                      </g>
                    </svg>
                  </a>
                  <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://dribbble.com/beauagency'>
                   <svg width={36} height={36} viewBox="0 0 36 36">
                    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                      <ellipse
                        stroke="#C8C8C8"
                        strokeWidth={1.068}
                        cx={17}
                        cy={16.846}
                        rx={17}
                        ry={16.846}
                      />
                      <g fill="#C8C8C8">
                        <path d="M19.106 17.633a28.263 28.263 0 011.528 5.353 7.056 7.056 0 001.316-1.036 6.948 6.948 0 001.953-3.784 8.672 8.672 0 00-4.797-.533zM21.956 12.056l-.006-.006A6.954 6.954 0 0017 10c-.731 0-1.445.112-2.123.327a28.363 28.363 0 012.86 4.347 16.247 16.247 0 004.219-2.618zM22.506 12.676a16.945 16.945 0 01-4.39 2.732c.236.476.46.958.67 1.448a9.59 9.59 0 015.207.46 6.947 6.947 0 00-1.487-4.64zM12.691 22.518A6.947 6.947 0 0017 24c1.005 0 1.978-.21 2.868-.611a27.513 27.513 0 00-1.58-5.575 8.758 8.758 0 00-5.597 4.704z" />
                        <path d="M16.868 17.437c.36-.152.727-.281 1.1-.387-.197-.45-.406-.895-.626-1.333a16.94 16.94 0 01-5.922 1.058c-.474 0-.946-.02-1.414-.058a6.954 6.954 0 002.044 5.232l.002.002a9.515 9.515 0 011.784-2.47 9.509 9.509 0 013.032-2.044zM16.959 14.98a27.862 27.862 0 00-2.898-4.337 7.004 7.004 0 00-2.01 1.407 6.947 6.947 0 00-1.965 3.85 16.14 16.14 0 006.873-.92z" />
                      </g>
                    </g>
                  </svg>
                  </a>
                  <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'>
                  <svg width={36} height={36} viewBox="0 0 36 36">
                      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                        <g fill="#C8C8C8">
                          <ellipse
                            stroke="#C8C8C8"
                            strokeWidth={1.068}
                            cx={17}
                            cy={16.846}
                            rx={17}
                            ry={16.846}
                          />
                          <path
                            d="M15.804 16.502c.345-.178.607-.378.786-.593.319-.387.476-.9.476-1.538 0-.62-.157-1.15-.472-1.594-.525-.723-1.413-1.093-2.67-1.113H8.947v10.44h4.641c.523 0 1.007-.047 1.455-.14.448-.096.836-.27 1.164-.525.291-.222.535-.497.729-.821.306-.49.46-1.046.46-1.665 0-.6-.135-1.11-.402-1.53-.27-.419-.665-.726-1.19-.92zM11 13.477h2.241c.493 0 .9.055 1.218.163.369.158.553.478.553.966 0 .437-.14.744-.419.916-.28.172-.643.259-1.09.259h-2.503v-2.304zm3.544 6.631c-.248.123-.597.184-1.043.184H11v-2.784h2.535c.442.004.785.064 1.031.176.438.203.655.576.655 1.12 0 .643-.225 1.075-.677 1.304z"
                            fillRule="nonzero"
                          />
                          <path d="M19.597 12.152h4.414v1.299h-4.414z" />
                          <path
                            d="M25.544 17.28a3.784 3.784 0 00-.608-1.597 3.09 3.09 0 00-1.315-1.142c-.53-.241-1.127-.362-1.791-.361-1.116 0-2.022.358-2.723 1.07-.7.714-1.05 1.74-1.05 3.078 0 1.427.387 2.458 1.164 3.09a4.11 4.11 0 002.683.95c1.228 0 2.183-.376 2.865-1.125.437-.473.684-.939.738-1.396h-2.033a1.678 1.678 0 01-.41.531c-.284.235-.653.353-1.105.353-.43 0-.795-.097-1.1-.291-.501-.31-.764-.854-.797-1.628h5.549c.008-.667-.012-1.18-.067-1.533zm-5.432.2c.073-.503.25-.9.531-1.195.282-.293.68-.441 1.189-.442.47 0 .862.138 1.182.415.316.28.494.685.53 1.221h-3.432z"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className={styles.sec7_logo}>
                   <p>Member of <img src="/img/contact/PC_Beau_Logo.png" alt="" /></p>
                </div>
              </div>
            </SectionFull>
            <SectionFull className={styles.fixSec7Right}>
              <div className={`${styles.sec7_right}`}>
                  <div className={styles.sec7_info}>
                    <p>33 Giang Văn Minh,<br/>Ba Đình, Hà Nội</p>
                    <p>info@beau.vn</p>
                    <p>097 531 9889 — o86 929 1771</p>
                  </div>
                  <div className={styles.sec7_social}>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/beauagency'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='/#'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://dribbble.com/beauagency'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z"/></svg>
                    </a>
                    <a className='mouse-hover' target="_blank" rel="noopener noreferrer" href='https://www.behance.net/BeauCreative'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/></svg>
                    </a>
                  </div>
                  <div className={styles.sec7_ft}>
                    <p>Member of <img src="/img/contact/Mobile_Beau_Logo.png" alt="" /></p>
                  </div>
                </div>
            </SectionFull>
          </ScrollPageComponent>
          <div  className={`mouse-hover ${styles.sec6_btn} ${styles.fixBtn}`}>
            <Link to="/work">Tất cả dự án <img src="/img/home/icon-plus-black.svg" alt=""/></Link>
          </div>
        </Layout>
      </>
    );
  }
}
