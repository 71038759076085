import React from "react"
import { Helmet } from "react-helmet"

export default function SEO(props) {
  return (
    <Helmet>
      <link rel="icon" type="image/x-icon" href="/img/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />

      <title>{props.title}</title>
      <meta name="title" content={props.title} />
      <meta name="description" content={props.description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.imgThumb} />
      <meta property="og:image:alt" content="Beautique" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={props.url} />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:image" content={props.imgThumb}></meta>
      <meta property="twitter:image:alt" content="Beautique" />
    </Helmet>
  )
}
