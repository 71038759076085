import React, { Component } from "react"
import { Link } from "gatsby"

import SEO from "../../components/layout/SEO"
import Layout from "../../components/layout/layout"
import SectionFull from "../../components/layout/sectionFull"

import ScrollPageComponent from "../../components/modules/scrollpage"
import { scrollToElement, isElementVisible } from "../../helpers/utils"
import { ElementHover } from "../../helpers/hover"
import ContactWork from '../../components/modules/contactWork';


import styles from "./olympia-school.module.scss"

export default class OlympiaSchool extends Component {
  constructor() {
    super()

    this.req = null
    this.isReqRun = false

    this.refSec6 = React.createRef();
    this.refSec8 = React.createRef();
    this.refCircle1Sec13 = React.createRef();
    this.refCircle2Sec13 = React.createRef();
  }

  componentDidMount() {
    const WINDOWN_WIDTH = window.innerWidth
    if (this.isReqRun || WINDOWN_WIDTH < 1025) return;

    new ElementHover(this.refCircle1Sec13.current);
    new ElementHover(this.refCircle2Sec13.current);

    const animate = () => {
      // sec6
      if (isElementVisible(this.refSec6.current)) {
        this.refSec6.current.classList.add(styles.sec6Active)
      }
      // sec8
      if (isElementVisible(this.refSec8.current)) {
        this.refSec8.current.classList.add(styles.sec8Active)
      }

      // requestAnimationFrame
      this.req = requestAnimationFrame(animate)
    }
    if (!this.isReqRun) {
      this.req = requestAnimationFrame(animate)
      this.isReqRun = true
    }

    window.addEventListener("resize", () => {
      if (WINDOWN_WIDTH <= 1025) {
        window.cancelAnimationFrame(this.req)
        this.req = null
        // if (!this.reqMb) animateMb();
      }
    })
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.req)
  }

  shouldComponentUpdate() {
    return false
  }

  scrollHandle = callbackScroll => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {
    return (
      <>
        <SEO
          title="The Olympia School - Trường học của tương lai"
          url="https://beautique.vn/work-detail/olympia-school"
          description="Hình thành từ năm 2003, trực thuộc hệ thống giáo dục Dream
                      House. The Olympia School luôn là đơn vị tiên phong trong
                      lĩnh vực giáo dục tư thục với mục tiên giáo dục đó là cung
                      cấp đầy đủ hành trang cả kiến thức và kĩ năng để sẵn sàng
                      với một xã hội đầy biến động. Nhà trường và Beautique đã
                      có cơ hội cùng được cộng tác trong dự án xây dựng chiến
                      lược nhận diện thương hiệu nhằm giúp trường truyền tải
                      hình ảnh và quyết tâm mới tới những khách hàng của mình."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-olympia.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
            <SectionFull className={styles.sec1}>
              <div className={styles.inner}>
                <div>
                  <h2 className={styles.sec1_title}>
                    The Olympia School <br /> - Trường học của tương lai
                  </h2>
                </div>
                <div className={styles.sec1_content}>
                  <div className={styles.sec1_left}>
                    <ul className={styles.sec1_list}>
                      <li>
                        <span>Client</span>The Olympia School
                      </li>
                      <li>
                        <span>Duration</span>12/2019 - 02/2020
                      </li>
                      <li>
                        <span>Scope</span>Brand Strategy
                        <br />
                        Brand Identity
                        <br />
                        Brand Experience
                      </li>
                    </ul>
                  </div>
                  <div className={styles.sec1_right}>
                    <p>
                      Hình thành từ năm 2003, trực thuộc hệ thống giáo dục Dream
                      House. The Olympia School luôn là đơn vị tiên phong trong
                      lĩnh vực giáo dục tư thục với mục tiên giáo dục đó là cung
                      cấp đầy đủ hành trang cả kiến thức và kĩ năng để sẵn sàng
                      với một xã hội đầy biến động. Nhà trường và Beautique đã
                      có cơ hội cùng được cộng tác trong dự án xây dựng chiến
                      lược nhận diện thương hiệu nhằm giúp trường truyền tải
                      hình ảnh và quyết tâm mới tới những khách hàng của mình.
                    </p>
                  </div>
                  <button
                    onClick={this.pageTranslateX}
                    className={styles.sec1_btn}
                  >
                    <img src="/img/icon/arrow-black.svg" alt="" />
                  </button>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec2}>
              <div className={styles.sec2_inner}>
                <div className={styles.sec2Img}>
                  <img src="/img/work/OLYMPIA/svg1.svg" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec3}>
              <div className={styles.sec3_inner}>
                <div className={styles.sec3_text}>
                  <h3>Bối cảnh thị trường</h3>
                  <p>
                    Tầng lớp trung lưu tại Việt Nam gia tăng trong vài năm gần
                    đây dẫn tới việc nhu cầu về cuộc sống cũng gia tăng. Điều
                    này khiến Việt Nam trở thành một thị trường tiềm năng để đầu
                    tư của các thương hiệu quốc tế.
                  </p>
                  <p>
                    Ngành giáo dục cũng không là ngoaị lệ khi chỉ có sự đầu tư
                    mạnh từ các tập đoàn lớn vào các chuỗi trường học chất lượng
                    cao (Vingroups, TH,...) mà còn có cả sự góp mặt của các tổ
                    chức giáo dục uy tín quốc tế như VAS, BIS, UN, etc.
                  </p>
                </div>
              </div>
              <div className={styles.sec3Img}>
                <img src="/img/work/OLYMPIA/img1.jpg" alt="" />
              </div>
              <div className={styles.sec3ImgMB}>
                <img src="/img/work/OLYMPIA/img1.jpg" alt="" />
              </div>
            </SectionFull>

            <SectionFull className={styles.sec4}>
              <div className={styles.sec4_inner}>
                <div className={styles.sec4_left}>
                  <div className={styles.sec4_text}>
                    <h3>Thị trường tiềm năng nhưng khắc nghiệt</h3>
                    <p>
                      Tính riêng trong lĩnh vực giáo dục tư thục, doanh nghiệp
                      có thể cạnh tranh được không còn chỉ phụ thuộc vào danh
                      tiếng hay tiêu chuẩn mà còn cần phải thể hiện được cho các
                      bậc phụ huynh lợi thế về chương trình giáo dục và một môi
                      trường có thể giúp trẻ phát triển toàn diện.
                    </p>
                  </div>
                </div>
                <div className={styles.sec4_right}>
                  <div className={styles.sec4_text}>
                    <h3>
                      Theo một nghiên cứu của Intage, tiêu chí lựa chọn trường
                      học của phụ huynh là
                    </h3>
                    <div className={styles.sec4_percent}>
                      <div className={styles.sec4_percent1}>
                        <div>
                          <img src="/img/work/OLYMPIA/55.png" alt="" />
                        </div>
                        <p>Lựa chọn chất lượng dạy</p>
                      </div>
                      <div className={styles.sec4_percent2}>
                        <div>
                          <img src="/img/work/OLYMPIA/52.png" alt="" />
                        </div>
                        <p>Lựa chọn chất lượng cơ sở vật chất</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.sec4Img}>
                <div className={styles.sec4Img1}>
                  <img src="/img/work/OLYMPIA/svg2.svg" alt="" />
                </div>
                <div className={styles.sec4Img2}>
                  <img src="/img/work/OLYMPIA/svg3.svg" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec5}>
              <div className={styles.sec5_inner}>
                <div className={styles.sec5_left}>
                  <div className={styles.sec5_text}>
                    <h3>Định hướng mới cho thương hiệu</h3>
                    <p>
                      The Olympia Schools đáp ứng nhu cầu của khách hàng trong
                      thời đại mới bằng một định hướng chiến lược mạnh mẽ
                    </p>
                  </div>
                  <div className={styles.sec5_quote}>
                    <h3>
                      <span className={styles.quote1}>
                        <img src="/img/work/OLYMPIA/quote.svg" alt="" />
                      </span>
                      <span className={styles.sec5_quotetext}>
                        Trường Olympia phát triển theo mô hình trường học tương
                        lai, chuẩn bị cho thế hệ trẻ bước vào một thế giới đang
                        thay đổi, sẵn sàng tìm ra giải pháp sáng tạo cho các vấn
                        đề và cùng hướng tới sự phát triển bền vững
                      </span>
                      <span className={styles.quote2}>
                        <img src="/img/work/OLYMPIA/quote.svg" alt="" />
                      </span>
                    </h3>
                  </div>
                </div>
                <div className={styles.sec5_right}>
                  <div className={styles.sec5_img}>
                    <img src="/img/work/OLYMPIA/img2.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull  className={styles.sec6}>
              <div className={styles.sec6_inner}>
                <div className={styles.sec6_left}>
                  <div className={styles.sec6_text}>
                    <h3>Rào cản của thương hiệu</h3>
                    <p>
                      Dù là một định hướng chiến lược giàu tiềm năng và có khả
                      năng cạnh tranh, nhưng định hướng mới này lại không mang
                      lại nhiều hiệu quả về mặt truyền thông. Qua quá trình tìm
                      hiểu, chúng tôi xác định được những rào cản này đến từ một
                      hệ thống nhận diện thương hiệu không truyền tải được hình
                      ảnh của thương hiệu tới phụ huynh chính xác nhất cũng như
                      đi ngược lại so với xu hướng hiện đại hóa giáo dục (theo
                      báo cáo của WENR).
                    </p>
                  </div>
                </div>
                <div ref={this.refSec6} className={styles.sec6_right}>
                  <div className={styles.sec6_img1}>
                    <img src="/img/work/OLYMPIA/img3.png" alt="" />
                  </div>
                  <div className={styles.sec6_arrow1}></div>
                  <div className={styles.sec6_boxText1}>
                    <h3 className={styles.text1}>Cổ điển</h3>
                    <h3 className={styles.text2}>Nề nếp</h3>
                    <h3 className={styles.text3}>Nghiêm túc</h3>
                  </div>
                  <div className={styles.sec6_arrow2}></div>
                  <div className={styles.sec6_boxText2}>
                    <h3>
                      Bộ nhận diện tạo ra cảm giác về một thương hiệu truyền
                      thống, có phần gò bó. Không thể hiện được định hướng
                      thương hiệu mới một cách tốt nhất
                    </h3>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec7}>
              <div className={styles.sec7_inner}>
                <div className={styles.sec7_left}>
                  <div className={styles.sec7_text}>
                    <h3>Xác định tính cách thương hiệu</h3>
                    <p>
                      Để xây dựng bộ nhận diện giúp truyền tải hình ảnh và định
                      hướng mới cho thương hiệu, cần xác định tính cách thương
                      hiệu mới cho TOS. Để làm được điều này, chúng tôi cùng nhà
                      trường đã cùng thực hiện khảo sát về hình ảnh của nhà
                      trường trong lòng phụ huynh học sinh.
                    </p>
                  </div>
                </div>
                <div className={styles.sec7_right}>
                  <div className={styles.sec7_boxText1}>
                    <div>Phụ huynh học sinh</div>
                    <div>Đội ngũ nhà trường</div>
                  </div>
                  <div className={styles.sec7_arrow1}>
                    <div className={styles.sec7_branch}></div>
                    <div className={styles.sec7_arrow}></div>
                    <div className={styles.sec7_arrow1_text}>Khảo sát</div>
                  </div>
                  <div className={styles.sec7_boxText2}>
                    <div className={styles.sec7_circle1}>Đáng tin cậy</div>
                    <div className={styles.sec7_circle2}>Nhiệt huyết</div>
                    <div className={styles.sec7_circle3}>Sáng tạo</div>
                    <div className={styles.sec7_circle4}>Ấm áp</div>
                    <div className={styles.sec7_circle5}>Can đảm</div>
                    <div className={styles.sec7_circle6}>Chuyên nghiệp</div>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull  className={styles.sec8}>
              <div className={styles.sec8_inner}>
                <div className={styles.sec8_left}>
                  <div className={styles.sec8_text}>
                    <h3>Thể hiện đặc tính thương hiệu</h3>
                    <p>
                      Để xây dựng bộ nhận diện giúp truyền tải hình ảnh và định
                      hướng mới cho thương hiệu, cần xác định tính cách thương
                      hiệu mới cho TOS. Để làm được điều này, chúng tôi cùng nhà
                      trường đã cùng thực hiện khảo sát về hình ảnh của nhà
                      trường trong lòng phụ huynh học sinh.
                    </p>
                  </div>
                </div>
                <div ref={this.refSec8} className={styles.sec8_right}>
                  <div className={styles.sec8_right_wrapper}>
                    <div className={styles.sec8_boxText}>
                      <div className={styles.text1}>
                        Tính cách thương hiệu được xác định qua khảo sát phụ
                        huynh
                      </div>
                      <div className={styles.text2}>Từ ngữ biểu đạt tính cách thương hiệu</div>
                    </div>
                    <div className={styles.box1}>
                      <div className={styles.img1}>
                        <img src="/img/work/OLYMPIA/svg1model3.png" alt="" />
                      </div>
                      <div className={styles.img1MB}>
                        <img src="/img/work/OLYMPIA/svg1m3MB.png" alt="" />
                      </div>
                      <div className={styles.img2}>
                        <img src="/img/work/OLYMPIA/svg3model3.png" alt="" />
                      </div>
                      <div className={styles.img2MB}>
                        <img src="/img/work/OLYMPIA/svg3m3MB.png" alt="" />
                      </div>
                      <div className={styles.text}>
                        Hình ảnh thương hiệu cần mang về hiện đạt và chân thật
                        để đem lại sự chuyên nghiệp. Ngoài ra, để tạo ra sự tin
                        cật và vẻ ấm áp, hình ảnh và thiết kế cần giữ sự đơn
                        giản, màu sắc tươi sáng, tông màu đầm ấm, đời thường.
                      </div>
                    </div>
                    <div className={styles.box2}>
                      <div className={styles.img1}>
                        <img src="/img/work/OLYMPIA/svg2model3.png" alt="" />
                      </div>
                      <div className={styles.img1MB}>
                        <img src="/img/work/OLYMPIA/svg2m3MB.png" alt="" />
                      </div>
                      <div className={styles.img2}>
                        <img src="/img/work/OLYMPIA/svg4model3.png" alt="" />
                      </div>
                      <div className={styles.img2MB}>
                        <img src="/img/work/OLYMPIA/svg4m3MB.png" alt="" />
                      </div>
                      <div className={styles.text}>
                        Cân bằng với sự chuyên nghiệp và gần gũi, thiết kế cần
                        mang lại vẻ năng động và giàu năng lượng qua những
                        chuyển động, màu sắc giàu năng lượng và với những thông
                        điệp mạnh mẽ, ấn tượng.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec9}>
              <div className={styles.sec9_inner}>
                <div className={styles.sec9_left}>
                  <div className={styles.sec9_text}>
                    <h3>Mục tiêu dự án</h3>
                    <p>
                      Sau quá trình làm việc và trao đổi giữa Beau Agency và nhà
                      trường The Olympia School, chúng tôi đưa ra thống nhất về
                      mục tiêu cho dự án đó là giúp nhà trường xây dựng thương
                      hiệu cho TOA nhằm tối ưu hóa việc truyền tải định hướng
                      chiến lược mới một cách mạnh mẽ tới khách hàng. Từ đó gia
                      thế mạnh cạnh tranh trên thị trường so với các đối thủ.
                    </p>
                  </div>
                </div>
                <div className={styles.sec9_right}>
                  <div className={styles.sec9_img}>
                    <img src="/img/work/OLYMPIA/svgmodel4.png" alt="" />
                  </div>
                  <div className={styles.sec9_imgMB}>
                    <img src="/img/work/OLYMPIA/svgm4MB.png" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec10}>
              <div className={styles.sec10_inner}>
                <div className={styles.sec10_left}>
                  <div className={styles.sec10_text}>
                    <h3>Giải pháp</h3>
                    <p>
                      Nhận diện thương hiệu mới không chỉ giữ được những tài sản
                      thương hiệu giá trị như chiến Khiên và màu sắc thương hiệu
                      mà còn mang lại tính ứng dụng thực tiễn cao hơn khi có thể
                      sử dụng trên nhiều hoàn cảnh, nền tảng và chất liệu khác
                      nhau hơn.
                    </p>
                  </div>
                </div>
                <div className={styles.sec10_right}>
                  <div className={styles.sec10_img}>
                    <img src="/img/work/OLYMPIA/logos.svg" alt="" />
                  </div>
                  <div className={styles.sec10_imgPC}>
                    <img
                      src="/img/work/OLYMPIA/logo-animation-min.gif"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec11}>
              <div className={styles.sec11_inner}>
                <div className={styles.sec11_logo1}>
                  <div className={styles.sec11_img}>
                    <img src="/img/work/OLYMPIA/svg4.svg" alt="" />
                  </div>
                  <p>LOGO TRÊN NỀN MÀU THƯƠNG HIỆU</p>
                </div>
                <div className={styles.sec11_logo2}>
                  <div className={styles.sec11_img}>
                    <img src="/img/work/OLYMPIA/svg5.svg" alt="" />
                  </div>
                  <p>LOGO TRÊN NỀN MÀU THƯƠNG HIỆU</p>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec12}>
              <div className={styles.sec12_inner}>
                <div className={styles.sec12_left}>
                  <div className={styles.sec12_text}>
                    <h3>Logo phụ</h3>
                    <p>
                    Bên cạnh logo chính, bộ logo phụ cũng đóng một vai trò quan trọng trong việc đa dạng hoá ứng dụng nhưng đồng thời vẫn duy trì được tính thống nhất cho cả hệ thống nhận diện
                    </p>
                  </div>
                </div>
                <div className={styles.sec12_right}>
                  <div className={styles.sec12_boxImg1}>
                    <div>1/ Horizontal micro logo</div>
                    <img className={styles.imgPC} src="/img/work/OLYMPIA/svglogos1.svg" alt="" />
                    <img className={styles.imgMB} src="/img/work/OLYMPIA/svglogos1MB.svg" alt="" />
                  </div>

                  <div className={styles.sec12_boxImg2}>
                    <div className={styles.img}>
                      <div>2/ Vertical logo</div>
                      <img className={styles.imgPC} src="/img/work/OLYMPIA/svglogos2.svg" alt="" />
                      <img className={styles.imgMB} src="/img/work/OLYMPIA/scglogos2MB.svg" alt="" />
                    </div>

                    <div className={styles.img}>
                      <div>3/ logomark</div>
                      <img className={styles.imgPC} src="/img/work/OLYMPIA/svglogos3.svg" alt="" />
                      <img className={styles.imgMB} src="/img/work/OLYMPIA/svglogos3MB.svg" alt="" />
                    </div>

                    <div className={styles.img}>
                      <div>4/ micro logomark</div>
                      <img className={styles.imgPC} src="/img/work/OLYMPIA/svglogos4.svg" alt="" />
                      <img className={styles.imgMB} src="/img/work/OLYMPIA/svglogos4MB.svg" alt="" />
                    </div>

                    <div className={styles.img}>
                      <div>5/ olympia emblems</div>
                      <img className={styles.imgPC} src="/img/work/OLYMPIA/svglogos5.svg" alt="" />
                      <img className={styles.imgMB} src="/img/work/OLYMPIA/svglogos5MB.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec13}>
              <div className={styles.sec13_inner}>
                <div className={styles.sec13_left}>
                  <div className={styles.sec13_text}>
                    <h3>Chiến lược màu sắc</h3>
                    <p>
                      Hai màu sắc vàng và tím luôn là một tài sản thương hiệu
                      quan trọng và cần được bảo tồn.
                    </p>
                    <p>
                      Tuy nhiên những màu sắc cũ của thương hiệu chưa thực sự
                      thể hiện được đặc tính và thông điệp tươi mới, hướng tới
                      trường học của tương lai mà nhà trường lựa chọn. Vì vậy
                      cần thay thế bằng hai màu tím và vàng với những tông màu
                      tươi sáng, khỏe khoắn hơn.
                    </p>
                  </div>
                </div>

                <div className={styles.sec13_right}>
                  <div className={styles.color1}>
                    <div ref={this.refCircle1Sec13} className={styles.circle1}></div>
                    <div className={styles.colorInfo}>
                      <h3>Academic purple</h3>
                      <p>#5A1E91</p>
                      <ul>
                        <li>
                          <span className={styles.typeColor}>RGB</span>
                          <span className={styles.valueColor}>
                            90 - 30 - 140
                          </span>
                        </li>
                        <li>
                          <span className={styles.typeColor}>CMYK</span>
                          <span className={styles.valueColor}>
                            80 - 100 - 5 - 0
                          </span>
                        </li>
                        <li>
                          <span className={styles.typeColor}>PANTONE</span>
                          <span className={styles.valueColor}>3583 C</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className={styles.color2}>
                    <div ref={this.refCircle2Sec13} className={styles.circle2}></div>
                    <div className={styles.colorInfo}>
                      <h3>Curious yellow</h3>
                      <p>#FFDA00</p>
                      <ul>
                        <li>
                          <span className={styles.typeColor}>RGB</span>
                          <span className={styles.valueColor}>
                            255 - 213 - 0
                          </span>
                        </li>
                        <li>
                          <span className={styles.typeColor}>CMYK</span>
                          <span className={styles.valueColor}>
                            0 - 15 - 100 - 0
                          </span>
                        </li>
                        <li>
                          <span className={styles.typeColor}>PANTONE</span>
                          <span className={styles.valueColor}>108 C</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec14}>
              <div className={styles.sec14_inner}>
                <div className={styles.sec14_left}>
                  <div className={styles.sec14_text}>
                    <h3>Hành trang xây dựng tương lai</h3>
                    <p>
                      Lấy cảm hứng từ trò chơi xếp gỗ của trẻ nhỏ, hệ thống họa
                      tiết được xậy dựng từ các hình khối cơ bản ứng dưới lưới
                      thiết kế 6x6, tạo nên hệ thống hình ảnh độc đáo dành riêng
                      cho TOS. Từng khối màu đơn giản kia chính là những hành
                      trang mà các em học sinh thu thập trong quá trình học tập
                      để xậy dựng nên một cuộc sống thành công sau này.
                    </p>
                  </div>
                </div>

                <div className={styles.sec14_right}>
                  <div className={styles.img}>
                    <img src="/img/work/OLYMPIA/icon-min.gif" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec15}>
              <div className={styles.sec15_inner}>
                <div className={styles.sec15_left}>
                  <div className={styles.sec15_text}>
                    <h3>Hành trang xây dựng tương lai</h3>
                    <p>
                      Đi kèm với logo sẽ là hệ thống ngôn ngữ thiết kế riêng
                      biệt nhằm tạo trải nghiệm độc đáo và có một không hai dành
                      cho thương hiệu The Olympia Schools. Ngôn ngữ thiết kế
                      được thiết lập cho bộ nhận diện dựa vào những hình khối
                      hình học cơ bản nhất và dùng 2 màu sắc chu đạo của thương
                      hiệu.
                    </p>
                  </div>
                </div>

                <div className={styles.sec15_right}>
                  <div className={styles.img}>
                    <img
                      src="/img/work/OLYMPIA/hoa-tiet-thuong-hieu.gif"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec16}>
              <div className={styles.sec16_inner}>
                <div className={styles.sec16_left}>
                  <div className={styles.sec16_text}>
                    <h3>Hành trang xây dựng tương lai</h3>
                    <p>
                      Bộ pictogram sẽ được ứng dụng làm một dạng pattern cho
                      thương hiệu cũng như dùng làm hình minh họa, biểu tượng
                      chỉ phương hướng cho một vài điểm chạm nhất định.
                    </p>
                  </div>
                </div>

                <div className={styles.sec16_right}>
                  <div className={styles.img}>
                    <img src="/img/work/OLYMPIA/svgsubjects.svg" alt="" />
                  </div>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec17}>
              <div className={styles.sec17_inner}>
                <div className={styles.boxImg}>
                  <img src="/img/work/OLYMPIA/img4.jpg" alt="" />
                </div>
                <div className={styles.boxImg}>
                  <img src="/img/work/OLYMPIA/img5.jpg" alt="" />
                </div>
                <div className={styles.boxImg}>
                  <img src="/img/work/OLYMPIA/img6.jpg" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec18}>
              <div className={styles.sec18_inner}>
                <div className={styles.sec18_boxText}>
                  <h3>Graphic Motif</h3>
                  <p>
                    Ngoài bộ họa tiết dạng minh họa dành cho tối tượng là các em
                    học sinh, một bộ họa tiết song song dành riêng cho phụ
                    huynh, các đối tác và khách mời sẽ có phần trang trọng,
                    chuyên nghiệp họp được xây dựng.
                  </p>
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec19}>
              <div className={styles.sec19_inner}>
                <div className={styles.sec19_text}>
                  <h3>Thành quả của dự án</h3>
                  <p>
                    Việc thể hiện hình ảnh thương hiệu không đồng nhất trên các
                    điểm chạm truyền thông không còn là điều hiếm thấy đối với
                    các doanh nghiệp tại Việt Nam do thiếu sự đầu tư một cách
                    bài bản cho quá trình xây dựng chiến lược thương hiệu. Thông
                    qua dự án, Beautique đã giúp ban quản trị TOS tìm và giải
                    quyết được vấn đề cốt lõi trong việc xây dựng hình ảnh của
                    thương hiệu đồng nhất hơn với cảm nhận trong tâm trí khách
                    hàng tiềm năng, giúp thương hiệu truyền tải thông điệp cũng
                    như tiếp cận khách hàng một cách hiệu quả và dễ hàng hơn.
                  </p>
                </div>
              </div>
            </SectionFull>
            <SectionFull className={styles.sec20}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec21}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec22}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec23}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec24}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec25}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec26}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec27}>
              <div className={styles.inner}></div>
            </SectionFull>
            <SectionFull className={styles.sec28}>
              <div className={styles.inner}></div>
            </SectionFull>

            <SectionFull className={styles.sec29}>
              <div className={styles.sec29_inner}>
                <div className={styles.sec29_img}>
                  <img src="/img/work/OLYMPIA/img17.png" alt="" />
                </div>

                <div className={styles.sec29_img}>
                  <img src="/img/work/OLYMPIA/img18.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec30}>
              <div className={styles.sec30_inner}>
                <div className={styles.sec30_img}>
                  <img src="/img/work/OLYMPIA/img19.png" alt="" />
                </div>
              </div>
            </SectionFull>

            <SectionFull className={styles.sec31}>
                <div className={styles.inner}>
                  <h3 className={styles.sec31Title}>Dự án điển hình.</h3>
                  <div className={styles.listWork}>
                  <div className={styles.listItem}>
                      <Link to="/work-detail/joa">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-joa.png`} alt=""/>
                        </div>
                        <h3 className="f-title">Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/doan">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-doan.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">DOAN - thời trang đại diện cho khái niệm Effortless beauty</h3>
                      </Link>
                    </div>

                    <div className={styles.listItem}>
                      <Link to="/work-detail/wonder">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-wd.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">Wonder không gian nghệ thuật sáng tạo dành cho gia đình và các bé</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </SectionFull>

              <ContactWork />
          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
