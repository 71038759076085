import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/19",
  "guid": "d79873cf-d914-465e-95b8-a0766d81c3cd",
  "title": "Kỹ năng Agency: Tố chất để làm việc với client hiệu quả",
  "subtitle": "Kỹ năng Agency: Tố chất để làm việc với client hiệu quả",
  "publicationDate": "2021-04-14T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Thêm option, lật brief, thúc deadline, chậm feedback,... Tất cả sẽ được chúng tôi tại Beautique cùng đặt mình vào vị trí của client để tìm ra những lý do đứng đằng sau, từ đó thấu hiểu hơn về văn hoá làm việc của đôi bên. Mời bạn cùng lắng nghe những câu hỏi và chia sẻ của chúng tôi qua podcast này.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_19.mp3",
  "size": 65274455,
  "duration": 2040,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Thêm option, lật brief, thúc deadline, chậm feedback,... Tất cả sẽ được chúng tôi tại Beautique cùng đặt mình vào vị trí của client để tìm ra những lý do đứng đằng sau, từ đó thấu hiểu hơn về văn hoá làm việc của đôi bên. Mời bạn cùng lắng nghe những câu hỏi và chia sẻ của chúng tôi qua podcast này.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      