import React, { Component } from "react"
import { Link } from "gatsby"

import SEO from '../../components/layout/SEO'
import Layout from '../../components/layout/layout'
import SectionFull from "../../components/layout/sectionFull"

import ScrollPageComponent from '../../components/modules/scrollpage';
import ContactWork from '../../components/modules/contactWork';
import { scrollToElement } from '../../helpers/utils'

import styles from "./doan.module.scss"

export default class Insight extends Component {

  scrollHandle = (callbackScroll) => {
    callbackScroll(window.scrollY)
  }

  pageTranslateX = () => scrollToElement(window.innerWidth)

  render() {

    return (
      <>
        <SEO
          title="DOAN - thời trang đại diện cho khái niệm Effortless beauty"
          url="https://beautique.vn/work-detail/doan"
          description="DOAN hướng tới một vẻ đẹp nhẹ nhàng và không phô trương hay hoa mỹ. Vẻ đẹp của tâm hồn và phong thái toát ra từ sự tự tin. Qua những sản phẩm của mình, DOAN mong muốn giúp khách hàng của mình trở thành những người phụ nữ tinh tế, với một tâm hồn và tình yêu hết mình cho thiên nhiên và nghệ thuật."
          imgThumb="https://beautique.vn/img/work/thumb/thumb-doan.jpg"
        />
        <Layout>
          <ScrollPageComponent scrollHandle={this.scrollHandle}>
                <SectionFull className={styles.sec1}>
                  <div className={styles.inner}>
                    <div>
                        <h2 className={styles.sec1_title}>DOAN - thời trang đại diện cho khái niệm Effortless beauty</h2>
                    </div>
                    <div className={styles.sec1_content}>
                      <div className={styles.sec1_left}>
                        <ul className={styles.sec1_list}>
                          <li><span>Client</span>DOAN</li>
                          <li><span>Duration</span>10/2018 - 12/2018</li>
                          <li><span>Scope</span>Brand Strategy<br />Campaign Identity</li>
                        </ul>
                      </div>
                      <div className={styles.sec1_right}>
                        <p>DOAN hướng tới một vẻ đẹp nhẹ nhàng và không phô trương hay hoa mỹ. Vẻ đẹp của tâm hồn và phong thái toát ra từ sự tự tin. Qua những sản phẩm của mình, DOAN mong muốn giúp khách hàng của mình trở thành những người phụ nữ tinh tế, với một tâm hồn và tình yêu hết mình cho thiên nhiên và nghệ thuật.</p>
                      </div>
                      <button onClick={this.pageTranslateX} className={styles.sec1_btn}><img src="/img/icon/arrow-black.svg" alt=""/></button>
                    </div>
                  </div>
                </SectionFull>



              <SectionFull className={styles.sec2}>
                <div className={styles.inner}>
                  <div className={styles.img2PC}>
                    <img src="/img/work/DOAN/img1.png" alt="" />
                  </div>
                  <div className={styles.img2MB}>
                    <img src="/img/work/DOAN/img1Mb.png" alt="" />
                  </div>
                  <div>
                    <img src="/img/work/DOAN/leaves.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec3}>
              <div className={styles.inner}>
                <div>
                  <h3>Nhận diện cho thương hiệu DOAN</h3>
                  <p>Lấy giá trị cốt lõi là sự hoà hợp giữa thời trang và phong cách sống. DOAN muốn khẳng định cái tôi cá nhân, không phải là sự khác biệt mà bằng cách làm nổi bật lên về đặc biệt của mỗi khách hàng bằng phong cách thiết kế  timeless - trường tồn.</p>
                </div>
                <div>
                  <img src="/img/work/DOAN/img2.png" alt="" />
                </div>
              </div>
              </SectionFull>

              <SectionFull className={styles.sec4}>
                <div className={styles.inner}>
                  <div className={styles.sec4_left}>
                    <h3>Bộ ứng dụng</h3>
                    <p>Bộ ứng dụng chúng tôi xây dựng cho DOAN trải dài qua toàn bộ các điểm chạm offline của thương hiệu</p>
                  </div>
                  <div className={styles.sec4_right}>
                    <div className={styles.sec4_right_scroll}>
                      <div>
                        <img src="/img/work/DOAN/img3.png" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/img4.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec5}>
                <div className={styles.inner}>
                  <div>
                    <h3>o1.</h3>
                    <p>Business Card</p>
                  </div>
                  <div>
                    <img src="/img/work/DOAN/img5.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec6}>
                <div className={styles.inner}>
                  <div>
                    <h3>o2.</h3>
                    <p>Shopping bag</p>
                  </div>
                  <div>
                    <img src="/img/work/DOAN/img6.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec7}>
                <div className={styles.inner}>
                  <div>
                    <h3>o3.</h3>
                    <p>Price tag</p>
                  </div>
                  <div>
                    <img src="/img/work/DOAN/img7.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec8}>
                <div className={styles.inner}>
                    <img className={styles.img8PC} src="/img/work/DOAN/img8.png" alt="" />
                    <img className={styles.img8MB} src="/img/work/DOAN/doan-fix1.png" alt="" />
                </div>
              </SectionFull>

              <SectionFull className={styles.sec9}>
                <div className={styles.inner}>
                  <div>
                    <h3>o4.</h3>
                    <p>Giấy gói</p>
                  </div>
                  <div>
                    <img src="/img/work/DOAN/img9.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec10}>
                <div className={styles.inner}>
                  <div>
                    <img src="/img/work/DOAN/img10.png" alt="" />
                  </div>
                </div>
              </SectionFull>

              <SectionFull className={styles.sec11}>
                <div className={styles.inner}>
                  <div className={styles.sec11_left}>
                    <h3>o5.</h3>
                    <p>Posters</p>
                  </div>
                  <div className={styles.sec11_right + ' ' + styles.sec11_rightPC}>
                    <div className={styles.sec11_scroll}>
                      <div>
                        <img src="/img/work/DOAN/img11.png" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/img12.png" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/img13.png" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/img14.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div className={styles.sec11_right + ' ' + styles.sec11_rightMB}>
                    <div className={styles.sec11_scroll}>
                      <div>
                        <img src="/img/work/DOAN/doan-fix2.jpg" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/doan-fix3.jpg" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/doan-fix4.jpg" alt="" />
                      </div>
                      <div>
                        <img src="/img/work/DOAN/doan-fix5.jpg" alt="" />
                      </div>
                    </div>
                  </div>

                </div>
              </SectionFull>

              <SectionFull className={styles.sec12}>
                <div className={styles.inner}>
                  <h3 className={styles.sec12Title}>Dự án điển hình.</h3>
                  <div className={styles.listWork}>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/olympia-school">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-olympia.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">The Olympia School - Trường học của tương lai</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/joa">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-joa.png`} alt=""/>
                        </div>
                        <h3 className="f-title">Đương đại hoá hoạ tiết truyền thống qua bộ nhận diện Journey on Air</h3>
                      </Link>
                    </div>
                    <div className={styles.listItem}>
                      <Link to="/work-detail/doan">
                        <span className="f-subtitle">Design/Identity</span>
                        <div>
                        <img className="f-img" src={`/img/work/thumb/thumb-doan.jpg`} alt=""/>
                        </div>
                        <h3 className="f-title">DOAN - thời trang đại diện cho khái niệm Effortless beauty</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </SectionFull>

              <ContactWork />

          </ScrollPageComponent>
        </Layout>
      </>
    )
  }
}
