import React, { useEffect, useRef, useState } from "react"

import {
  changeWidthInput,
  isElementVisible,
  postData,
} from "../../helpers/utils"

import styles from "./contact.module.scss"

export default function Contact(props) {
  const formElement = useRef()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [trademark, setTrademark] = useState("")

  useEffect(() => {
    let req = null
    const activeForm = () => {
      if (isElementVisible(formElement.current)) {
        formElement.current.classList.add(styles.formActive)
      } else {
        formElement.current.classList.remove(styles.formActive)
      }
      req = window.requestAnimationFrame(activeForm)
    }
    activeForm()
    return () => {
      window.cancelAnimationFrame(req)
    }
  }, [])

  const onSubmit = async e => {
    e.preventDefault()
    await postData(
      "https://hooks.slack.com/services/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",
      { text: `Name: ${name}\n Email: ${email}\n Desc: ${trademark}` }
    )

    setName("")
    setEmail("")
    setTrademark("")
  }

  return (
    <>
      <div className={styles.formwrap} ref={formElement}>
        <span className={styles.subtitle}>liên hệ chúng tôi</span>
        <form className={styles.form} onSubmit={onSubmit}>
          <p className="f-name">
            Xin chào, tôi là
            <input
              className="mouse-hover"
              type="text"
              required
              data-w="340"
              value={name}
              onKeyPress={changeWidthInput}
              onChange={e => setName(e.target.value)}
              onKeyDown={changeWidthInput}
              placeholder="họ tên của bạn*"
            />
            ,
          </p>
          <p className="f-email">
            liên hệ với tôi qua
            <input
              className="mouse-hover"
              type="email"
              required
              data-w="290"
              value={email}
              onKeyPress={changeWidthInput}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={changeWidthInput}
              placeholder="địa chỉ email*"
            />
          </p>
          <p className="f-trademark">
            tôi muốn chia sẻ cùng Beautique về câu chuyện
            <input
              className="mouse-hover"
              type="text"
              required
              data-w="420"
              value={trademark}
              onKeyPress={changeWidthInput}
              onChange={e => setTrademark(e.target.value)}
              onKeyDown={changeWidthInput}
              placeholder="thương hiệu của tôi"
            />
          </p>
          <button className="mouse-hover">
            Gửi lời nhắn{" "}
            <img
              src={props.srcArrow ? props.srcArrow : "/img/home/arrow-form.svg"}
              alt=""
            />
          </button>
        </form>
      </div>
    </>
  )
}
