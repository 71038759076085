import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/02",
  "guid": "793d71b7-1c43-448f-9abb-1bf8eb36c6ce",
  "title": "Employer Branding - Lời Giải Cho Bài Toán Tuyển Dụng",
  "subtitle": "The second episode.",
  "publicationDate": "2020-11-11T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Sẽ có những thời điểm toàn bộ phòng HR của doanh nghiệp sục sôi vì chờ đợi CV của ứng viên. Hẳn sẽ có rất nhiều lý do khiến ứng viên bỏ qua nhà tuyển dụng...",
  "url": "https://beautique.vn/mp3/Beauthik_talk_02.mp3",
  "size": 65846430,
  "duration": 2060,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sẽ có những thời điểm toàn bộ phòng HR của doanh nghiệp sục sôi vì chờ đợi CV của ứng viên. Hẳn sẽ có rất nhiều lý do khiến ứng viên bỏ qua nhà tuyển dụng. Tuy nhiên, việc thiếu đi một thương hiệu tuyển dụng - Employer Branding được coi là một lý do lớn khiến bản thân doanh nghiệp chưa thể thu hút được sự chú ý từ những ứng viên tiềm năng.
Trong kỳ podcast lần này, #BeauThikTalk sẽ tập trung đi sâu phân tích một case study về Employer Branding mà Beautique đã có cơ hội thực hiện. Rất hi vọng có thể giúp ích cho các doanh nghiệp đang đau đầu với bài toán nhân sự.
Mời bạn cùng chia sẻ quan điểm!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      