import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/11",
  "guid": "092cac81-320e-4f1a-ad8f-51c667f9da39",
  "title": "Truyền thông nói láo, quảng cáo nói điêu?",
  "subtitle": "The eleventh episode.",
  "publicationDate": "2021-01-22T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Có thể đến một lúc, bạn sẽ đặt câu hỏi hoài nghi về chính những sản phẩm mà bản thân đang góp phần thúc đẩy doanh số tiêu thụ.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_11.mp3",
  "size": 35625456,
  "duration": 1781,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Có thể đến một lúc, bạn sẽ đặt câu hỏi hoài nghi về chính những sản phẩm mà bản thân đang góp phần thúc đẩy doanh số tiêu thụ. Liệu chúng có thật sự mang lại một giá trị thực tế cho những người tiêu dùng hay đơn giản chỉ là những ảo ảnh xa vời, giúp họ vuốt ve cái tôi của bản thân?
Truyền thông - Quảng cáo có thực sự là công cụ để “make up” cho những sản phẩm như vậy? Mời bạn cùng Mann Up & Beautique trả lời câu hỏi bên trên ngay tại link podcast phía dưới!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      