/**
 * Get element by Id
 * @param {String} id
 */
const byId = id => {
  return document.getElementById(id)
}

/**
 * Get Dom element
 * @param {Element} scope Tên element muốn query
 * @param {String} selector Tên class, id, tagName
 * @param {String} typeQuery Chọn kiểu muốn query Node hoặc NodeList
 * @return {NodeList} type query là NodeList
 * @return {Node} type query là Node
 */
const $ = (scope, selector, typeQuery = "Node") => {
  if (typeQuery === "NodeList") {
    return scope.querySelectorAll(selector)
  }
  return scope.querySelector(selector)
}

/**
 * Change class element
 * @param {String} type
 * @param {String} className
 * @param  {Array} elements
 */
const changeClassElement = (type, className, ...elements) => {
  switch (type) {
    case "ADD":
      if (elements.length < 2) {
        elements[0].classList.add(className)
        break
      }
      for (const e of elements) {
        e.classList.add(className)
      }
      break
    case "REMOVE":
      if (elements.length < 2) {
        elements[0].classList.remove(className)
        break
      }
      for (const e of elements) {
        e.classList.remove(className)
      }
      break
    default:
      break
  }
}

export { byId, $, changeClassElement }
