import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "podcast-episode",
  "status": "published",
  "slug": "/beauthik-talk/15",
  "guid": "c32a7bb9-a348-4536-a56a-7d3f855a98a3",
  "title": "Nghề sáng tạo - Căng thẳng lo âu, vì đâu nên nỗi?",
  "subtitle": "Nghề sáng tạo - Căng thẳng lo âu, vì đâu nên nỗi?",
  "publicationDate": "2021-03-05T00:00:00.000Z",
  "author": "Beautique",
  "season": 1,
  "episodeNumber": 1,
  "episodeType": "trailer",
  "excerpt": "Sáng tạo và niềm hứng khởi vẫn luôn song hành cùng nhau qua từng chiến dịch. Thế nhưng bộ não là một con quái vật đói khát cảm xúc. Vui, buồn, tức giận, quá khích, đều có thể là chất liệu cho những tác phẩm bay bổng.",
  "url": "https://beautique.vn/mp3/Beauthik_talk_15.mp3",
  "size": 97335950,
  "duration": 3042,
  "explicit": false,
  "categories": ["Case Study"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sáng tạo và niềm hứng khởi vẫn luôn song hành cùng nhau qua từng chiến dịch. Thế nhưng bộ não là một con quái vật đói khát cảm xúc. Vui, buồn, tức giận, quá khích, đều có thể là chất liệu cho những tác phẩm bay bổng.
Những cảm xúc đó luôn đến từ nhiều phía: đồng nghiệp, khách hàng hoặc từ chính bản thân người làm sáng tạo. Nhưng đừng quên, dẫu thế nào đi nữa hãy giữ cho tâm trí của mình khỏe mạnh để luôn dồi dào sức sáng tạo.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      